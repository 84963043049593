.main {
	&__why {
		&__grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 30px;

			@media (max-width: $laptop) {
				grid-template-columns: 1fr 1fr;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
				gap: 24px;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 18px;
			line-height: 27px;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 24px;
			}

			b {
				color: $blue;
			}
		}
	}

	&__contribution {
		padding: var(--row-gap) 0;
		margin-left: 40px;
		margin-right: 40px;
		background-color: #e8ebf2;
		border-radius: 40px;

		@media (max-width: $laptop) {
			padding-top: 100px;
			padding-bottom: 100px;
		}

		@media (max-width: $tablet) {
			margin-left: 20px;
			margin-right: 20px;
		}

		@media (max-width: $mobile) {
			padding: 40px 0;
		}

		h2 {
			@media (max-width: $tablet) {
				font-size: 40px;
			}

			@media (max-width: $mobile) {
				font-size: 30px;
			}
		}

		&__body {
			display: grid;
			grid-template-columns: 1fr max-content;
			gap: 60px var(--column-gap);

			@media (max-width: $laptop) {
				grid-template-columns: 1fr;
			}

			@media (max-width: $tablet) {
				padding: 0 24px;
			}

			@media (max-width: $mobile) {
				padding: 0 5px;
			}
		}

		&__person {
			@media (max-width: $laptop) {
				display: grid;
				grid-template-columns: max-content minmax(auto, max-content) max-content;
				align-items: center;
				gap: 12px 40px;
			}

			@media (max-width: $tablet) {
				grid-template-columns: max-content minmax(auto, max-content);
			}

			@media (max-width: $mobile) {
				gap: 12px 20px;
			}

			&__img {
				margin-bottom: 40px;

				@media (max-width: $laptop) {
					margin-bottom: 0;
					grid-row: span 2;
				}

				@media (max-width: $tablet) {
					grid-row: span 3;
				}

				@media (max-width: $mobile) {
					grid-row: span 2;
				}

				img {
					width: 211px;
					height: 211px;
					object-fit: cover;
					border-radius: 50%;
					object-position: top;
					background-color: #C9CFDC;

					@media (max-width: $mobile) {
						width: 60px;
						height: 60px;
					}
				}
			}

			&__name {
				font-weight: 500;
				font-size: 32px;
				line-height: 120%;

				@media (max-width: $laptop) {
					grid-column: 2;
					grid-row: 1;
					align-self: flex-end;
				}

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 1;
				}
			}

			&__caption {
				font-size: 18px;
				font-weight: 40;
				line-height: 24px;
				margin-top: 12px;
				margin-bottom: 40px;

				@media (max-width: $laptop) {
					margin-bottom: 0;
					grid-column: 2;
					grid-row: 2;
					align-self: flex-start;
					margin-top: 0;
				}

				@media (max-width: $mobile) {
					font-size: 14px;
					line-height: 1;
				}
			}

			.button {
				@media (max-width: $laptop) {
					grid-row: span 2;
				}

				@media (max-width: $tablet) {
					grid-row: unset;
					align-self: flex-start;
				}

				@media (max-width: $mobile) {
					grid-column: 1 / span 2;
					margin-top: 8px;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		&__text {
			max-width: 654px;
			font-size: 24px;
			font-weight: 400;
			line-height: 30px;

			@media (max-width: $mobile) {
				font-size: 18px;
				line-height: 21.6px;
			}
		}

		&__list {
			max-width: 654px;

			.custom-list {
				color: #000;
				gap: 12px;
				font-size: 18px;
				line-height: 24px;

				a {
					text-decoration: underline;
					color: $blue;
				}
			}
		}
	}

	&__services {
		display: grid;
		grid-template-columns: 1fr auto auto;
		--gap: 40px;
		gap: var(--gap) 20px;

		@media (max-width: $laptop) {
			grid-template-columns: 1fr 306px;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__item {
			padding-bottom: var(--gap);
			border-bottom: 1px solid #E3E6E6;
			display: grid;
			grid-template-columns: subgrid;
			grid-column: 1 / -1;
			align-items: center;
			gap: 20px;

			@media (max-width: $mobile) {
				padding-bottom: 0;
				border-bottom: none;
			}

			&__buttons {
				display: contents;

				@media (max-width: $mobile) {
					display: flex;
					flex-direction: column;
					gap: 20px;
					margin-top: 20px;
				}
			}

			&__text {
				display: flex;
				flex-direction: column;
				gap: 20px;

				@media (max-width: $laptop) {
					grid-row: span 2;
				}
			}

			&__title {
				font-size: 24px;
				font-weight: 400;
				line-height: 30px;

				@media (max-width: $mobile) {
					font-size: 20px;
					line-height: 24px;
				}
			}

			&__text {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}
	}

	&__portfolio {
		@include Portfolio;
	}

	&__discuss {
		&__header {
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin-bottom: 80px;

			@media (max-width: $mobile) {
				margin-bottom: 60px;
			}

			h2 {
				@media (max-width: $mobile) {
					font-size: 30px;
				}
			}
		}

		&__subtitle {
			font-size: 20px;
			font-weight: 500;
			line-height: 30px;

			@media (max-width: $mobile) {
				font-size: 18px;
				line-height: 21.8px;
			}
		}

		&__footer {
			margin-top: 40px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			.button {
				border: none;
				align-self: center;
			}

			&__caption {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				color: $text_color;
				display: flex;
				flex-direction: column;
				gap: 4px;

				@media (max-width: $tablet) {
					display: block;
				}

				a {
					text-decoration: underline;
					color: $blue;
					text-underline-offset: 5px;
				}
			}
		}

		&__form {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			align-items: flex-start;
			gap: 40px 20px;

			@media (max-width: $laptop) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media (max-width: $laptop) {
				grid-template-columns: 1fr;
			}

			textarea {
				resize: none;
			}

			.choices__inner,
			input,
			textarea {
				font-size: 18px;
				line-height: 24px;
				font-weight: 400;

				@media (max-width: $mobile) {
					font-size: 16px;
				}
			}

			input,
			textarea {
				border-width: 0 0 1px 0;
				border-style: solid;
				border-color: #DFE3EF;
				background: none;
				color: #000;
				padding-bottom: 8px;
				transition: all ease .3s;
				width: 100%;

				&:hover,
				&:not(:placeholder-shown) {
					border-color: #000;
				}

				&::placeholder {
					color: inherit;
				}

				&:user-invalid {
					border-color: #f00;
					color: #f00;
				}
			}
		}

		&__files {
			display: flex;
			flex-direction: column;
			gap: 20px;

			&__item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 20px;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;

				@media (max-width: $mobile) {
					flex-direction: column;
					align-items: flex-start;
				}

				br {
					@media (max-width: $mobile) {
						display: none;
					}
				}

				a,
				label {
					color: $blue;
					padding-bottom: 5px;
					border-bottom: 1px solid $blue;
					white-space: nowrap;
					font-size: 14px;
					line-height: 20px;
					cursor: pointer;
				}

				input {
					display: none;
				}
			}
		}
	}
}