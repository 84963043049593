.audit-cards {
	@media (min-width: calc(#{$tablet} + 1px)) {
		--gap: 40px;
	}

	@media (max-width: $tablet) {
		--gap: 20px;
	}

	&__container {
		@extend %container;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--gap);
	}

	&__item {
		&:not(:last-child) {
			padding-bottom: var(--gap);
			border-bottom: 1px solid #E3E6E6;
		}

		&__header {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			align-items: center;
			gap: 20px;

			&__text {
				grid-column: 1 / 8;

				@media (max-width: $laptop) {
					grid-column: 1 / 12;
				}

				@media (max-width: $mobile) {
					grid-column: 1 / 13;
				}
			}

			&__img {
				grid-column: 1 / 2;
				width: 84px;
				height: 84px;

				@media (max-width: $mobile) {
					width: 64px;
					height: 64px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
			}

			&__title {
				grid-column: 2 / 12;

				@media (max-width: $mobile) {
					grid-column: 2 /13;
				}
			}

			&__flipper {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;

				@media (max-width: $mobile) {
					width: 50px;
					height: 50px;
				}

				&-wrapper {
					margin-left: auto;
					grid-column: 12 / 13;

					@media (max-width: $mobile) {
						align-self: flex-start;
						grid-column: 1 / 2;
						grid-row: 3;
						margin-left: 0;
					}
				}

				.icon {
					display: flex;
					--color: #{$blue};
					--size: 30px;
					@extend %icon--plus;
				}
			}

			.spoiler__flipper {
				&.is-active {
					.audit-cards__item__header__flipper {
						.icon {
							@extend %icon--minus;
						}
					}
				}
			}
		}

		.spoiler {
			display: grid;
			gap: 20px;
		}

		&__limited-text {

			// max-width: cols-width(7);
			// @media (max-width: $laptop) {
			// 	max-width: cols-width(11);
			// }
			p {
				margin: 1em 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}