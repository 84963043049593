$cols_gap: 10px;
$cols_maxWidth: 433px;
$containerPadding: 20px;
$cols_oneColOnly: 3 * $cols_maxWidth + 2 * $cols_gap + 2 * $containerPadding;

%landing2-element-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	.block-name {
		color: #595571;
		font: 16px/1.5 Inter;
	}

	.block-value {
		font: bold 26px/32px Inter;
		color: #2A2546;
	}
}

%gray-block-with-button {
	.section__content {
		padding: 40px;
		background-color: $background_color;
		border-radius: 40px;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 40px;
		color: $text_color;

		@media (max-width: $mobile) {
			padding: 40px 15px;
			gap: 20px;
			border-radius: 20px;
		}
	}

	.lead {
		color: #000;
	}

	.button {
		@media (max-width: $mobile) {
			max-width: 300px;
			font-size: 16px;
			line-height: 1.2;
		}
	}
}

section[class^="landing2-"] {
	scroll-margin-top: 100px;

	&:is(.is-visible) {
		scroll-margin-top: 75px;
	}
}