.learn {
	display: flex;
	justify-content: center;
	gap: 140px;

	@media (max-width: $tablet) {
		gap: 32px;
	}

	@media (max-width: $mobile) {
		flex-direction: column;
	}

	&__btn {
		@extend %button, %button--primary;
		display: flex;
		align-items: center;
		color: #fff;
		@extend %title;
		height: 80px;
		border-radius: 666px;
		padding: 0 50px;
		gap: 10px;

		@media (max-width: $mobile) {
			height: 50px;
		}

		hr {
			height: 40px;
			width: 1px;
			background-color: #fff;
		}

		img {
			@media (max-width: $mobile) {
				width: 70px;
				height: auto;
			}
		}
	}
}