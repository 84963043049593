:root {
	--header-margin: 80px;
	--button-height: 56px;
	--container-padding: 40px;
	--column-gap: 10px;
	--row-gap: 140px;
	--transition-duration: .3s;

	@media (max-width: $laptop) {
		--container-padding: 20px;
		--header-margin: 60px;
		--row-gap: 120px;
	}

	@media (max-width: $tablet) {
		--header-margin: 40px;
		--row-gap: 100px;
	}

	@media (max-width: $mobile) {
		--header-margin: 20px;
		--container-padding: 15px;
		--row-gap: 60px;
	}
}