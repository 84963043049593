.landing2-workplan {
	.section__content {
		gap: 40px;
		padding: 40px;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #eef0f9;
		position: relative;

		@media (max-width: $mobile) {
			padding: 40px 40px 40px 20px;
			flex-direction: column;
			align-items: flex-start;
		}

		* {
			position: relative;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			border-radius: inherit;
			display: block;
			background-image: url(/src/assets/static/images/support/banner.png);
			background-size: 53% auto;
			background-repeat: no-repeat;
			background-position: top 35% right 0;
			opacity: .1;

			@media (max-width: $mobile) {
				background-size: 145%;
				background-position: top 35% right 60%;
			}
		}
	}

	h3 {
		font: 500 50px/60px Inter;

		@media (max-width: $mobile) {
			font-size: 24px;
			line-height: 27px;
		}
	}
}