.landing2-crm {
	.section__content {
		display: flex;
		flex-direction: column;
	}

	h2 {
		margin-bottom: 80px;

		@media (max-width: $mobile) {
			margin-bottom: 40px;
			font-size: 30px;
			line-height: 1.2;
		}
	}

	.crm-elements-grid {
		--columns-count: 3;
		--padding: 40px;
		display: grid;
		grid-template-columns: repeat(var(--columns-count), 1fr);
		gap: 60px calc(2 * var(--padding));
		padding: 0 var(--padding);
		margin-bottom: 40px;

		@media (max-width: $tablet) {
			--columns-count: 2;
		}

		@media (max-width: $mobile) {
			--columns-count: 1;
			gap: 40px;
			padding: 0;
		}
	}

	.crm-element {
		display: flex;
		flex-direction: column;
		gap: 30px;

		&__value {
			color: $text_color;
			text-wrap: balance;
		}

		@media (max-width: $mobile) {
			gap: 20px;
		}
	}

	.button {
		align-self: center;

		@media (max-width: $mobile) {
			align-self: start;
			max-width: 330px;
			font-size: 16px;
			line-height: 1.2;
		}
	}
}