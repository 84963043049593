.landing2-banner {
	.section__inner {
		background: center / cover url("/src/assets/static/images/landing2/banner.webp") no-repeat;
		background-image: image-set(
			url("/src/assets/static/images/landing2/banner.webp") 1x,
			url("/src/assets/static/images/landing2/banner-2x.webp") 2x
		);
		border-radius: 40px;
		overflow: hidden;
		color: #fff;
		min-height: 682px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: $laptop) {
			margin-left: var(--container-padding);
			margin-right: var(--container-padding);

			.container {
				padding-left: 16px;
				padding-right: 16px;
			}
		}

		@media (max-width: $mobile) {
			min-height: unset;
			border-radius: 20px;

			.container {
				padding-bottom: 20px;
			}
		}

		@media (max-width: $mobile-small) {
			background-image: image-set(
				url("/src/assets/static/images/landing2/banner-mobile.webp") 1x,
				url("/src/assets/static/images/landing2/banner-mobile-2x.webp") 2x
			);
		}
	}

	.section__content {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 30px;
		max-width: 543px;

		@media (max-width: $mobile) {
			margin-top: 233px;
			gap: 20px;
		}
	}

	.button {
		max-width: 340px;

		@media (max-width: $mobile) {
			margin-top: 20px;
			font-size: 16px;
			line-height: 1.2;
		}
	}
}