.landing2-cases {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 140px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	.cases-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 60px 10px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	.case {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			max-width: 430px;
			gap: 20px;
		}

		img {
			border-radius: 40px;
			aspect-ratio: 1;
			object-fit: cover;
		}

		.name-and-link {
			--link-size: 70px;
			display: flex;
			align-items: start;
			justify-content: space-between;
			gap: 40px;
		}

		&__name-tags {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&__tags {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 10px;
		}

		&__tag {
			background-color: #e3e6e6;
			color: $text_color;
			border-radius: 10px;
			padding: 5px 15px;

			@media (max-width: $laptop) {
				line-height: 1.3;
			}
		}

		&__description {
			color: $text_color;
		}
	}

	.show-all-cases {
		--py: 22px;
		display: none;
		max-width: 300px;
		margin-top: -20px;
		font: 500 16px/1.25 Inter;

		@media (max-width: $mobile) {
			display: flex;
		}
	}
}