.pseudo-scroll {
	&__static {
		position: sticky;
		height: 100vh;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		@media (max-width: $mobile){
			height: auto;
		}
	}

	&__scrollable {
		// padding: 20vh 0;
	}
}