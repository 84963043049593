.custom-file-input {
	display: flex;
	align-items: center;
	gap: 14px;
	position: relative;
	cursor: pointer;
	--status: 'Прикрепить файл';
	--name-visibility: none;
	--icon-bg: #E8EBF2;
	--icon-color: #{$text_color};

	&--chosen {
		--icon-bg: #{$blue};
		--icon-color: #fff;
		--status: 'Файл прикреплен';
		--name-visibility: flex;
	}

	&__input {
		position: absolute;
		width: 0;
		height: 0;
	}

	&__icon {
		flex-shrink: 0;
		border-radius: 50%;
		background: var(--icon-bg);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;

		&::before {
			content: "";
			@extend %icon, %icon--file;
			--size: 24px;
			--color: var(--icon-color);
		}
	}

	&__info {
		display: flex;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		gap: 5px;
		position: relative;

		&__status {
			@extend %menu;

			&::before {
				content: var(--status);
			}
		}

		&__name {
			color: $text_color;
			flex: 1 1 0;
			display: var(--name-visibility);
			@extend %small-text;

			&__start {
				white-space: nowrap;
				width: 0;
				flex-grow: 1;
				max-width: max-content;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__end {}
		}
	}
}