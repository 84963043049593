.search-list {
	&__item {
		padding-bottom: 40px;
		border-bottom: 2px solid #E3E6E6;

		@media (max-width: $mobile) {
			padding-bottom: 20px;
		}

		&:not(:first-child) {
			padding-top: 40px;

			@media (max-width: $mobile) {
				padding-top: 20px;
			}
		}

		&__inner {
			max-width: 988px;
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__title {
			@extend %lead;
		}

		&__text {
			color: $text_color;
		}

		&__way {
			color: $text_color;
			@extend %small-text;
		}
	}
}