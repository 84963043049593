.popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #00000080;
	overflow: auto;
	display: flex;
	justify-content: center;
	padding: 64px;
	opacity: 1;
	z-index: 9999;
	@include transition-base;
	cursor: pointer;

	@media (max-width: $laptop) {
		padding: 48px;
	}

	@media (max-width: $tablet) {
		padding: 32px;
	}

	@media (max-width: $mobile) {
		padding: 0;
	}

	&:not(.show) {
		pointer-events: none;
		opacity: 0;
		overflow: hidden;
	}

	&--fit &__inner {
		padding: 0;
	}

	&--header-loyal {
		top: var(--header-height, 0);
	}

	&__inner {
		display: flex;
		flex-direction: column;
		cursor: initial;
		flex-shrink: 0;
		background-color: #FAFAFA;
		border-radius: 40px;
		padding: 160px;
		width: fit-content;
		margin: auto;
		position: relative;

		@media (max-width: $laptop) {
			padding: 80px;
			border-radius: 30px;
		}

		@media (max-width: $tablet) {
			padding: 40px;
			border-radius: 20px;
		}

		@media (max-width: $mobile) {
			padding: 20px;
			border-radius: 0px;
			width: 100%;
			min-height: 100%;
		}
	}

	&--header-loyal &__selfcloser {
		&--mobile {
			top: calc(12px + var(--header-height, 0));
		}
	}

	&__selfcloser {
		cursor: pointer;

		&--corner {
			position: absolute;
			right: 40px;
			top: 40px;
			display: flex;

			@media (max-width: $mobile) {
				position: fixed;
				right: 12px;
				top: 12px;
			}

			.icon {
				--size: 40px;
				--color: #{$blue};

				@media (max-width: $mobile) {
					--size: 32px;
				}
			}
		}
	}
}