.case {
	&__header {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 70px;

		@media (max-width: $laptop) {
			grid-template-columns: 1fr;
			gap: 20px;
		}

		&__title {
			grid-column: 1 / -1;
		}

		&__tags {
			grid-column: 1;
		}

		&__link {
			@media (max-width: $laptop) {
				grid-column: 1;
			}
		}
	}
}