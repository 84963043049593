.section-caption {
	font: 500 60px/70px Inter;
	color: #000;
	letter-spacing: -0.05em;

	@media (max-width: $laptop) {
		font-size: 40px;
	}

	@media (max-width: $mobile) {
		font-size: 30px;
		line-height: 30px;
	}
}