.step-cards {
	display: flex;
	flex-direction: column;
	gap: 40px;

	&__title {
		font-size: 24px;
		font-weight: 400;
		line-height: 28.8px;
		text-align: left;

		@media (min-width: $mobile) {
			display: none;
		}
	}

	&__cards {
		display: grid;
		gap: var(--column-gap);
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		grid-template-rows: auto auto minmax(269px, max-content);

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-template-rows: unset;
			gap: 40px;
		}
	}

	&__card {
		display: grid;
		grid-template-rows: subgrid;
		grid-row: span 3;
		position: relative;

		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;
			grid-row: unset;
		}

		&__header {
			padding: 30px;
			gap: 10px;
			border-radius: 20px 20px 5px 5px;
			background-color: #EFEEFB;
			text-align: center;
			color: #2A2546;
			font-size: 26px;
			font-weight: 700;
			line-height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__body {
			background-color: #fff;
			padding: 20px 30px;
			border-radius: 5px 5px 20px 20px;
		}

		&__step {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 30px;

			@media (max-width: $mobile) {
				margin-bottom: 20px;
			}

			&__content {
				display: flex;
				flex-direction: column;
				align-items: center;

				@media (max-width: $mobile) {
					display: grid;
					align-items: center;
					grid-template-columns: 60px auto;
				}
			}

			&__title {
				font-size: 46px;
				font-weight: 700;
				line-height: 56.07px;

				@media (max-width: $mobile) {
					grid-row: 1;
					grid-column: 1;
					font-size: 36px;
				}
			}

			&__caption {
				font-size: 24px;
				font-weight: 400;
				line-height: 29.26px;
				color: #595571;
				align-self: stretch;

				@media (max-width: $mobile) {
					grid-row: 2;
					grid-column: 1;
					font-size: 18px;
					line-height: 22px;
				}
			}

			&__decorator {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: $blue;
				margin-top: 40px;
				display: flex;
				align-items: center;
				justify-content: center;

				@media (max-width: $mobile) {
					grid-row: 1;
					grid-column: 2;
					margin-top: 0;
				}

				&::before {
					content: '';
					width: 150%;
					height: 1px;
					position: absolute;
					background-color: $blue;

					@media (max-width: $mobile) {
						width: 1px;
						height: 150%;
						z-index: -1;
					}
				}
			}
		}

		&:first-child &__step__decorator {
			justify-content: flex-start;

			@media (max-width: $mobile) {
				justify-content: center;
				align-items: flex-start;
			}
		}

		&:last-child &__step__decorator {
			justify-content: flex-end;

			@media (max-width: $mobile) {
				justify-content: center;
				align-items: flex-end;
			}
		}
	}
}