.steps {
	display: flex;
	flex-direction: column;
	gap: 80px;
	counter-reset: biba;

	&__item {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		gap: var(--column-gap);
		opacity: 0.3;
		--num-color:#{$text_color};
		--title-color:#{$text_color};
		--caption-color:#{$text_color};
		transition-property: color, opacity;
		transition-duration: 1s;
		transition-delay: .5s;
		transition-timing-function: ease;

		@media (max-width: $mobile) {
			grid-template-columns: min-content 1fr 1fr 1fr;
		}

		&--is-visible {
			opacity: 1;
			--num-color:#{$blue};
			--title-color: #000;
			--caption-color:#{$text_color};
		}

		&:nth-child(-n + 9) {
			&::before {
				content: "0" counter(biba);
			}
		}

		&::before {
			counter-increment: biba;
			content: counter(biba);
			grid-column: 1 / 3;
			@extend %h2;
			color: var(--num-color);
			transition: inherit;

			@media (max-width: $tablet) {
				grid-column: 1;
			}
		}

		&__title {
			@extend %h2;
			grid-column: 3 / 8;
			color: var(--title-color);
			transition: inherit;

			@media (max-width: $laptop) {
				grid-column: 3 / 7;
			}

			@media (max-width: $tablet) {
				grid-column: 2 / 6;
			}

			@media (max-width: $mobile) {
				grid-column: 2 / -1;
			}
		}

		&__caption {
			@extend %body;
			grid-column: 10 / 13;
			padding: 20px 0;
			color: var(--caption-color);
			transition: inherit;

			@media (max-width: $laptop) {
				grid-column: 9 / 13;
			}

			@media (max-width: $mobile) {
				grid-column: 1 / -1;
			}
		}
	}
}