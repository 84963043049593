.news-detail {
	&__with-more {
		margin-top: var(--row-gap);

		&__column {
			display: flex;
			flex-direction: column;
			gap: var(--row-gap);

			.container {
				--container-padding: 0px;
			}
		}

		@media (min-width: #{$laptop + 1px}) {
			display: grid;
			grid-template-columns: 1fr 433px;
			grid-auto-flow: dense;
			align-items: start;
			gap: 0 40px;

			section { grid-column: 1; }
			aside { grid-column: 2; }
		}
	}

	&__more {
		display: none;

		@media (min-width: #{$laptop + 1px}) {
			display: flex;
			flex-direction: column;
			gap: 20px;
			position: sticky;
			top: calc(var(--header-height) - 68px);

			&:is(header.header:not(.sticky) ~ main > section > &) {
				--header-height: 0px;
			}

			h2 {
				font: 500 40px/1.2 Inter;
				color: #000;
			}

			& &-article {
				display: flex;
				flex-direction: column;
				gap: 20px;
				padding: 20px;
				border-radius: 20px;
				border: 1px solid $text_color;
				color: #000;
				font: 500 20px/1.3 Inter;

				&:hover {
					border-color: $blue;
					color: $blue;
				}

				img {
					border-radius: 10px;
					object-fit: cover;
					width: 100%;
					height: 207px;
				}
			}

			&-want100 {
				position: relative;
				margin-top: 40px;
				background-color: $background_color;

				img {
					width: 100%;
					height: auto;
				}

				.button {
					position: absolute;
					bottom: 40px;
					left: 20px;
				}
			}
		}
	}

	&__offer {
		padding: 40px;
		display: flex;
		flex-direction: column;
		gap: 40px;
		background: $background_color;
		border-radius: 40px;
		color: $text_color;

		&__title {
			color: #000;
			@extend %h2;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 80px;
		margin-bottom: 80px;

		@media (max-width: $mobile) {
			margin-bottom: 24px;
			gap: 24px;
		}

		&__info {
			display: flex;
			align-items: center;
			gap: 20px;
		}
	}

	> p {
		margin-top: 40px;
		margin-bottom: 40px;
		color: $text_color;

		@media (max-width: $mobile) {
			margin-bottom: 16px;
			margin-top: 16px;
		}
	}

	> .title {
		margin-bottom: 40px;

		@media (max-width: $mobile) {
			margin-bottom: 16px;
		}
	}

	> .button {
		margin-top: 60px;
		margin-bottom: 60px;

		@media (max-width: $mobile) {
			margin-bottom: 24px;
			margin-top: 24px;
		}
	}

	> .lead {
		margin-bottom: 26px;
	}

	> * {
		&:last-child {
			margin-bottom: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	img {
		border-radius: 40px;

		@media (max-width: $mobile) {
			border-radius: 20px;
		}
	}
}