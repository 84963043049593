.techsupport-about {
	color: #000;

	.section__content {
		--summary-width: 322px;
		display: grid;
		grid-template: auto 1fr / 1fr var(--summary-width);
		gap: 80px 120px;

		@media (max-width: 1200px) { column-gap: 80px; }
		@media (max-width: $laptop) { gap: 60px 10px; }
		@media (max-width: $tablet) { --summary-width: 237px; }
		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 40px;
		}
	}

	.about__summary {
		grid-row: span 2;
		display: grid;
		grid-template-columns: 1fr;
		gap: 40px;

		&__item {
			display: flex;
			flex-direction: column;
			gap: 10px;
			font: 16px/1.2 Inter;
		}

		@media (max-width: $mobile) {
			grid-row: auto;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
		}
	}

	.company-info {
		display: flex;
		align-items: center;
		gap: 80px;

		@media (max-width: $laptop) {
			gap: 40px;
		}

		@media (max-width: $tablet) {
			flex-direction: column;
			align-items: flex-start;
		}

		@media (max-width: $mobile) {
			margin-top: 20px;
			grid-row: 2;
		}

		&__logo {
			width: 180px;
			height: 180px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			box-shadow: 10px 20px 60px 0px rgba(0, 0, 0, 0.05);

			img {
				width: 146px;
			}
		}

		&__details {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__name {
			font-weight: 500;
			line-height: 1;
			letter-spacing: -0.05em;
		}

		&__description p {
			--line-height: 1.3em;
			font: 24px/var(--line-height) Inter;

			& + p { margin-top: var(--line-height); }

			@media (max-width: $mobile) {
				--line-height: 1.2em;
				font-size: 18px;
			}
		}
	}
}