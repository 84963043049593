.landing2-faq {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 50px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	.faq__question {
		--expander-size: 64px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 30px 0;
		border-bottom: 1px solid #e3e6e6;

		&:first-child { padding-top: 0; }

		@media (max-width: $mobile) {
			--expander-size: 70px;
			gap: 16px;

			& + .faq__question {
				padding-top: 58px;
			}
		}
	}

	.faq__answer {
		color: #000;
		padding-right: calc(var(--expander-size) + 10px);
		font: 24px/1.3 Inter;

		&:is(:not(.faq__question.open .faq__answer)) {
			display: none;
		}

		@media (max-width: $mobile) {
			color: $text_color;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.lead {
		color: #000;
		letter-spacing: -0.05em;
		font-weight: 500;
		letter-spacing: normal;
	}

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 0;
	}

	.expander {
		--line-length: 14px;
		flex: 0 0 var(--expander-size);
		width: var(--expander-size);
		height: var(--expander-size);
		background-color: #fff;
		border-radius: 50%;
		position: relative;
		cursor: pointer;

		&::before, &::after {
			content: "";
			border-radius: 1px;
			width: var(--line-length);
			background-color: $blue;
			height: 2px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: width var(--transition-duration, .3s);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(90deg);
		}

		&:is(.faq__question.open .expander) {
			&::after {
				width: 0;
			}
		}
	}
}