%container-base {
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--container-padding);
	padding-right: var(--container-padding);
	width: 100%;
}

$container: 1320px;
$col: calc(#{$container} / 12 - var(--column-gap) * 11 / 12);

@function cols-width ($count) {
	@return calc(#{$count} * #{$col} + (#{$count} - 1) * var(--column-gap));
}

.container,
%container {
	@extend %container-base;
	max-width: calc($container + var(--container-padding, 0px) * 2);

	&-small {
		margin-left: 0;
		max-width: cols-width(8);
	}

	&-outer {
		max-width: calc($container + (var(--container-padding, 0px) + 140px) * 2);
		@extend %container-base;
	}

	&-unlimited {
		@extend %container-base;

		@media (max-width: $laptop) {
			padding: 0;
		}
	}
}