.custom-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: calc(var(--gap) * 2);
	--gap: 18px;

	@media (max-width: $mobile) {
		--gap: 12px;
	}

	&__title {}

	&__group {
		display: flex;
		gap: var(--gap);

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: stretch;
			gap: calc(var(--gap) * 2);
		}
	}

	&__col {
		flex: 1 1 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: var(--gap);

		&:empty {
			@media (max-width: $mobile) {
				display: none;
			}
		}
	}

	&__row {
		display: flex;
		gap: var(--gap);

		@media (max-width: $mobile) {
			flex-direction: column;
		}
	}

	&__cell {
		flex: 1 1 0;
		display: flex;
		flex-wrap: wrap;
		gap: calc(var(--gap) / 2);

		&--centered {
			justify-content: center;
		}

		&--big {
			flex: 3 1 0;
		}
	}
}