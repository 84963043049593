.text-autoprint {
	position: relative;
	display: flex;

	&__origin {
		opacity: 0;
		width: 0;
		display: block;
		white-space: nowrap;
		order: 1;

		&::before {
			content: " ";
		}
	}

	&::before {
		content: attr(data-text);
		display: inline;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&::after {
		content: "|";
		display: inline;
		animation: pulse 0.3s ease infinite alternate both;
	}

	@keyframes pulse {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}