.landing2-start-acting {
	.section__content {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 40px;
	}

	.steps, .tariffs {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(290px, $cols_maxWidth));
		gap: $cols_gap;

		@media (max-width: $cols_oneColOnly) {
			grid-template-columns: minmax(auto, $cols_maxWidth);
			justify-content: center;
			gap: 40px;
		}
	}

	.step, .tariff {
		display: flex;
		flex-direction: column;
		gap: 5px;

		&__caption {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 10px;
			padding: 30px;
			color: #2A2546;
			min-height: 92px;
			border-radius: 20px 20px 5px 5px;
			background-color: $background_color;
			font: bold 26px/32px Montserrat, Inter;
		}

		&__text {
			border-radius: 5px;
			background-color: #fff;
			padding: 20px 30px;

			&:last-child {
				border-radius: 5px 5px 20px 20px;
			}
		}

		@media (max-width: $cols_oneColOnly) {
			max-width: $cols_maxWidth;
		}
	}

	@media (min-width: #{$mobile + 1px}) {
		.tall_element { min-height: 269px; }
	}

	.button {
		align-self: center;

		@media (max-width: $cols_oneColOnly) {
			align-self: center;
		}

		@media (max-width: $mobile) {
			--px: 30px;
			max-width: 330px;
			font-size: 16px;
			line-height: 1.2;
		}
	}
}