.text-content,
%text-content {
	--p-magrin: 1em;

	& > &__block {
		margin-top: 100px;
		margin-bottom: 100px;

		@media (max-width: $mobile) {
			margin-top: 24px;
			margin-bottom: 24px;
		}

		:last-child {
			margin-bottom: 0;
		}

		p {
			@extend %body;
			color: $text_color;
			margin: var(--p-magrin) 0;
		}
	}

	& > &__title {
		color: #000;
		margin-bottom: 60px;
		margin-top: 100px;

		@media (max-width: $laptop) {
			margin-bottom: 40px;
		}

		@media (max-width: $tablet) {
			margin-top: 60px;
		}

		@media (max-width: $mobile) {
			margin-bottom: 20px;
		}
	}

	> p {
		@extend %body;
		color: $text_color;
		margin: var(--p-magrin) 0;
	}

	> img {
		margin: var(--p-magrin) 0;
	}

	> ul,
	> ol {
		margin: var(--p-magrin) 0;
		list-style-position: inside;

		li {
			&:nth-child(n+2) {
				// margin-top: 8px;
			}
		}
	}

	&__equal {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 40px;

		&--big {
			gap: 60px;
		}

		@media (max-width: $tablet) {
			gap: 20px;
		}
	}

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}