.clients {
	background: #EFF4FF;
	border-radius: 40px;
	padding: 160px 0;
	@extend %fullwidth-but-not;

	@media (max-width: $laptop) {
		padding: 60px 0;
		border-radius: 30px;
	}

	@media (max-width: $tablet) {
		padding: 40px 0;
		border-radius: 20px;
	}

	@media (max-width: $mobile) {
		padding: 20px 0;
		border-radius: 20px;
	}

	&__container {
		@extend %container;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 60px;

		@media (max-width: $tablet) {
			gap: 30px;
		}

		@media (max-width: $mobile) {
			gap: 20px;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__sup {
		@extend %menu;
	}

	&__title {
		@extend %h1;
	}

	&__text {
		@extend %title;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: var(--column-gap);

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__item {
			background: #fff;
			border-radius: 40px;
			padding: 30px;
			height: 260px;

			@media (max-width: $mobile) {
				height: auto;
				padding: 16px 30px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;

				@media (max-width: $mobile) {
					height: auto;
				}
			}
		}
	}
}