.button,
%button {
	@include title_desk;
	border-radius: 666px;
	@include transition-base;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	box-shadow: none;
	--border: 2px;
	--px: 50px;
	--py: 20px;
	--accent: #{$blue};
	white-space: nowrap;
	user-select: none;

	@media (max-width: $laptop) {
		font-size: 18px;
		--py: 14px;
	}

	@mixin small {
		@include menu_desk;
		--px: 30px;
		--py: 10px;
	}

	@media (max-width: $tablet) {
		--py: 10px;
		--px: 30px;
		font-size: 16px;
	}

	@media (max-width: $mobile) {
		@include small();
		width: 100%;
	}

	&--small {
		@include small();
	}

	&:hover {
		--accent: #0C43CF;
	}

	&--primary {
		padding: var(--py) var(--px);
		color: #fff;
		background-color: var(--accent);
	}

	&--secondary {
		padding: calc(var(--py) - var(--border) * 2) calc(var(--px) - var(--border) * 2);
		color: #000;
		border: var(--border) solid var(--accent);
		background-color: transparent;

		&--white {
			color: #fff;
			border-color: #fff;
		}

		&:hover {
			background-color: var(--accent);
			color: #fff;
		}
	}

	&--tertiary {
		gap: 10px;
		--size: 24px;
		--border: 1px;
		padding: calc(var(--py) - var(--border) * 2) calc(var(--px) - var(--border) * 2);
		background-color: transparent;
		color: #000;
		border: var(--border) solid $blue;
		--color: #000;

		&:hover:not(:disabled) {
			border-color: #0633A7;
			color: #0633A7;
			--color: #0633A7;
		}

		&:disabled {
			opacity: .4;
		}
	}

	&--quaternary {
		--border: 1px;
		padding: calc(var(--py) - var(--border) * 2) calc(var(--px) - var(--border) * 2);
		background-color: transparent;
		color: #fff;
		border: var(--border) solid #fff;

		&:hover:not(:disabled) {
			border-color: #0633A7;
			color: #0633A7;
		}

		&:disabled {
			opacity: .4;
		}
	}

	&--on-blue-bg:hover {
		background-color: #fff;
		color: var(--accent);
	}

	&--white-bg {
		background-color: #fff;
		color: #000;

		&:hover:not(:disabled) {
			color: $blue;
		}

		&:disabled {
			opacity: .4;
		}
	}

	&--yellow {
		--px: 50px;
		--py: 20px;
		background-color: #fbc117;
		border-color: #fbc117;
		color: #000;
		max-width: 266px;

		&:hover {
			background-color: #fbc117;
			border-color: #fbc117;
			color: #000;
		}

		@media (max-width: $tablet) {
			--px: 30px;
			--py: 10px;
			width: auto;
		}

		@media (max-width: $mobile) {
			--py: 10px;
			font-size: 16px;
			line-height: 30px;
			max-width: 330px;
		}
	}

	&-group {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}