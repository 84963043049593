.techsupport-form {
	@extend .techsupport-client;

	.container-unlimited .section__inner {
		background-color: #d2d6e1;
	}

	.container {
		grid-template-columns: 1fr;
		align-items: center;
		gap: 20px;
	}

	.custom-input input {
		--pb: 8px;
		border-bottom-color: #000;

		&:not(:disabled).is-invalid {
			color: #f00;
			border-bottom-color: #f00;
		}
	}

	.form {
		&__subheader {
			font: 500 20px/1.5 Inter;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 1.25;
			}
		}

		&__body {
			margin-top: 60px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 40px 20px;

			@media (max-width: $mobile) {
				margin-top: 20px;
				grid-template-columns: 1fr;
				gap: 20px;
			}
		}

		&__second-line {
			grid-column: 1 / -1;
			display: grid;
			grid-template-columns: subgrid;

			@media (max-width: $laptop) {
				grid-template-columns: 1fr 1fr;
				gap: 20px;
			}

			@media (max-width: $tablet) {
				grid-template-columns: 1fr 190px;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}

		&__notice {
			grid-column: span 2;
			font: 14px/20px Inter;

			@media (max-width: $laptop) {
				grid-column: 1;
			}

			@media (max-width: $mobile) {
				br { display: none; }
			}

			a {
				color: $blue;
				text-decoration: underline;

				&:hover { text-decoration: none; }
			}
		}
	}


	.button {
		--py: 20px;
		border: none;

		@media (max-width: $tablet) {
			--py: 10px;
		}
	}
}