.custom-list,
%custom-list {
	display: flex;
	flex-direction: column;
	color: $text_color;
	gap: 10px;

	&__disabled:is(li) {
		color: rgba($text_color, 0.5);

		&::before {
			opacity: 0;
		}
	}

	li {
		display: flex;
		align-items: flex-start;
		gap: 10px;

		&::before {
			margin-top: 8px;
			flex-shrink: 0;
			content: "";
			background: $blue;
			width: 10px;
			height: 10px;
			border-radius: 50%;

			@media (max-width: $mobile) {
				width: 6px;
				height: 6px;
			}
		}
	}
}