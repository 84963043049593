.banner {
	display: block;
	background: $blue;
	color: #fff;
	position: relative;
	overflow: hidden;

	@media (max-width: $mobile) {
		display: none;
	}

	&__container {
		@extend %container;
	}

	&__content {
		display: grid;
		grid-template-columns: auto auto auto;
		grid-template-rows: minmax(60px, auto);
		align-items: center;
		gap: 20px;

		@media (max-width: $tablet) {
			grid-template-rows: minmax(40px, auto);
		}
	}

	&__img {
		position: relative;
		align-self: stretch;
		height: 100%;
		align-items: stretch;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-50%);
			background: #fff;
			filter: blur(125px);
			width: 700px;
			height: 100%;
		}

		&__img {
			position: relative;

			img {
				width: auto;
				height: 100%;
			}
		}

		&__pill {
			position: absolute;
			left: 0;
			top: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-50%);
			padding: 0 20px;
			height: 40px;
			background: #d63971;
			border-radius: 666px;
			@extend %title;

			@media (max-width: $tablet) {
				height: 30px;
			}
		}
	}

	&__title {
		@extend %title;
		justify-self: center;
		text-align: center;

		@media (min-width: $laptop) {
			white-space: nowrap;
		}
	}

	&__caption {
		text-align: center;
		@extend %menu;
		justify-self: flex-end;
	}

	&-popup {
		@media (min-width: #{$mobile + 1px}) {
			display: none !important;
		}

		&__body {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__title {
			@extend %h2;
		}

		&__caption {
			@extend %body;
		}

		&__btn {
			.button {
				width: min-content;
			}
		}

		&__img {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&.popup {
			padding: 32px var(--container-padding);

			.popup {
				&__inner {
					overflow: hidden;
					position: relative;
					background: $blue;
					border-radius: 20px;
					color: #fff;
					padding: 40px 20px 165px 20px;
					min-height: 0;
				}

				&__selfcloser {
					position: absolute;
					padding: 8px;
					top: 0;
					right: 0;
					--color: #fff;
				}
			}
		}
	}
}