.expert-banner {
	@extend %container;

	&__img {
		background-image: url(/src/assets/static/images/laurel.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		@media (max-width: $mobile) {
			max-width: 320px;
		}
	}

	&__inner {
		display: flex;
		gap: var(--column-gap);

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		&__left,
		&__right {
			flex: 1 1 0;
		}

		&__left {
			padding-bottom: 60px;
		}

		&__right {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
		}

		&__title {}

		&__caption {
			margin-top: 80px;
			@extend %body;
			color: $text_color;

			@media (max-width: $laptop) {
				margin-top: 60px;
			}

			@media (max-width: $tablet) {
				margin-top: 40px;
			}

			@media (max-width: $mobile) {
				margin-top: 20px;
			}
		}

		&__footer {
			margin-top: 60px;

			@media (max-width: $laptop) {
				margin-top: 45px;
			}

			@media (max-width: $tablet) {
				margin-top: 30px;
			}

			@media (max-width: $mobile) {
				margin-top: 15px;
			}
		}
	}
}