.quote {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: min-content min-content 1fr;
	gap: 16px 10px;

	@media (max-width: $laptop) {
		gap: 10px;
	}

	@media (max-width: $tablet) {
		grid-template-columns: min-content min-content 1fr 1fr 1fr;
		grid-template-rows: auto auto auto;
	}

	&__photo {
		grid-column: 1 / span 2;
		grid-row: 1;

		@media (max-width: $tablet) {
			grid-row: 1 / span 2;
			margin-right: 10px;
			margin-bottom: 10px;
		}

		img {
			width: 160px;
			height: 160px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;

			@media (max-width: $laptop) {
				width: 100px;
				height: 100px;
			}
		}
	}

	&__name {
		grid-column: 1 / span 2;
		grid-row: 2;
		@extend %title;

		@media (max-width: $tablet) {
			grid-row: 1;
			grid-column: 3 / span 3;
			align-self: flex-end;
		}
	}

	&__caption {
		grid-column: 1 / span 2;
		grid-row: 3;
		@extend %small-text;

		@media (max-width: $tablet) {
			grid-row: 2;
			grid-column: 3 / span 3;
			align-self: flex-start;
		}
	}

	&__text {
		grid-column: 3 / -1;
		grid-row: 2 / span 2;

		@media (max-width: $tablet) {
			grid-row: 3 / span 1;
			grid-column: 1 / -1;
		}
	}
}