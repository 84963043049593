.techsupport-workplan {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $laptop) { gap: 60px; }
		@media (max-width: $mobile) { gap: 40px; }
	}

	.workplan-list {
		max-width: 988px;
		gap: 12px;
		color: #000;

		li {
			font-size: 24px;
			line-height: 1.3;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 1.25;
			}
		}
	}

	.button {
		max-width: 427px;

		@media (max-width: $laptop) {
			--py: 20px;
			font-size: 20px;
		}

		@media (max-width: $tablet) {
			--py: 10px;
			max-width: 229px;
			font-size: 16px;
		}

		@media (max-width: $mobile) {
			max-width: 330px;
		}
	}
}