.feedback-block {
	display: flex;
	flex-direction: column;

	&__title {
		margin-bottom: 60px;

		@media (max-width: $laptop) {
			margin-bottom: 48px;
		}

		@media (max-width: $mobile) {
			margin-bottom: 24px;
		}
	}

	&__text {
		@extend %title;
		margin-bottom: 40px;

		@media (max-width: $laptop) {
			margin-bottom: 30px;
		}

		@media (max-width: $mobile) {
			margin-bottom: 16px;
		}
	}

	&__footer {}
}