.custom-input {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
	$parent: &;
	@extend %body;
	position: relative;

	&__label {
		order: 1;
		@extend %small-text;
		color: $text_color;
	}

	&__helper {
		order: 3;
		@include transition-base;
		@extend %small-text;
		height: 0;
		opacity: 0;
	}

	%base {
		--pb: 10px;
		order: 2;
		outline: none;
		@include transition-base;
		appearance: initial;
		padding-bottom: var(--pb);
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		border: none;
		background-color: transparent;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: #DFE3EF;
		width: 100%;
		color: #000000;

		&:disabled {
			~ #{$parent}__helper {}

			~ #{$parent}__label {}
		}

		&:not(:disabled) {
			&:hover {}

			&:focus {}
		}
	}

	input,
	textarea {
		@extend %base;

		&::placeholder {
			color: $text_color;
		}

		&:not(:disabled) {
			&:not(:focus):not(:hover):not(:placeholder-shown) {
				&:valid {
					~ #{$parent}__helper {}

					~ #{$parent}__label {}
				}

				&:invalid {
					border-bottom-color: #E8002A;

					~ #{$parent}__helper {
						color: #E8002A;
					}

					~ #{$parent}__label {}
				}
			}

			&.is-invalid {
				border-bottom-color: #E8002A;

				~ #{$parent}__helper {
					opacity: 1;
					color: #E8002A;
				}

				~ #{$parent}__label {}
			}
		}
	}

	textarea {
		resize: none;
		height: calc(4em + var(--pb) + 10px);
	}
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
	appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}