.tariff {
	&__tabs {
		&__select-control {
			padding-bottom: 10px;
			border-bottom: 2px solid #DFE3EF;
			display: flex;

			select {
				width: 100%;
				background: transparent;
				border: none;
				outline: none;
				appearance: none;
				padding-right: 20px;
				cursor: pointer;
				background-image: url(/src/assets/static/images/icon/arrow-down.svg);
				background-size: 12px;
				background-position: right center;
				background-repeat: no-repeat;
				@extend %title;
			}
		}

		&__controls {
			display: flex;
			gap: 40px;

			&__item {
				cursor: pointer;

				input[type=radio]:checked ~ &__visual {
					color: #000000;

					&::before {
						content: "";
						border-top: 1px solid $blue;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}

				&__visual {
					display: block;
					padding-top: 5px;
					position: relative;
					@extend %title;
					color: $text_color;
				}
			}
		}

		&__content {
			&__text {
				color: $text_color;
				@extend %small-text;
				max-width: 1098px;
			}
		}
	}

	&__advantages {
		display: flex;
		gap: var(--column-gap);
		justify-content: space-between;

		@media (max-width: $tablet) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: $laptop) {
			gap: 24px;
		}

		&::before,
		&::after {
			content: "";
			flex-shrink: 0;
			width: calc((100% - (var(--column-gap) * 11)) / 12);

			@media (max-width: $laptop) {
				display: none;
			}
		}

		&__item {
			flex: 1 1 0;
			text-align: center;

			@media (min-width: $laptop) {
				max-width: calc((100% - (var(--column-gap) * 11)) / 6);
			}

			&__img {
				--size: 125px;
				--color: #{$blue};
				display: flex;
				justify-content: center;

				@media (max-width: $laptop) {
					--size: 100px;
				}

				@media (max-width: $tablet) {
					--size: 85px;
				}

				@media (max-width: $mobile) {
					--size: 70px;
				}

				img {
					width: var(--size);
					height: var(--size);
				}
			}

			&__text {
				@extend %menu;
			}
		}
	}

	&__table {
		overflow: visible;

		@media (max-width: $mobile) {
			// word-wrap: break-word;
			word-break: break-word;
			margin: 0 -10px;
		}

		&-group {
			--table-row-header-width: 324px;
			overflow: auto;

			@media (max-width: $laptop) {
				margin: calc(var(--container-padding) * -1);
				padding: var(--container-padding);
			}

			@media (max-width: $mobile) {
				--table-row-header-width: 155px;
			}
		}

		&-group & {
			&:first-of-type {}
		}

		&--choose {
			margin-top: 40px;

			@media (max-width: $mobile) {
				margin-top: 10px;
			}

			thead {
				tr {
					th:not(:first-child) {
						border-radius: 10px;
					}
				}
			}
		}

		&--releases {
			&:not(:first-child) {
				margin-top: 80px;

				@media (max-width: $mobile) {
					margin-top: 40px;
				}
			}

			tbody {
				tr {
					&:first-child {
						td {
							&:not(:first-child) {
								position: relative;

								&::after {
									position: absolute;
									top: 0;
									left: 50%;
									transform: translateX(-50%);
									content: "";
									border-top: 1px solid #D4D9EF;
									width: calc(100% - 31px);
								}
							}
						}
					}
				}
			}
		}

		&--licenses {
			margin-top: 20px;

			@media (max-width: $mobile) {
				margin-top: 10px;
			}

			.custom-list {
				margin-top: 20px;
			}

			thead {
				tr {
					th:not(:first-child) {
						border-radius: 10px;
					}
				}
			}

			tbody {
				tr {
					td {
						background: #FAFAFA;
					}
				}
			}
		}

		&__legend {
			display: flex;
			justify-content: center;
			padding-left: var(--table-row-header-width);
			padding-right: calc((100% - (var(--column-gap) * 11)) / 12);
			gap: 20px;
			margin-top: 40px;

			@media (max-width: $mobile) {
				padding-left: 0;
				padding-right: 0;
				gap: 5px;
				justify-content: flex-start;
				position: sticky;
				left: 0;
			}

			&__item {
				@extend %small-text;
				color: $text_color;
				display: flex;
				align-items: center;
				gap: 20px;

				@media (max-width: $mobile) {
					flex-direction: column;
					text-align: center;
					gap: 5px;
					width: 0;
					flex-grow: 1;
					word-break: break-all;
				}
			}
		}

		&__choose {
			padding: 20px 25px;
			display: flex;
			flex-direction: column;
			height: 100%;
			text-align: left;
			gap: 10px;

			@media (max-width: $mobile) {
				padding: 10px;
			}

			&-header {
				@extend %big-title;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 21px;

				&__select {
					padding-bottom: 10px;
					border-bottom: 2px solid #DFE3EF;
					display: inline-flex;
					max-width: 100%;

					select {
						max-width: 100%;
						background: transparent;
						border: none;
						outline: none;
						appearance: none;
						padding-right: 20px;
						cursor: pointer;
						background-image: url(/src/assets/static/images/icon/arrow-down.svg);
						background-size: 12px;
						background-position: right center;
						background-repeat: no-repeat;
						@extend %body;
					}
				}
			}

			&__sup {
				color: $text_color;
				@extend %small-text;
			}

			&__price {
				display: flex;
				align-items: baseline;
				gap: 4px;

				@media (max-width: $mobile) {
					flex-wrap: wrap;
					align-items: flex-start;
				}

				&__sub {
					@extend %menu;
					margin-right: auto;
				}

				&__pill {
					padding: 2px 5px;
					border-radius: 666px;
					background: $blue;
					color: #fff;
					font-weight: 500;
					font-size: 12px;
					line-height: 20px;
					align-self: center;

					@media (max-width: $mobile) {}
				}
			}

			&__cost {
				@extend %small-text;
				color: $text_color;

				&__line-through {
					margin-right: 4px;
					text-decoration: line-through;
				}
			}

			&__btn {
				margin-top: auto;

				.button {
					width: 100%;
				}
			}
		}

		&__th {
			display: flex;
			flex-direction: column;
			height: 100%;

			&__title {}

			&__caption {
				@extend %small-text;
				color: $text_color;
			}

			&__header {
				padding: 5px 30px;

				@media (max-width: $mobile) {
					padding: 10px;
				}
			}

			&__body {
				padding: 10px 20px;
				background: #FAFAFA;
				border-radius: 10px;
				color: $text_color;
				flex-grow: 1;
				display: flex;
				align-items: center;
				@extend %small-text;

				@media (max-width: $mobile) {
					padding: 10px;
				}
			}
		}

		&__rating {
			pointer-events: none;
			--size: 16px;
			--border-width: 2px;
			--color: #{$blue};
			--bg: #EEF0F9;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: center;

			&__item {
				width: calc(var(--size) + var(--border-width) * 2);
				height: calc(var(--size) + var(--border-width) * 2);
				border-radius: 50%;
				visibility: hidden;
				position: relative;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				appearance: none;
				display: none;

				&:not(:last-child) {
					margin-left: calc(var(--size) * -0.5);
				}

				&:last-child {
					display: flex;
				}

				&.checked {
					display: flex;

					&::before {
						background: var(--color);
					}
				}

				&.checked ~ & {
					display: flex;

					&::before {
						background: var(--color);
					}
				}

				&::before {
					background: transparent;
					visibility: visible;
					position: absolute;
					border-radius: inherit;
					content: "";
					border: var(--border-width) solid var(--color);
					width: var(--size);
					height: var(--size);
				}

				&::after {
					background: transparent;
					visibility: visible;
					position: absolute;
					border-radius: inherit;
					content: "";
					width: 100%;
					height: 100%;
					border: var(--border-width) solid var(--bg);
				}
			}
		}

		&__rowhead {
			@extend %small-text;
			display: grid;
			grid-template-columns: minmax(24px, min-content) 1fr;
			gap: 20px;
			align-items: center;

			@media (max-width: $mobile) {
				grid-template-columns: minmax(20px, min-content) 1fr;
				gap: 10px;
			}

			&__hint {
				display: flex;

				@media (max-width: $mobile) {
					--size: 20px;
				}
			}

			&__text {
				min-width: 0;
				width: 100%;
				color: $text_color;

				@media (max-width: $mobile) {
					word-break: break-word;
				}

				&:first-child {
					grid-column: 2;
				}
			}
		}

		&__td {
			@extend %menu;
			padding: 15px 10px;
			text-align: center;
		}

		col {
			&:not(:first-child) {
				background: #EEF0F9;
				min-width: 211px;

				@media (max-width: $laptop) {
					min-width: 190px;
				}

				@media (max-width: $tablet) {
					min-width: 180px;
				}

				@media (max-width: $mobile) {
					min-width: 120px;
					width: 120px;
				}
			}

			&:first-child {
				width: var(--table-row-header-width);
				min-width: var(--table-row-header-width);
			}
		}

		thead {
			th {
				vertical-align: top;
				@extend %title;
				text-align: left;

				&:not(:first-child) {
					text-align: center;
					border-radius: 10px 10px 0 0;
				}
			}
		}

		tbody {
			tr {
				&:not(:last-child) {
					td {
						&:not(:first-child) {
							position: relative;

							&::after {
								position: absolute;
								bottom: 0;
								left: 50%;
								transform: translateX(-50%);
								content: "";
								border-bottom: 1px solid #D4D9EF;
								width: calc(100% - 31px);
							}
						}
					}
				}

				&:last-child {
					td {
						border-radius: 0 0 10px 10px;
					}
				}
			}
		}

		table {
			table-layout: fixed;
			border-spacing: 10px 0;
			height: 1px;
			width: 100%;
		}
	}
}