.popup .request_new,
.popup .request_done {
	--container-padding: 0px;
	padding: 60px;
	color: #000;
	max-width: 1320px;
	width: 100%;

	@media (max-width: $mobile) {
		padding: 40px 15px;
	}

	.popup__selfcloser--corner {
		top: 60px;
		right: 60px;

		@media (max-width: $mobile) {
			top: 40px;
			right: 15px;
		}
	}

	header {
		margin-right: 144px;
		margin-bottom: 80px;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: $tablet) {
			margin-right: 84px;
		}

		@media (max-width: $mobile) {
			margin-bottom: 60px;
		}
	}

	h1 {
		font: 500 60px/70px Inter;
		letter-spacing: -0.05em;

		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1.2;
		}
	}

	.subheader {
		margin-top: 20px;
		font: 500 20px/1.5 Inter;

		@media (max-width: $tablet) {
			max-width: 510px;
		}

		@media (max-width: $mobile) {
			font-size: 18px;
			line-height: 1.2;
		}
	}

	.formFields {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 40px;

		@media (max-width: $tablet) {
			grid-template-columns: 1fr;
		}
	}

	.formField {
		font: 16px/1.5 Inter;

		input {
			width: 100%;
			border: none;
			padding: 0 0 8px 0;
			border-bottom: 1px solid #dfe3ef;
			background-color: transparent;
			appearance: none;

			&::placeholder {
				color: #000;
				opacity: 1;
			}

			&:hover,
			&:focus {
				border-bottom-color: #000;
			}

			&.is-invalid {
				border-bottom-color: red;
				color: red;

				&::placeholder {
					color: red;
				}
			}
		}
	}

	.underline {
		--agreement-width: calc(2 * (100% - 80px) / 3 + 40px);
		margin-top: 40px;
		display: grid;
		grid-template-columns: var(--agreement-width) 1fr;
		gap: 20px;

		@media (max-width: $tablet) {
			grid-template-columns: 1fr 1fr;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	.agreement {
		color: $text_color;
		font: 14px/20px Inter;

		a {
			color: #306aff;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	button[type=submit] {
		border: none;

		@media (max-width: $mobile) {
			max-width: 420px;
			justify-self: center;
		}
	}
}