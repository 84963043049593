.landing2-lets-discuss {
	.section__content {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 50px;

		@media (max-width: $laptop) {
			gap: 40px;
		}

		@media (max-width: $mobile) {
			gap: 24px;
		}
	}

	.button {
		max-width: 330px;

		@media (max-width: $mobile) {
			font-size: 16px;
			line-height: 1.2;
		}
	}
}