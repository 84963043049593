@mixin Overline {
	&::before {
		position: absolute;
		content: "";
		top: -5px;
		left: 0;
		width: 100%;
		border-top: 1px solid $blue;
	}
}

.landing2-inner-navigation {
	margin-top: calc(40px - var(--row-gap));
	z-index: 1;
	position: sticky;
	top: var(--header-height);
	transition: top 0.7s;

	&:is(header.header:not(.sticky) ~ * > &) {
		top: 0;
	}

	.section__content {
		background-color: $background_color;
		padding: 20px 40px;
		font: 500 20px/1.5 Inter;
		color: $text_color;
		border-radius: 20px;
		display: flex;
		justify-content: center;

		@media (max-width: 1230px) {
			background-color: transparent;
			padding: 0;
		}
	}

	.mobile-view {
		display: none;

		@media (max-width: 1230px) {
			display: block;
			width: 100%;

			.ux-select {
				min-width: 330px;
				--uxs-font-size: 16px;
				--uxs-line-height: 20px;
				--uxs-padding-x: 15px;
				--uxs-padding-y: 9px;
				--uxs-border-radius: 20px;
				--uxs-border-width: 0;
				--uxs-text-color: #616177;
				--uxs-background-color: #eef0f9;
				--uxs-option-selected-bg-color: transparent;
				--uxs-option-hovered-bg-color: transparent;

				&.custom-select {
					padding-top: 20px;
					padding-bottom: 20px;

					// Это на случай, если у меню сверху должны быть прямые углы
					/* &:is(.sticked .custom-select) {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					} */
				}

				&-group__elem, &__title {
					font: 500 20px/1.5 Inter;
				}

				&-group__elem {
					&:last-child { padding-bottom: 20px; }

					&:not(.-disabled) {
						font-weight: 500;

						&:hover { color: #000; }

						&:active { color: $blue }

						&:not(.-disabled).-selected {
							font-weight: 500;
							position: relative;
						}
					}

					&.-disabled { display: none; }
				}

				&__head::after { margin-left: auto; }

				&__title {
					position: relative;
					flex-grow: 0;
					color: #000;
				}

				&__body {
					top: 0;
					padding-top: 70px;  // Высота селекта
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					z-index: -1;
				}

				&__dropdown {
					max-height: calc((var(--uxs-font-size) * var(--uxs-line-height) + var(--uxs-padding-y) * 2) * 8);
				}

				&__clear { display: none; }
			}
		}
	}

	.navUl {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		gap: 40px;

		@media (max-width: 1230px) {
			display: none;
		}

		li {
			position: relative;
			cursor: pointer;

			&:hover, &.active {
				color: #000;
				@include Overline;
			}
		}
	}
}