.landing2-trusters {
	.section__inner {
		background-color: #EFF4FF;
		border-radius: 40px;
		padding: 160px 0;

		@media (max-width: $mobile) {
			margin: 0 15px;
			padding: 20px 0;
		}
	}

	.partners {
		padding: 0;

		&__inner {
			max-width: 100%;
			padding: 0;

			@media (max-width: $mobile) {
				gap: 0;

				.splide__track .splide__list {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.partners__slider__card__link { display: none; }

					.partners__slider__card {
						padding: 22px;
						height: 100px;
						--opacity: 1;
					}
				}
			}
		}
	}

	header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $mobile) {
			.partners__slider__controls { display: none; }
		}
	}

	.h2 {
		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1.2;
			padding: 14px 0 9px 16px;
		}
	}
}