* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
	text-decoration: none;

	&::before,
	&::after {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		outline: none;
		text-decoration: none;
	}
}

img:where(:not(.custom-style)) {
	width: 100%;
	height: auto;
	object-fit: contain;
	display: block;
}

main {
	display: flex;
	flex-direction: column;
	gap: var(--row-gap);

	&.small-gap {
		--row-gap: 80px;

		@media (max-width: $mobile) {
			--row-gap: 60px;
		}
	}
}

html {
	overflow-x: hidden;
	scroll-behavior: smooth;
	background-color: #fafafa;
}

body {
	&.noscroll {
		overflow-x: hidden;
		overflow-y: hidden;
		top: calc(-1 * var(--scroll-position, 0));
		padding-right: var(--scrollbar-width, 0);
		position: fixed;
		width: 100%;
	}
}

.hide-mobile,
%hide-mobile {
	@media (max-width: $mobile) {
		display: none !important;
	}
}

.d-mobile,
%d-mobile {
	@media (min-width: #{$mobile + 1px}) {
		display: none !important;
	}
}

.d-desktop,
%d-desktop {
	@media (max-width: $mobile) {
		display: none !important;
	}
}

[hidden] {
	display: none !important;
}

%default-shadow {
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}

%fullwidth-but-not {
	@media (max-width: $laptop) {
		margin-left: var(--container-padding);
		margin-right: var(--container-padding);
	}
}