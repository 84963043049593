.main-banner {
	position: relative;
	@extend %fullwidth-but-not;

	&__inner {
		@extend %container;
		position: relative;
		z-index: 2;
		pointer-events: none;

		@media (max-width: $laptop) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__content {
		max-width: 1172px;
		display: flex;
		flex-direction: column;
	}

	&__gallery {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@media (max-width: $mobile) {
			order: -1;
			position: static;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 140px;
		position: relative;
		z-index: 1;
		pointer-events: auto;
		align-items: flex-start;
		@extend %menu;

		a {
			color: $blue;
		}

		@media (max-width: $laptop) {
			margin-top: 110px;
		}

		@media (max-width: $mobile) {
			margin-top: 60px;
			flex-direction: column;
		}
	}

	h1 {
		@media (max-width: $mobile) {
			font-size: 36px;
		}
	}

	&__sup {
		margin-bottom: 40px;
		@extend %big-title;
		color: rgb(0, 0, 0, 0.1);

		@media (max-width: $mobile) {
			margin-bottom: 20px;
		}
	}
}