.cards-request {
	padding: var(--row-gap) 0;
	background: #EFF4FF;
	border-radius: 40px;

	@media (max-width: $laptop) {
		// border-radius: 0;
	}

	&__container {
		@extend %container;
	}

	&__content {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(12, 1fr);

		@media (max-width: $laptop) {
			grid-template-columns: 656px 1fr;
		}

		@media (max-width: $tablet) {
			grid-template-columns: 1fr 267px;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	&__cards {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 1fr;
		gap: var(--column-gap);

		@media (min-width: $laptop) {
			grid-column: 1 / 7;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__item {
			min-width: 0;
			padding: 40px;
			border-radius: 40px;
			background: #fff;
			display: flex;
			flex-direction: column;
			cursor: pointer;
			user-select: none;
			gap: 20px;

			@media (max-width: $tablet) {
				padding: 30px 20px;
			}

			&__content {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}

			&__title {
				@extend %big-title;

				@media (min-width: $laptop) {
					white-space: nowrap;
				}
			}

			&__price {
				&__caption {
					color: $text_color;
					margin-bottom: 5px;
					@extend %small-text;
				}

				&__nums {
					@extend %h2;

					@media (max-width: $mobile) {
						display: inline;
					}
				}

				&__measure {
					font-weight: 500;
					font-size: 30px;
					line-height: 40px;
					letter-spacing: -0.05em;

					@media (max-width: $tablet) {
						font-size: 20px;
						line-height: 30px;
					}

					@media (max-width: $mobile) {
						font-size: 16px;
						display: inline;
					}
				}
			}

			&__terms {
				&__caption {
					display: inline;
					@extend %small-text;
					color: $text_color;
				}

				&__value {
					display: inline;
					@extend %title;
				}
			}

			&__footer {
				margin-top: auto;
			}
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 60px;

		@media (min-width: $laptop) {
			grid-column: 9 / 13;
		}

		@media (max-width: $laptop) {
			gap: 40px;
		}

		&__title {
			@extend %h2;
		}

		&__group {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $laptop) {
				gap: 20px;
			}

			&__title {
				@extend %big-title;
			}

			&__content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
			}
		}

		&__checkbox-group {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px 40px;
			position: relative;

			&.is-invalid {
				&::after {
					position: absolute;
					top: calc(100% + 8px);
					left: 0;
					color: #E8002A;
					content: "* Укажите вид услуги";
					@extend %small-text;
				}
			}

			@media (max-width: $laptop) {
				grid-template-columns: 1fr;
			}
		}
	}
}