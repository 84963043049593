.team {
	display: flex;
	flex-direction: column;
	gap: 80px;

	@media (max-width: $laptop) {
		gap: 40px;
	}

	&__item {
		display: grid;
		grid-template-columns: 323px 1fr;
		gap: 20px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__photo {
			@include proportional-block(10, 13);
			& {
				border-radius: 40px;
			}

			@media (max-width: $laptop) {
				@include proportional-block(323, 572);
			}

			@media (max-width: $mobile) {
				@include proportional-block(290, 321);
			}

			img {
				background: #D6DCEA;
				border-radius: 40px;
				object-position: bottom;
			}
		}

		&__info {
			padding: 20px;
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		&__name {
			@extend %h2;
		}

		&__post {
			@extend %title;
			max-width: 843px;
		}

		&__description {
			display: flex;
			align-items: flex-start;
			gap: 110px;

			@media (max-width: $laptop) {
				flex-direction: column;
				gap: 40px;
			}

			&__block {
				display: flex;
				flex-direction: column;
				gap: 10px;
				@extend %body;

				&__title {
					color: $text_color;
				}
			}
		}

		&__mail {
			color: #{$blue};
			@extend %title;
		}
	}
}