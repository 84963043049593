@mixin HeaderWithArrows {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	.splide__arrow {
		&:not(:disabled) {
			&::before { background-color: #000; }
			&:hover::before { background-color: $blue; }
		}
	}
}

.universal {
	&-inner-navigation {
		position: sticky;
		top: var(--header-height);
		z-index: 1;
		transition: top 0.7s;

		&:is(header.header:not(.sticky) + * > &) {
			top: 0;
		}

		.section__content {
			padding-block: 20px;
			display: flex;
			justify-content: center;
			font: 500 14px/1.3 Inter;
			background-color: $grey_color;
			border-radius: 20px;

			@media (max-width: $tablet) {
				padding: 23px 28.5px;
			}

			@media (max-width: $mobile) {
				padding: 22px 20px;
				font-size: 12px;
				line-height: 20px;
			}
		}

		.navUl {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;

			@media (min-width: #{$tablet + 1px}) {
				gap: 30px;
			}

			li { cursor: pointer; }
		}

		.splide--slide {
			display: flex;
			overflow-x: hidden;
			gap: 30px;

			@media (max-width: $mobile) {
				gap: 10px;
			}
		}

		.splide__track { order: 2; }

		.splide__arrows {
			display: none;
			img { filter: grayscale(1) contrast(10); }

			@media (max-width: $tablet) {
				display: contents;
			}

			.splide__arrow {
				appearance: none;
				border: none;
				background-color: transparent;
				width: 24px;
				aspect-ratio: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.splide__arrow--prev { order: 1; }
		.splide__arrow--next {
			order: 3;

			img { transform: rotate(180deg); }
		}
	}

	&-problems {
		h2 {
			margin-bottom: 40px;

			@media (max-width: $mobile) {
				margin-bottom: 30px;
			}
		}

		&__cards {
			--columns-count: 3;
			display: grid;
			grid-template-columns: repeat(var(--columns-count), 1fr);
			align-items: center;
			justify-items: center;
			gap: 10px;

			@media (max-width: $tablet) {
				--columns-count: 2;
			}

			@media (max-width: $mobile) {
				--columns-count: 1;
			}

			.button {
				max-width: 100%;
				line-height: 1.3;
				grid-column: -2;

				@media (max-width: $tablet) {
					--py: 14px;

					@media (min-width: #{$mobile + 1px}) { font-size: 18px; }
				}

				@media (max-width: $mobile) {
					width: 100%;
					margin-block: 20px;
				}
			}
		}

		&__card {
			width: 100%;
			border: 1px solid $blue;
			border-radius: 20px;
			min-height: 170px;
			padding: 37px 40px;
			font: 32px/1 Inter;
			letter-spacing: -0.05em;

			@media (max-width: $laptop) {
				padding: 40px;
				min-height: 176px;
			}

			@media (max-width: $laptop) {
				min-height: 208px;
			}

			@media (max-width: $mobile) {
				min-height: 112px;
				padding: 20px;
				font-size: 24px;
			}
		}
	}

	&-banner {
		.support__banner {
			gap: 100px;

			&::before {
				background-image: url(/src/assets/static/images/support/banner-logo.svg);
				background-position: center right 195px;
				background-size: auto 170%;

				@media (max-width: $laptop) {
					background-position: center right 28px;
				}

				@media (max-width: $tablet) {
					background-position: center right -100px;
				}

				@media (max-width: $mobile) {
					background-size: auto 250%;
					background-position: center right -310px;
				}
			}

			@media (max-width: $laptop) {
				gap: 20px;
			}

			@media (max-width: $tablet) {
				flex-direction: column;
				gap: 40px;
				align-items: flex-start;
			}

			@media (max-width: $mobile) {
				padding: 20px;
			}
		}

		h2 {
			font: 500 40px/1.2 Inter;
			letter-spacing: normal;

			@media (max-width: $mobile) {
				font-size: 20px;
				line-height: 1.4;
			}
		}
	}

	&-abilities {
		.header-60 { margin-bottom: 60px; }

		.crm-elements-grid {
			--columns-count: 2;
			column-gap: 30px;
			padding: 0;
			margin: 0;

			@media (max-width: $laptop) {
				gap: 30px;
			}

			@media (max-width: $mobile) {
				--columns-count: 1;
			}
		}

		.crm-element {
			@media (max-width: $laptop) {
				gap: 10px;
			}

			.title {
				font-size: 32px;
				line-height: 1.2;
				color: $blue;

				@media (max-width: $laptop) {
					font: bold 18px/1.5 Inter;
				}

				@media (max-width: $mobile) {
					font-size: 14px;
					line-height: 20px;
				}
			}

			&__value {
				color: #000;
				font-size: 24px;
				line-height: 1.3;

				@media (max-width: $laptop) {
					font-size: 18px;
					line-height: 1.5;
				}

				@media (max-width: $mobile) {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}

	&-whyitrack {
		.container-unlimited {
			@media (max-width: $laptop) {
				padding-inline: var(--container-padding);
			}
		}

		.section__content {
			background-color: $grey_color;
			border-radius: 40px;
			padding-block: 80px;

			@media (max-width: $mobile) { padding-block: 60px; }
		}

		.container {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding-inline: 40px;

			@media (max-width: $mobile) { padding-inline: 20px; }
		}

		.support__about {
			&__body__left { gap: 132px; }

			&__nums {
				gap: 12px 30px;

				@media (min-width: #{$laptop + 1px}) { padding-right: 30px; }

				@media (max-width: $tablet) { column-gap: 20px; }
				@media (max-width: $mobile) { gap: 18px; }

				strong {
					font-size: 100px;

					@media (max-width: $laptop) { font-size: 80px; }
					@media (max-width: $mobile) { font-size: 60px; }
				}

				&__item {
					gap: 20px;
					font-size: 24px;
					line-height: 1.5;

					@media (max-width: $tablet) {
						font-size: 18px;
						line-height: 1.3;
					}

					@media (max-width: $mobile) {
						font-size: 16px;
						line-height: 1.1;
						display: grid;
						grid-template-columns: 150px 1fr;
					}
				}
			}

			&__link {
				border: 1px solid $blue;
				align-items: flex-start;
			}

			&__slider .splide__arrow {
				--color: #000;

				&:hover:not(:disabled) {
					--color: #{$blue};
				}
			}

			&__slide span {
				font: 500 20px/1.3 Inter;

				@media (max-width: $mobile) { font: 18px/1.2 Inter; }
			}
		}
	}

	&-price {
		.header-60 {
			@media (max-width: $tablet) { padding-top: 50px; }
		}

		.container-unlimited {
			@media (max-width: $laptop) {
				padding-inline: var(--container-padding);
			}
		}

		.section__content {
			background-color: $grey_color;
			border-radius: 40px;
		}

		.container {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding: 80px 40px 40px;
			background-position: right top 118px;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: auto 788px;
			background-image: image-set(
				"/src/assets/static/images/universal/bag.webp" 1x,
				"/src/assets/static/images/universal/bag-2x.webp" 2x
			);

			@media (max-width: $laptop) {
				background-size: 539px auto;
				background-position: right top 192px;
			}

			@media (max-width: $tablet) {
				padding-top: 30px;
				background-size: 468px auto;
				background-position: top right 30px;
			}

			@media (max-width: $mobile) {
				padding: 30px 20px;
				gap: 30px;
				background-size: 343px auto;
				background-position: top 10px center;
			}
		}

		.elements-grid {
			--list-width: 615px;
			// min-height: 740px;
			grid-template-columns: var(--list-width) 1fr auto auto;
			gap: 40px;
			padding-right: 0;

			@media (max-width: $laptop) {
				--list-width: 447px;
				column-gap: 10px;
				// min-height: 610px;
			}

			@media (max-width: $tablet) {
				grid-template-columns: auto 1fr;
				// min-height: 300px;
			}

			@media (max-width: $mobile) {
				// min-height: 220px;
				max-width: 100%;
				display: grid;
				grid-template-columns: 1fr auto;
				justify-items: space-between;

				.custom-list { margin-top: 0; }
			}

			.button {
				width: 100%;
				max-width: 100%;

				@media (max-width: $tablet) { max-width: 344px; }
			}

			.element-block, .button {
				grid-column-start: 3;

				@media (max-width: $tablet) {
					grid-column-start: 1;
				}
			}

			.element-block + .element-block {
				grid-column-start: 4;

				@media (max-width: $laptop) { margin-left: 30px; }
				@media (max-width: $tablet) { grid-column: 2; }
			}
		}

		.list-container {
			grid-row: 1 / span 2;

			@media (max-width: $tablet) {
				grid-column: 1 / span 2;
				grid-row: 3;
			}

			ul {
				@extend %custom-list;
				color: #000;
				gap: 8px;
				font: 24px/1.3 Inter;
			}
		}

		.element-block {
			align-items: flex-start;

			@media (max-width: $mobile) {
				flex-direction: column;
				gap: 6px;

				&.period { margin: 0; }
			}

			.block-name {
				color: $text_color;
				font-size: 24px;
				line-height: 1.3;

				@media (max-width: $mobile) {
					font-size: 16px;
					line-height: 1.25;
				}
			}

			.block-value {
				font: 500 32px/1.2 Inter;
				color: #000;

				@media (max-width: $mobile) {
					font-size: 20px;
					line-height: 1.4;
				}
			}
		}
	}

	&-fast-calculation {
		.header-60 {
			@media (min-width: #{$mobile + 1px}) {
				max-width: 70%;
			}
		}
	}

	&-cases {
		.section__content {
			display: flex;
			flex-direction: column;
			gap: 60px;
		}

		header {
			--header-margin: 0;
			display: flex;
			justify-content: space-between;
			gap: 20px;

			@media (max-width: $mobile) {
				flex-direction: column;
			}

			.button--tertiary {
				@media (max-width: $mobile) {
					max-width: 390px;
					--py: 25px;
				}
			}
		}

		.main__portfolio__columns.splide__list {
			display: flex;
			gap: 0;
			overflow: visible;

			@media (max-width: $mobile) {
				flex-direction: column;
				gap: 60px;
			}
		}
	}

	&-responses {
		header {
			--header-margin: 60px;
			@include HeaderWithArrows;

			.splide__arrows {
				--btn-size: 56px;
				gap: 10px;
				display: flex;

				@media (min-width: 1201px) {
					display: none;
				}

				.splide__arrow {
					@extend %SplideArrow;

					&--prev::before { transform: scaleX(-1); }
				}
			}
		}

		@media (min-width: 1201px) {
			&__grid .splide__list {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;
			}
		}

		&__response {
			background-color: $grey_color;
			padding: 40px;
			display: flex;
			flex-direction: column;
			gap: 30px;
			border-radius: 40px;
			color: #000;

			@media (max-width: $mobile) {
				padding: 20px;
			}

			&__author {
				--image-size: 80px;
				display: grid;
				grid-template-columns: var(--image-size) 1fr;
				grid-template-rows: auto 1fr;
				align-items: center;
				gap: 10px 20px;

				@media (max-width: $laptop) {
					order: 3;
				}

				img {
					grid-row: 1 / span 2;
					border-radius: 50%;
					object-fit: cover;
					width: 100%;
					aspect-ratio: 1;
				}

				&-name {
					font: 500 24px/1.25 Inter;
				}

				&-position {
					font: 18px/1.2 Inter;
				}
			}

			h3 {
				margin-bottom: 10px;
				font: 500 32px/1.2 Inter;

				@media (max-width: $laptop) {
					order: 1;
				}

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 1.25;
					margin-bottom: 0;
				}
			}

			&__text {
				font: 500 20px/1.3 Inter;

				@media (max-width: $laptop) {
					font-size: 24px;
					font-weight: normal;
					order: 2;
				}

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 1.2;
				}
			}
		}
	}

	&-our-team {
		header {
			--header-margin: 60px;

			@include HeaderWithArrows;

			.splide__arrows {
				display: flex;

				@media (max-width: $mobile) {
					display: none;
				}
			}
		}

		.integration__team__card {
			&__name {
				display: flex;
				flex-direction: column;
			}

			@media (max-width: $mobile) {
				&__info {
					padding: 20px 15px 0 15px;
				}
			}
		}
	}

	&-faq {
		.section__content {
			gap: 60px;
		}
	}

	&-profit {
		.container-unlimited {
			@media (max-width: $laptop) {
				padding-inline: var(--container-padding);
			}
		}

		.section__content {
			background-color: $grey_color;
			border-radius: 40px;
			display: flex;
			flex-direction: column;
			gap: 60px;
		}

		.container {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding: 80px 40px 40px;

			@media (max-width: $tablet) {
				padding-top: 30px;
			}

			@media (max-width: $mobile) {
				padding: 30px 20px;
				gap: 30px;
			}
		}

		.blog-grid.splide {
			display: flex;
			gap: unset;
		}

		.blog-card__body {
			padding: 40px 16px 0 16px;
			font: 400 24px/1.25 Inter;
		}

		@media (max-width: $mobile) {
			.splide__list {
				display: flex;
				flex-direction: column;
				gap: 60px;
			}

			.splide__track {
				width: 100%;
			}
		}
	}

	&-services {
		.section__content {
			display: flex;
			flex-direction: column;
			gap: 60px;
		}

		.main__services {
			@media (min-width: #{$laptop + 1px}) {
				grid-template-columns: 1fr 355px auto;
			}

			&__item {
				@media (max-width: $laptop) {
					column-gap: 60px;
				}

				@media (max-width: $tablet) {
					align-items: start;
					column-gap: 40px;

					&__buttons {
						display: flex;
						flex-direction: column;
						gap: 20px;
					}
				}

				&__text {
					@media (min-width: #{$laptop + 1px}) {
						max-width: 640px;
					}
				}

				&__title {
					font: 500 32px/1.2 Inter;

					@media (max-width: $mobile) {
						font-size: 20px;
						line-height: 1.4;
					}
				}

				&__caption {
					font: 24px/1.3 Inter;

					@media (max-width: $mobile) {
						font-size: 16px;
						line-height: 1.25;
					}
				}
			}

		}

		.button--yellow {
			width: 100%;
			max-width: 355px;

			@media (max-width: $mobile) {
				max-width: unset;
			}
		}

		.button--tertiary {
			@media (min-width: #{$tablet + 1px}) {
				--py: 21px;
			}

			@media (max-width: $tablet) {
				--py: 11px;
			}
		}
	}
}