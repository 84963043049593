.outer-link {
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	@include transition-base;

	&:hover {
		background: $blue;

		&::before {
			--color: #fff;
		}
	}

	@media (max-width: $tablet) {
		width: 50px;
		height: 50px;
		background: $blue;
		border-radius: 50%;
	}

	&::before {
		content: "";
		@extend %icon, %icon--arrow-out;
		--size: 10px;
		--color: #{$blue};

		@media (max-width: $tablet) {
			--color: #fff;
		}
	}

	&--inverted {
		&::before {
			--color: #fff;
		}

		&:hover {
			background: #fff;

			&::before {
				--color: #{$blue};

				&:hover {
					background: $blue;

					&::before {
						--color: #fff;
					}
				}
			}
		}
	}
}