.advantages {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 95px;

	@media (max-width: $laptop) {
		gap: 32px;
	}

	@media (max-width: $mobile) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
	}

	&__item {
		flex: 0 1 260px;
		text-align: center;

		&__inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 32px;

			@media (max-width: $laptop) {
				gap: 20px;
			}
		}

		&__icon {
			display: flex;

			.icon {
				--size: 100px;
				--color: #{$blue};

				@media (max-width: $laptop) {
					--size: 80px;
				}
			}
		}

		&__titile {
			@extend %title;
		}

		&__caption {
			@extend %body;
			color: $text_color;

			p {
				margin-top: 1em;
				margin-bottom: 1em;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}