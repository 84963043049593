.socials {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	&__item {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #E8EBF2;
		display: flex;
		justify-content: center;
		align-items: center;
		--icon-size: 24px;
		--color: #AFB5CE;

		.icon {
			--size: var(--icon-size);
		}

		img {
			width: var(--icon-size);
			height: var(--icon-size);
			object-fit: contain;
			object-position: center;
			display: block;
		}

		&--tg {
			&:hover {
				--color: #2AABEE
			}
		}

		&--yt {
			&:hover {
				--color: #f00
			}
		}

		&--vk {
			&:hover {
				--color: #0077FF
			}
		}
	}
}