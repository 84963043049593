.landing2-transportation {
	.section__content {
		background-color: $blue;
		color: #fff;
		padding: 40px;
		border-radius: 40px;
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $mobile) {
			border-radius: 20px;
			padding: 40px 15px;
			gap: 30px;
		}
	}

	.info {
		font: 500 20px/1.5 Inter;

		@media (max-width: $mobile) {
			font: 14px/20px Inter;
		}
	}

	.h2 {
		@media (max-width: $laptop) and (min-width: #{$tablet + 1px}) {
			line-height: 1.2;
		}

		@media (max-width: $mobile) {
			font-size: 16px;
			line-height: 1.25;
		}
	}
}