.license {
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 60px;
	}

	&__header {
		@extend %lead;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: var(--column-gap);
	}

	&__item {
		display: flex;
		flex-direction: column;
		gap: 20px;
		background: $background_color;
		border-radius: 40px;
		padding: 40px;

		&__img {
			@include proportional-block(1, 1);
		}

		&__info {
			display: flex;
			flex-direction: column;
			gap: 5px;

			&__caption {
				@extend %small-text;
				color: $text_color;
			}

			&__title {
				@extend %title;
			}
		}

		&__radios {}

		&__price {
			@extend %title;
		}

		&__footer {
			margin-top: auto;
		}
	}
}