.landing2-caption {
	.section__inner {
		padding-bottom: 182px;
	}

	.section__content {
		display: grid;
		grid-template-columns: 766px 1fr;
		row-gap: 50px;

		.img-container {
			grid-row: span 2;
			width: min(553px, 100%);

			img {
				width: 100%;
				object-fit: contain;
				object-position: left center;
			}
		}

		.subtitle {
			font-size: 25px;
			line-height: 1.5;
		}
	}

	@media (max-width: $laptop) {
		margin-top: calc(-1 * var(--header-margin));

		.section__inner {
			padding-top: var(--header-margin);
			padding-bottom: var(--header-margin);
			overflow-x: hidden;
		}

		.section__content {
			grid-template-columns: 1fr 318px;
			gap: 40px 50px;

			.img-container { width: 553px; }
		}
	}

	@media (max-width: $tablet) {
		.section__content {
			.img-container {
				height: 270px;
				overflow: hidden;
				display: flex;
				align-items: center;

				img { transform: translateY(-30px); }
			}

			.subtitle { font-size: 18px; }
		}
	}

	@media (max-width: $mobile) {
		.section__inner {
			padding-bottom: 35px;
		}

		.section__content {
			grid-template-columns: 1fr;
			gap: 20px;

			.img-container {
				grid-row: 1;
				margin-bottom: 20px;
				height: 180px;
				width: min(100%, 300px);

				img {
					width: 100%;
					transform: none;
				}
			}

			.subtitle { font-size: 14px; }
		}
	}
}