.breadcrumbs {
	margin-bottom: 20px;
	--main-color: #{$text_color};
	--accent-color: #{$blue};
	font-size: 0;

	&--light {
		--main-color: #DFE2EC;
		--accent-color: #fff;
	}

	&__item {
		@extend %body;
		color: var(--main-color);

		&:last-child {
			color: var(--accent-color);
			pointer-events: none;

			&::after {
				display: none;
			}
		}

		&::after {
			display: inline-block;
			content: "";
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: var(--main-color);
			vertical-align: middle;
			margin: 0 8px;
		}
	}
}