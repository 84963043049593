.two-columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 60px var(--column-gap);

	@media (max-width: $laptop) {
		gap: 20px var(--column-gap);
	}

	@media (max-width: $mobile) {
		grid-template-columns: repeat(1, 1fr);
		gap: 20px;
	}

	&--freak {
		@media (max-width: $laptop) {
			grid-template-columns: 5fr 7fr;

			.two-columns__column--indented:not(:first-of-type) .two-columns__column__content {
				grid-column: 1 / -1;
			}
		}

		@media (max-width: $tablet) {
			grid-template-columns: 7fr 5fr;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	&--vertical-centered {
		align-items: center;
	}

	&--mobile-reversed {
		@media (max-width: $mobile) {
			> :first-child {
				order: 2;
			}
		}
	}

	&__column {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		$content: & + "__content";

		#{$content} {
			grid-column: 1 / -1;
		}

		&--wide {
			@media (min-width: #{$mobile + 1px}) {
				grid-column: span 2;
			}
		}

		@mixin indented {
			&:first-of-type {
				#{$content} {
					grid-column: 1 / -2;
				}
			}

			&:not(:first-of-type) {
				#{$content} {
					grid-column: 2 / -1;
				}
			}
		}

		&--indented {
			@media (min-width: #{$mobile + 1px}) {
				@include indented();
			}
		}

		&--desktop-indented {
			@media (min-width: $laptop) {
				@include indented();
			}
		}

		&--intented-both {
			#{$content} {
				@media (min-width: #{$mobile + 1px}) {
					grid-column: 2 / -2;

					@media (max-width: $laptop) {
						grid-column: 2 / -1;
					}
				}
			}
		}
	}
}