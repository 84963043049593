.landing2-guaranties {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 50px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	.giaranties-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	.guarantee {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 40px;
		border-radius: 40px;
		background-color: $background_color;

		&__detail {
			color: $text_color;
			font-size: 18px;
			line-height: 1.2;
		}

		@media (max-width: $mobile) {
			padding: 20px;
			border-radius: 20px;

			.lead {
				font-size: 16px;
				line-height: 20px;
			}

			&__detail {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}