.techsupport-warranty {
	.section__content {
		display: grid;
		grid-template-columns: 1fr 351px;
		align-items: center;
		gap: 40px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	.section-caption {
		align-self: end;

		@media (max-width: $tablet) {
			line-height: 44px;
		}

		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1;
		}
	}

	.warranty {
		&__subheader {
			align-self: start;
			font: 24px/1.3 Inter;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 1.25;
				margin-bottom: 20px;
			}
		}

		&__image {
			width: 100%;
			grid-row: 1 / span 2;
			grid-column: 2;

			@media (max-width: $mobile) {
				grid-row: auto;
				grid-column: 1;
				width: 227px;
				justify-self: center;
			}
		}
	}
}