.request {
	display: flex;
	flex-direction: column;
	gap: 80px;

	@media (max-width: $laptop) {
		gap: 60px;
	}

	@media (max-width: $tablet) {
		gap: 40px;
	}

	&__icon {
		display: flex;
		justify-content: flex-start;
		--size: 100px;
		--color: #{$blue};
	}

	&--success {
		gap: 60px;

		@media (max-width: $laptop) {
			gap: 45px;
		}

		@media (max-width: $tablet) {
			gap: 30px;
		}

	}

	&__block {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $laptop) {
			gap: 30px;
		}

		@media (max-width: $tablet) {
			gap: 20px;
		}


		&__title {
			@extend %big-title;
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: 40px;

			&__row {
				display: flex;
				gap: var(--column-gap);

				&__cell {
					flex: 1 1 0;
				}
			}
		}
	}

	&__title {}
}