footer {
	background: #D2D6E1;
	border-radius: 40px 40px 0 0;
	padding: 80px 0;
	margin-top: var(--row-gap);

	@media (max-width: $laptop) {
		padding: 40px 0;
		border-radius: 20px 20px 0 0;
	}
}

.footer {
	&__inner {
		display: grid;
		grid-template-columns: auto auto;
		gap: 80px;

		@media (max-width: $laptop) {
			gap: 60px;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	&__nav {
		display: flex;
		gap: 60px;

		@media (max-width: $laptop) {
			gap: 40px;
		}

		@media (max-width: $tablet) {
			gap: 20px;
		}

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		&__item {
			@extend %title;
			@include transition-base;

			&:hover,
			&--is-active {
				color: $blue;
				text-decoration: underline;
			}
		}
	}

	&__socials {
		justify-self: flex-end;

		@media (max-width: $mobile) {
			justify-self: flex-start;
		}
	}

	&__copyright {
		color: $text_color;
		@extend %small-text;
		align-self: flex-end;

		@media (max-width: $mobile) {
			order: 999;
		}
	}

	&__contacts {
		color: $text_color;
		@extend %small-text;
		align-self: flex-end;
		text-align: right;

		&--left {
			text-align: left;
		}

		@media (max-width: $mobile) {
			text-align: left;
		}

		a {
			@extend %link;
			color: inherit;

			+ a {
				margin-left: 40px;
			}
		}

		p {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
}