.tabs {
	display: flex;
	flex-direction: column;
	gap: 20px;

	&__controls {
		&--buttons {
			display: flex;
			gap: 10px;
			flex-wrap: wrap;

			&--right {
				justify-content: flex-end;

				@media (max-width: $laptop) {
					justify-content: flex-start;
				}
			}
		}

		&--buttons &__control {
			&__visual {
				white-space: nowrap;
				padding: 10px 20px;
				@extend %title;
				@include transition-base;
				border-radius: 60px;

				&:is(input:checked ~ *) {
					background: #306AFF;
					border: 2px solid #306AFF;
					color: #FFFFFF;
				}

				&:is(input:not(:checked) ~ *) {
					border: 2px solid #E3E6E6;
					background: transparent;
				}
			}
		}

		&__control {
			user-select: none;
			max-width: 100%;

			input {
				visibility: hidden;
				position: absolute;
				pointer-events: none;
			}

			&__visual {
				cursor: pointer;
				user-select: none;
			}
		}
	}

	&__content {
		$parent: &;

		&__controller:is(input) {
			visibility: hidden;
			position: absolute;
			pointer-events: none;

			&:not(:checked) ~ #{$parent}__visual {
				display: none;
			}
		}
	}
}