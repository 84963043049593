.tag,
%tag {
	padding: 5px 15px;
	@extend %small-text;
	background: #E3E6E6;
	border-radius: 10px;
	color: $text_color;
	@include transition-base;

	&-group {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	&.is-active,
	%is-active:is(&) {
		background: $blue;
		color: #fff;
	}
}