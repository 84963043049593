$parent: ".spoiler";
$toggler: #{$parent}__toggler;
$content: #{$parent}__content;
$initial: #{$parent}__initial;
$final: #{$parent}__final;
$flipper: #{$parent}__flipper;

#{$content} {
	overflow: hidden;
	transition: all var(--transition-duration, 0) ease;
	max-height: var(--scroll-height, auto);

	&:not(.is-active) {
		max-height: var(--initial-height, 0);
	}
}

#{$toggler} {
	cursor: pointer;
	user-select: none;
}

#{$flipper} {
	display: flex;
	flex-direction: column;
	transition: all var(--transition-duration, 0) ease;
}

#{$parent},
#{$toggler},
#{$content} {
	&.is-active {
		#{$flipper} {
			transform: scaleY(-1);
		}

		#{$initial} {
			display: none;
		}

		#{$final} {
			display: block;
		}
	}

	&:not(.is-active) {
		#{$initial} {
			display: block;
		}

		#{$final} {
			display: none;
		}
	}
}