.integration-tabs {
	display: flex;
	flex-direction: column;
	gap: 60px;

	@media (max-width: $mobile) {
		gap: 40px;
	}

	&__slide {}
}