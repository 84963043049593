.radio-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	&--centered {
		justify-content: center;
	}

	&__item {
		cursor: pointer;

		input[type=radio],
		input[type=checkbox] {
			opacity: 0;
			position: absolute;
			pointer-events: none;
		}

		&__visual {
			@extend %tag;
		}

		input[type=radio]:checked ~ &__visual,
		input[type=checkbox]:checked ~ &__visual {
			@extend %is-active;
		}
	}
}