.custom-checkbox {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	--all-size: 20px;
	--border-width: 2px;
	@extend %body;
	user-select: none;

	input[type="checkbox"] {
		width: var(--all-size);
		height: var(--all-size);
		position: relative;
		cursor: pointer;
		appearance: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;

		&::before {
			visibility: visible;
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border-width: var(--border-width);
			border-style: solid;
			border-color: $blue;
			border-radius: 5px;
			@include transition-base;
		}

		&::after {
			visibility: visible;
			position: absolute;
			content: "";
			opacity: 0;
			@extend %icon, %icon--check;
			--color: #fff;
			--size: var(--all-size);
			@include transition-base;
		}

		&:checked {
			&::before {
				background-color: $blue;
			}

			&::after {
				opacity: 1;
			}
		}
	}
}