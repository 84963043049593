@mixin case-name {
	font: 500 24px/30px Inter;

	@media (max-width: $mobile) {
		font-size: 20px;
		line-height: 1.4;
	}
}

@mixin case-description {
	font: 18px/1.2 Inter;

	@media (max-width: $mobile) {
		font-size: 16px;
		line-height: 1.25;
	}
}

.techsupport-similar-cases {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 60px;
	}

	.section-caption {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: $mobile) {
			line-height: 1;
			align-items: flex-start;
			flex-direction: column;
		}

		.button {
			--px: 50px;
			--py: 21px;
			gap: 20px;
			letter-spacing: normal;

			@media (max-width: $laptop) {
				--py: 21px;
				font-size: 20px;
			}

			@media (max-width: $tablet) {
				--px: 30px;
				--py: 11px;
				font-size: 16px;
				line-height: 30px;
			}

			@media (max-width: $mobile) {
				--px: 10px;
				max-width: 330px;
			}

			img {
				width: 10px;
				height: 10px;
				filter: grayscale(1) contrast(5);
			}
		}
	}

	.case-cards {
		&__card {
			flex-grow: 1;
			max-width: 433.33px;
			flex-shrink: 0;
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;
			border: 1px solid $text_color;
			border-radius: 20px;

			@media (max-width: $laptop) {
				max-width: 404px;
			}

			@media (max-width: $tablet) {
				max-width: 321px;
			}

			@media (max-width: $mobile) {
				max-width: 330px;
			}

			&.card-with-image {
				grid-template-rows: 313px 1fr;

				@media (min-width: #{$mobile + 1px}) and (max-width: $tablet) {
					grid-template-rows: 173px 1fr;
				}
			}

			&.card-text-only {
				border: none;
				background-color: #e8ebf2;
				grid-template-rows: 1fr;
				padding: 20px;

				@media (max-width: $mobile) {
					grid-template-rows: 245px auto;
				}
			}
		}

		@media (max-width: $mobile) {
			.splide__list {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}
	}

	.case-card {
		&__image {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&__name {
			@include case-name;
			& { color: #000; }
		}

		&__details {
			display: grid;
			grid-template-columns: 1fr 64px;
			gap: 12px 20px;
			padding: 20px;
		}

		&__active-zone:hover {
			border-color: $blue;

			.case-card__name { color: $blue; }

			.case-card__arrow {
				background-color: #fff;

				img { filter: none; }
			}
		}

		&__arrow {
			grid-row: span 2;
			width: 64px;
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;

			img {
				transition: filter var(--transition-duration, .3s);
				filter: grayscale(1) contrast(5);
				width: 10px;
				height: 10px;
				object-fit: contain;
			}
		}

		&__description {
			@include case-description;

			@media (max-width: $tablet) {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
			}
		}

		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
		}

		// Округлые блоки с белым фоном
		&__area {
			background-color: #fff;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			flex-direction: column;
			gap: 4px;

			&__name {
				color: $text_color;
				font: 14px/20px Inter;
			}

			&__value {
				font: 18px/1.2 Inter;
			}
		}
	}

	// Колонка серых блоков
	.case-list {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (max-width: $mobile) {
			margin-top: 20px;
		}

		&__entry {
			background-color: #e8ebf2;
			border-radius: 20px;
			padding: 40px;
			display: grid;
			grid-template-columns: 200px 1fr 502px;
			align-items: center;
			gap: 20px;

			@media (max-width: $laptop) {
				grid-template-columns: 180px 1fr 233px;
			}

			@media (max-width: $tablet) {
				grid-template-columns: 180px 1fr;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
				padding: 20px;
			}
		}
	}

	.case-entry {
		&__name {
			@include case-name;
		}

		&__description {
			@include case-description;
			& { margin-right: 38px; }

			@media (max-width: $laptop) {
				margin-right: 21px;
			}

			@media (max-width: $tablet) {
				margin-right: 0;
			}
		}

		&__buttons {
			display: flex;
			gap: 20px;

			@media (min-width: #{$tablet + 1px}) and (max-width: $laptop) {
				flex-direction: column;
			}

			@media (max-width: $tablet) {
				grid-column: 1 / -1;
			}

			@media (max-width: $mobile) {
				flex-direction: column;
				margin-top: 20px;
			}

			.button {
				--py: 21px;
				flex-shrink: 0;
				gap: 20px;

				@media (min-width: #{$laptop + 1px}) {
					flex-basis: 241px;
				}

				@media (max-width: $tablet) {
					--py: 11px;
					flex-grow: 1;
				}

				svg path {
					stroke: var(--color);
				}
			}
		}
	}
}