@import "choices.js/src/styles/choices";

.choices {
	width: 100%;
	display: flex;
	align-items: center;

	&__list--dropdown {
		border: none !important;
		@extend %default-shadow;
	}

	&__list--dropdown &__item {
		padding: 15px !important;

		&::after {
			display: none;
		}

		&--disabled {
			display: none !important;
		}

		&.is-highlighted {
			background-color: $background_color !important;
			border-radius: 5px;
		}
	}

	&[data-type*=select-one] {
		&::after {
			width: 12px;
			height: 12px;
			border: none;
			@extend %icon, %icon--arrow-down;
			--color: #{$blue};
			transform: none;
			margin-top: 0 !important;
			top: initial;
			margin-bottom: 10px;
		}

		&.is-open::after {
			transform: scaleY(-1);
		}
	}

	&[data-type*=select-one] &__inner {
		border: none;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: #DFE3EF;
		background: transparent;
		border-radius: 0;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 10px;
		padding-top: 0;
		min-height: 0;
	}

	&__inner &__list {
		padding: 0;
	}

	&__placeholder {
		color: $text_color;
	}
}

// .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable