.integration {
	&__mainscreen {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__img {
			align-self: center;

			@media (max-width: $mobile) {
				max-width: 400px;
				justify-self: center;
			}
		}

		&__text {
			display: flex;
			flex-direction: column;
			gap: 40px;
			justify-content: space-between;
		}

		&__title {
			font-size: 60px;
			font-weight: 500;
			line-height: 100%;
			letter-spacing: -0.05em;

			@media (max-width: $laptop) {
				font-size: 50px;
			}

			@media (max-width: $mobile) {
				font-size: 40px;
			}
		}

		&__subtitle {
			font-size: 32px;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: -0.05em;

			@media (max-width: $laptop) {
				font-size: 24px;
			}

			@media (max-width: $mobile) {
				font-size: 18px;
			}
		}
	}

	&__calc {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		gap: var(--column-gap);

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__info {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $mobile) {
				order: -1;
				gap: 30px;
			}
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 30px;
			}
		}

		&__text {
			ul {
				@extend .custom-list;

				li {
					font-size: 32px;
					font-weight: 400;
					line-height: 100%;
					letter-spacing: -0.05em;
					color: #000;

					@media (max-width: $laptop) {
						font-size: 24px;
					}

					@media (max-width: $mobile) {
						font-size: 18px;
					}

					&::before {
						margin-top: 13px;

						@media (max-width: $laptop) {
							margin-top: 9px;
						}

						@media (max-width: $mobile) {
							margin-top: 7px;
						}
					}
				}
			}
		}

		&__button {
			.button {
				background-color: #FBC117;
				color: #000;
			}
		}
	}

	&__problems {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				font-weight: 500;
				line-height: 39px;
			}
		}

		&__grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 1fr;
			gap: var(--column-gap);

			@media (max-width: $laptop) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}

		&__item {
			padding: 40px;
			border-radius: 20px;
			font-size: 32px;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: -0.05em;
			border: 1px solid $blue;

			@media (max-width: $mobile) {
				font-size: 24px;
				font-weight: 400;
				line-height: 24px;
				padding: 20px;
			}
		}

		&__button {
			align-self: center;
			justify-self: center;
		}
	}

	&__solve {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__title {
			max-width: 1058px;
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 30px;
			}
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $mobile) {
				gap: 30px;
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-template-rows: auto 1fr;
			gap: 40px;

			@media (max-width: $tablet) {
				grid-template-columns: 1fr;
				grid-template-rows: none;
			}

			@media (max-width: $mobile) {
				gap: 30px;
			}

			&__title {
				font-size: 32px;
				font-weight: 400;
				line-height: 41.6px;
				letter-spacing: -0.05em;
				color: $blue;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 31.2px;
				}
			}

			&__text {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.6px;
				}
			}

			&__image {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #000000;
				grid-row: 1 / -1;
				grid-column: 2;
				border-radius: 20px;
				position: relative;
				align-self: flex-end;
				width: 433px;

				@media (max-width: $tablet) {
					grid-row: auto;
					grid-column: auto;
				}

				@media (max-width: $mobile) {
					width: 100%;
					max-width: 400px;
				}

				&__button {
					position: absolute;
					background-color: #fff;
					border-radius: 50%;
					width: 64px;
					height: 64px;
					display: flex;
					align-items: center;
					justify-content: center;

					&::after {
						content: '';
						@extend .icon, .icon--search1;
						--size: 24px;
					}
				}

				img {
					border-radius: inherit;
					aspect-ratio: 433 / 245;
					object-fit: cover;

					@media (max-width: $mobile) {
						aspect-ratio: 330 / 187;
					}
				}

				&::before {
					content: '';
					position: absolute;
					inset: 0;
					border-radius: inherit;
					background-color: #00000033;
				}
			}
		}
	}

	&__about {
		background-color: #e8ebf2;
		padding: 80px 0;
		margin: 0 40px;
		border-radius: 40px;

		@media (max-width: $laptop) {
			margin: 0 var(--container-padding);
		}

		@media (max-width: $mobile) {
			padding: 60px 0;
		}

		.support__about__link {
			border: 1px solid $blue;
		}
	}

	&__steps {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				font-weight: 500;
				line-height: 39px;
			}
		}

		&__grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 50px 10px;

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
				gap: 30px;
			}
		}

		&__item {
			display: grid;
			grid-template-rows: subgrid;
			gap: 40px;
			grid-row: span 2;

			@media (max-width: $mobile) {
				gap: 30px;
			}

			&__title {
				font-size: 32px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: -0.05em;
				display: flex;
				gap: 1ch;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 30px;
				}

				strong {
					font: inherit;
					color: $blue;
					white-space: nowrap;
				}
			}

			ul {
				@extend .custom-list;

				li {
					color: #000;
					font-size: 24px;
					font-weight: 400;
					line-height: 31.2px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 21.6px;
					}

					&::before {
						margin-top: 14px;
					}
				}
			}
		}
	}

	&__products {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				font-weight: 500;
				line-height: 39px;
			}
		}

		&__item {
			$item: &;
			--bg-right-offset: 66px;
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding: 40px;
			border-radius: 20px;
			border: 1px solid $text_color;

			@media (max-width: $mobile) {
				padding: 20px;
				gap: 30px;
			}

			&.blue-bg {
				background-color: #0633A7;
				color: #fff;

				#{$item}__body ul {
					color: #fff;

					li::before {
						background-color: #fff;
					}
				}
			}

			$subNotebook: 1365px;
			@media (max-width: $subNotebook) { --bg-right-offset: 60px; }
			@media (max-width: $tablet) { --bg-right-offset: 34px; }

			@media (min-width: #{$mobile + 1px}) {
				&.item-bg {
					--top-offset: 40%;
					background-repeat: no-repeat;
					background-position: right var(--bg-right-offset) top var(--top-offset);

					&-1 {
						--top-offset: 34%;
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/person.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/person-2x.webp" 2x,
						);
						background-size: auto 143.56%;

						@media (max-width: $subNotebook) {
							--top-offset: 15%;
							background-size: auto 97%;
						}

						@media (max-width: $tablet) {
							--top-offset: 53%;
							background-size: auto 82%;
						}
					}

					&-2 {
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/megaphone.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/megaphone-2x.webp" 2x,
						);
						background-size: auto 144.38%;

						@media (max-width: $subNotebook) {
							--top-offset: 40%;
							background-size: auto 75%;
						}

						@media (max-width: $tablet) {
							--top-offset: 51%;
							background-size: auto 68%;
						}
					}

					&-3 {
						--top-offset: 5%;
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/funnel.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/funnel-2x.webp" 2x,
						);
						background-size: auto 148.51%;

						@media (max-width: $subNotebook) {
							--top-offset: 350%;
							background-size: auto 95%;
						}

						@media (max-width: $tablet) {
							--top-offset: 47%;
							background-size: auto 78%;
						}
					}

					&-4 {
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/cubes.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/cubes-2x.webp" 2x,
						);
						background-size: auto 110.95%;

						@media (max-width: $subNotebook) {
							--top-offset: 50%;
							background-size: auto 73%;
						}

						@media (max-width: $tablet) {
							--top-offset: 50%;
							background-size: auto 50%;
						}
					}

					&-5 {
						--top-offset: 17%;
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/queen.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/queen-2x.webp" 2x,
						);
						background-size: auto;

						@media (max-width: $subNotebook) {
							--top-offset: 20%;
						}

						@media (max-width: $tablet) {
							--top-offset: 20%;
							--bg-right-offset: 20px;
							background-size: auto 110%;
						}
					}

					&-crm {
						background-image: image-set(
							"/src/assets/static/images/integration-tilda/bg/tools.webp" 1x,
							"/src/assets/static/images/integration-tilda/bg/tools-2x.webp" 2x,
						);
						background-size: auto 122.52%;

						@media (max-width: $subNotebook) {
							background-size: auto 64.5%;
							background-position: right var(--bg-right-offset) center;
						}

						@media (max-width: $tablet) {
							--top-offset: 50%;
							background-size: auto 45%;
						}
					}
				}
			}

			&__head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 40px;

				@media (max-width: $mobile) {
					flex-direction: column;
					align-items: flex-start;
					gap: 16px;
				}
			}

			&__header {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}

			&__title {
				font-size: 32px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: -0.05em;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 30px;
				}
			}

			&__subtitle {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.6px;
				}
			}

			&__time {
				display: flex;
				flex-direction: column;
				gap: 6px;

				&__caption {
					color: $text_color;
					font-size: 24px;
					font-weight: 400;
					line-height: 31.2px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 21.6px;
					}
				}

				&__value {
					font-size: 32px;
					font-weight: 500;
					line-height: 32px;
					letter-spacing: -0.05em;

					@media (max-width: $mobile) {
						font-size: 24px;
						line-height: 30px;
					}
				}
			}

			&__body {
				display: flex;
				flex-wrap: wrap;
				gap: 40px var(--column-gap);

				@media (max-width: $mobile) {
					gap: 8px;
				}

				&.body-3-cols {
					@media (min-width: #{$subNotebook + 1px}) {
						#{$item}__block { max-width: 434px; }
					}

					@media (max-width: $subNotebook) {
						flex-direction: column;
					}
				}

				ul {
					@extend .custom-list;
					font-size: 24px;
					font-weight: 400;
					line-height: 31.2px;
					color: #000;
					gap: 8px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 23.4px;
					}

					li {
						&::before {
							margin-top: 14px;

							@media (max-width: $mobile) {
								margin-top: 7px;
								width: 10px;
								height: 10px;
							}
						}

						div {
							display: flex;
							flex-direction: column;
							gap: 8px;
						}
					}

					ul {
						gap: 8px;

						li {
							&::before {
								content: '-';
								background-color: unset;
								width: auto;
								height: auto;
								margin-top: 0;
							}
						}
					}
				}
			}

			&__block {
				flex: 1 1 0;
				display: flex;
				flex-direction: column;
				gap: 12px;

				& + & {
					max-width: calc(50% - 6px);

					@media (max-width: $mobile) {
						max-width: unset;
					}
				}

				@media (max-width: $subNotebook) {
					max-width: 447px;
				}

				@media (max-width: $laptop) {
					flex-basis: 40%;
				}

				@media (max-width: $tablet) {
					max-width: 382px;
				}

				@media (max-width: $mobile) {
					flex-basis: 100%;
				}

				&:not(:first-child) &__title {
					@media (max-width: $mobile) {
						margin-top: 22px;
					}
				}

				&__title {
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 23.4px;
					}
				}
			}
		}
	}

	&__features {
		margin: 0 40px;
		background-color: $blue;
		border-radius: 40px;
		padding: 80px 0;
		color: #fff;

		@media (max-width: $laptop) {
			margin: 0 var(--container-padding);
			padding: 80px var(--container-padding);
		}

		@media (max-width: $mobile) {
			padding: 60px var(--container-padding);
		}

		&__inner {
			display: flex;
			flex-direction: column;
			gap: 80px;
		}

		&__grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 60px var(--column-gap);

			@media (max-width: $tablet) {
				grid-template-columns: 1fr;
				gap: 40px;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 12px;
		}

		&__button {
			@media (min-width: #{$tablet + 1}) {
				display: none;
			}

			@media (max-width: $mobile) {
				display: none;
			}
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 39px;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $mobile) {
				gap: 30px;
			}

			&__title {
				font-size: 32px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: -0.05em;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 30px;
				}
			}

			&__text {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.6px;
				}
			}
		}
	}

	&__cases {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width: $laptop) {
				display: grid;
				grid-template-columns: 1fr auto;
				gap: 40px;
				align-items: center;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
				gap: 30px;
			}

			.button {
				justify-self: flex-start;
			}
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 39px;
			}
		}

		&__slider {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 1fr;
			gap: 20px var(--column-gap);

			@media (max-width: $laptop) {
				display: flex;
				margin-left: calc(var(--container-padding) * -1);
				margin-right: calc(var(--container-padding) * -1);
			}

			@media (max-width: $mobile) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.splide {
			width: 100%;

			@media (min-width: #{$laptop + 1px}) {
				display: contents;
			}

			&__slide {
				@media (max-width: $laptop) {
					width: calc(100% / 2.4);
				}

				@media (max-width: $mobile) {
					width: 100%;
				}
			}

			&__arrows {
				display: flex;
				justify-content: flex-end;
				gap: 20px;

				@media (min-width: #{$laptop + 1px}) {
					display: none;
				}

				@media (max-width: $mobile) {
					display: none;
				}
			}

			&__arrow {
				@extend %SplideArrow;

				&--prev::before { transform: scaleX(-1); }

			}

			&__track {
				@media (min-width: #{$laptop + 1px}) {
					display: contents;
				}
			}

			&__list {
				@media (min-width: #{$laptop + 1px}) {
					display: contents;
				}

				@media (max-width: $mobile) {
					display: flex;
					flex-direction: column;
					gap: 30px;
				}
			}

			&__slide {
				@media (min-width: #{$laptop + 1px}) {
					display: contents;
				}
			}
		}

		&__card {
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 40px;
			border-radius: 20px;
			border: 1px solid $text_color;
			height: 100%;

			&__body {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}

			&__image {
				img {
					aspect-ratio: 393 / 183;
				}
			}

			&__footer {
				display: flex;
				flex-direction: column;
				align-items: stretch;
			}

			&__text {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $tablet) {
					font-size: 18px;
					font-weight: 400;
					line-height: 21.6px;
				}
			}

			&__tags {
				display: flex;
				gap: 10px;
			}

			&__tag {
				background-color: #e8ebf2;
				border-radius: 666px;
				padding: 5px 15px;
				font-size: 18px;
				font-weight: 400;
				line-height: 21.6px;
			}
		}
	}

	&__team {
		display: flex;
		flex-direction: column;
		gap: 40px;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 39px;
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			gap: 30px;

			@media (max-width: $mobile) {
				gap: 20px;
			}

			&__top {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: var(--column-gap);

				@media (max-width: $mobile) {
					grid-template-columns: 1fr;
				}
			}

			&__img {
				@media (max-width: $mobile) {
					max-width: 400px;
				}

				img {
					aspect-ratio: 323 / 350;
					object-fit: cover;
					border-radius: 40px;
				}
			}

			&__info {
				display: flex;
				flex-direction: column;
				gap: 40px;
				padding-left: 30px;

				@media (max-width: $mobile) {
					padding-left: 0;
					gap: 30px;
				}
			}

			&__person {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}

			&__name {
				font-size: 32px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: -0.05em;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 30px;
				}
			}

			&__post {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.6px;
				}
			}

			&__exp {
				display: flex;
				flex-direction: column;
				gap: 10px;

				&__title {
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 21.6px;
					}
				}

				&__info {
					font-size: 24px;
					font-weight: 400;
					line-height: 31.2px;

					@media (max-width: $mobile) {
						display: flex;
						gap: 20px;
					}

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 21.6px;
					}

					strong {
						font: inherit;
						color: $blue;
					}

					p {
						@media (max-width: $mobile) {
							display: flex;
							flex-direction: column;
							gap: 10px;
						}
					}
				}
			}

			&__bottom {
				@media (max-width: $mobile) {
					margin-top: 10px;
				}
			}

			&__role {
				display: flex;
				flex-direction: column;
				gap: 10px;

				&__title {
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 23.4px;
					}
				}

				&__text {
					font-size: 24px;
					font-weight: 400;
					line-height: 31.2px;

					@media (max-width: $mobile) {
						font-size: 18px;
						line-height: 21.6px;
					}
				}
			}
		}

		.splide {
			@media (max-width: $laptop) {
				margin-left: calc(var(--container-padding) * -1);
				margin-right: calc(var(--container-padding) * -1);
			}

			@media (max-width: $mobile) {
				margin-left: 0;
				margin-right: 0;
			}

			&__slide {
				width: calc(100% / 1.5);

				@media (max-width: $tablet) {
					width: calc(100% / 1.1);
				}

				@media (max-width: $mobile) {
					width: 100%;
				}
			}

			&__arrows {
				display: flex;
				gap: 10px;

				@media (max-width: $mobile) {
					display: none;
				}
			}

			&__arrow {
				@extend %SplideArrow;

				&--prev {
					order: -99;

					&::before { transform: scaleX(-1); }
				}

				&--next { order: 99; }
			}

			&__list {
				@media (max-width: $mobile) {
					display: flex;
					flex-direction: column;
					gap: 50px;
				}
			}
		}
	}

	&__reviews {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 39px;
			}
		}

		&:has(.splide:not(.is-overflow)) {
			.splide__arrows {
				display: none;
			}
		}

		.splide {
			&:not(.is-overflow) .splide__list {
				transform: none !important;
			}

			@media (max-width: $laptop) {
				margin-left: calc(var(--container-padding) * -1);
				margin-right: calc(var(--container-padding) * -1);
			}

			&__slide {
				@media (max-width: $laptop) {
					width: calc(100% / 2.5);
				}

				@media (max-width: $tablet) {
					width: calc(100% / 1.8);
				}

				@media (max-width: $mobile) {
					width: 100%;
				}
			}

			&__arrows {
				display: flex;
				gap: 10px;
			}

			&__arrow {
				--arrow-size: 64px;
				align-self: center;
				width: var(--arrow-size);
				height: var(--arrow-size);
				border-radius: 50%;
				border: none;
				display: flex;
				align-items: center;
				background-color: transparent;
				justify-content: center;
				--color: #{$blue};
				--size: 16px;
				cursor: pointer;
				transition: background-color ease .3s;

				&::before {
					content: '';
					@extend .icon, .icon--arrow-right;
				}

				&:hover {
					background-color: #fff;
				}

				&:disabled {
					cursor: default;
					background-color: transparent;
					--color: #000;
				}

				&--prev {
					&::before {
						transform: scaleX(-1);
					}
				}

				&--prev {
					order: -99;
				}

				&--next {
					order: 99;
				}
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			gap: 40px;
			border-radius: 40px;
			padding: 40px;
			background-color: #e8ebf2;
			height: 100%;

			@media (max-width: $mobile) {
				padding: 20px;
				gap: 30px;
			}

			&__title {
				font-size: 32px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: -0.05em;

				@media (max-width: $mobile) {
					font-size: 24px;
					line-height: 30px;
				}
			}

			&__text {
				font-size: 24px;
				font-weight: 400;
				line-height: 31.2px;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.6px;
				}
			}

			&__person {
				margin-top: auto;
				display: grid;
				grid-template-columns: auto 1fr;
				grid-template-rows: auto auto;
				gap: 10px 20px;
				align-items: center;

				&__img {
					grid-row: 1 / -1;

					img {
						width: 80px;
						aspect-ratio: 1;
						border-radius: 50%;
						object-fit: cover;
					}
				}

				&__name {
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;
				}

				&__post {
					font-size: 18px;
					font-weight: 400;
					line-height: 21.6px;
				}
			}
		}
	}

	&__support {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px var(--column-gap);

		@media (max-width: $laptop) {
			grid-template-columns: 1fr;
		}

		@media (max-width: $mobile) {
			gap: 30px;
		}

		&__title {
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;

			@media (max-width: $mobile) {
				font-size: 30px;
				line-height: 39px;
			}
		}

		&__caption {
			font-size: 24px;
			font-weight: 400;
			line-height: 31.2px;

			@media (max-width: $mobile) {
				font-size: 18px;
				line-height: 21.6px;
			}
		}

		&__main {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $laptop) {
				display: contents;
			}

			&__footer {
				margin-top: auto;

				@media (max-width: $laptop) {
					order: 999;
				}

				.button {
					background-color: #FBC117;
					color: #000;
				}
			}
		}

		&__features {
			font-size: 24px;
			font-weight: 400;
			line-height: 31.2px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 40px 10px;

			@media (max-width: $laptop) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media (max-width: $tablet) {
				grid-template-columns: repeat(2, 1fr);
				gap: 30px 10px;
			}

			@media (max-width: $mobile) {
				grid-template-columns: repeat(1, 1fr);
				font-size: 18px;
				line-height: 21.6px;
			}
		}
	}
}