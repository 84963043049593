.techsupport-what-we-done {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 60px;
	}

	.section-caption {
		margin-bottom: 20px;

		@media (max-width: $laptop) { margin-bottom: 0; }
	}

	.done-item {
		display: flex;
		flex-direction: column;
		gap: 40px;
		font: 32px/1.3 Inter;

		@media (max-width: $mobile) {
			&:nth-child(2) { margin-top: -20px; }
		}

		&__title {
			letter-spacing: -0.05em;
			color: $blue;

			@media (max-width: $mobile) {
				font: 500 20px/1.4 Inter;
			}
		}

		&__content {
			font-size: 24px;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 1.25;
			}
		}

		img {
			border-radius: 20px;
		}
	}

	.done-slider {
		display: grid;
		grid-template-columns: 1fr 100px;
		gap: 10px;
		margin-top: -20px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	.splide {
		&__arrows {
			display: flex;
			align-items: flex-start;
			gap: 10px;

			@media (max-width: $mobile) {
				justify-content: space-between;
			}
		}

		&__arrow {
			background: transparent;
			border: none;
			outline: none;
			padding: 14px;
			border-radius: 50%;
			display: flex;
			@include transition-base;
			--color: #000;

			&:not(:disabled) {
				cursor: pointer;
				background: #e3e6e6;
			}

			&::before {
				content: "";
				@extend %icon;
				--size: 12px;
			}

			&--prev {
				&::before {
					@extend %icon--arrow-left;
				}
			}

			&--next {
				&::before {
					@extend %icon--arrow-right;
				}
			}
		}
	}
}