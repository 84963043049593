.mobile-menu {
	background: #E8EEF8;
	padding: 40px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: flex-start;

	&__nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 30px;

		&__item {
			.spoiler {
				&__toggler {
					display: flex;
					align-items: center;
					gap: 10px;

					.icon {
						--size: 12px;
					}

					&.is-active {
						color: $blue;

						.icon {
							--color:#{$blue};
						}
					}
				}
			}

			&__title {
				@extend %h1;

				a {
					display: block;
				}
			}

			&__sub-list {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding-top: 20px;
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: auto;
	}

	&__button {
		border: 2px solid #306AFF;
		border-radius: 50px;
		padding: 20px 40px;
		@extend %title;
	}
}