.awards {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--column-gap);

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		gap: 32px;
	}

	&__text {
		@media (min-width: #{$mobile + 1px}) {
			grid-column: 1 / 9;
		}
	}

	&__list {
		display: flex;
		gap: 60px;

		@media (min-width: #{$mobile + 1px}) {
			grid-column: 11 / 13;
			flex-direction: column;
		}

		@media (max-width: $tablet) {
			gap: 40px;
		}

		@media (max-width: $mobile) {
			gap: var(--column-gap);
		}

		&__item {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: $mobile) {
				flex: 1 1 0;
			}

			&__icon {
				// margin-bottom: 20px;
				width: 60%;

				@media (max-width: $laptop) {
					width: calc(100% - 31px);
				}

				@media (max-width: $tablet) {
					width: 100%;
				}

				@media (max-width: $mobile) {
					width: calc(100% - 31px);
				}

				.icon {
					@extend %icon--flex;
					--color: #{$blue};
				}
			}

			&__title {
				@extend %title;
			}

			&__caption {
				@extend %small-text;
			}
		}
	}
}