.audit-banner {
	padding-bottom: 100px;
	position: relative;

	@media (max-width: $laptop) {
		padding-bottom: 80px;
		padding-top: 40px;
	}

	@media (max-width: $tablet) {
		padding-bottom: 126px;
		padding-top: 20px;
	}

	@media (max-width: $mobile) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&__inner {
		@extend %container;
		display: grid;
		grid-template-columns: auto auto;
		grid-auto-rows: min-content;
		align-items: flex-start;
		gap: 20px var(--column-gap);
		position: relative;
		z-index: 1;

		@media (max-width: $laptop) {
			padding: 0 8px 0 40px;
		}

		@media (max-width: $tablet) {
			padding: 0 20px;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	&__title {
		margin-top: 20px;
		grid-column: 1;
		max-width: 936px;

		@media (max-width: $laptop) {
			margin-top: 0;
		}

		@media (max-width: $mobile) {
			margin-top: 10px;
		}
	}

	&__text {
		margin-top: 60px;
		grid-column: 1;
		max-width: 544px;

		@media (max-width: $tablet) {
			max-width: 355px;
		}

		@media (max-width: $mobile) {
			margin-top: 20px;
		}
	}

	&__breadcrumbs {
		grid-column: 1;

		.breadcrumbs {
			margin-bottom: 0;
		}
	}

	&__btn {
		margin-top: 16px;
		grid-row: 1 / 3;
		align-items: flex-start;
		justify-self: flex-end;

		@media (min-width: #{$mobile + 1px}) {
			grid-column: 2;
		}

		@media (max-width: $laptop) {
			margin-top: 0;
			grid-row: 2;
		}

		@media (max-width: $mobile) {
			grid-row: 3;
			justify-self: center;
		}
	}

	&__img {
		position: absolute;
		bottom: 69px;
		right: 76px;
		width: 985px;
		height: 675px;

		&--1 {
			bottom: 0;
			width: 873px;
		}

		@media (max-width: $laptop) {
			width: 690px;
			height: 473px;
			bottom: 0;
			right: -175px;
		}

		@media (max-width: $tablet) {
			bottom: 15px;
			right: -223px;
		}

		@media (max-width: $mobile) {
			display: none;
		}
	}
}