.blog {
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 80px var(--column-gap);

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	&-card {
		&__header {
			@include proportional-block(433, 290);
			& {
				border-radius: 20px;
			}
		}

		&__body {
			padding: 20px;
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (max-width: $laptop) {
				padding: 20px 10px 0 10px;
				gap: 10px;
			}
		}

		&__date {
			@extend %small-text;
			color: $text_color;
		}

		&__title {
			@extend %title;
		}

		&__text {
			@extend %body;
			color: $text_color;
		}
	}
}