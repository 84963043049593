.partners {
	padding: var(--row-gap) 0;
	border-radius: 40px;
	background: #EFF4FF;

	&__inner {
		display: flex;
		flex-direction: column;
		gap: 60px;
		@extend %container;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
		}

		&__caption {
			@extend %menu;
		}

		&__title {
			@extend %h1;
		}

		&__left {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}

	&__slider {
		&__card {
			height: 260px;
			background: #fff;
			position: relative;
			display: flex;
			align-items: center;
			padding: 30px;
			border-radius: 40px;
			@include transition-base;
			--opacity: 0;

			.splide__slide.is-active & {
				--opacity: 0;
			}

			@media (max-width: $laptop) {
				height: 192px;
			}

			&:hover {
				--opacity: 1 !important;
			}

			&__link {
				transition: inherit;
				opacity: var(--opacity);
				position: absolute;
				right: 0;
				top: 0;
				padding: 30px;
				display: flex;

				&::before {
					content: "";
					@extend %icon, %icon--arrow-out;
					--size: 10px;
					--color: #{$blue};
				}
			}

			&__img {
				width: 100%;
				height: 100%;
				mask-image: var(--image);
				background: #889DCB;
				mask-size: contain;
				mask-position: center;
				mask-repeat: no-repeat;
				transition: inherit;

				&::after {
					content: "";
					display: block;
					background-image: var(--image);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					transition: inherit;
					opacity: var(--opacity);
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}
		}

		&__controls {
			.splide {
				&__arrows {
					display: flex;
					gap: 10px;
				}

				&__arrow {
					background: transparent;
					border: none;
					outline: none;
					padding: 25px;
					border-radius: 50%;
					display: flex;
					@include transition-base;
					--color: #000;

					&:not(:disabled) {
						cursor: pointer;
						background: #{$blue};
						--color: #fff;
					}

					&::before {
						content: "";
						@extend %icon;
						--size: 20px;
					}

					&--prev {
						&::before {
							@extend %icon--arrow-left;
						}
					}

					&--next {
						&::before {
							@extend %icon--arrow-right;
						}
					}
				}
			}
		}
	}
}