.support {
	&__header {
		display: flex;
		flex-direction: column;
		gap: 200px;

		@media (max-width: $tablet) {
			gap: 40px;
		}

		.button {
			background-color: #FBC117;
			color: #000;
		}
	}

	&__subtitle {
		display: flex;
		gap: 24px 40px;
		align-items: center;

		@media (max-width: $tablet) {
			flex-direction: column;
			align-items: flex-start;
		}

		.h3 {
			@media (max-width: $mobile) {
				font-size: 15px;
				line-height: 133%;
			}
		}
	}

	&__advantages {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 32px var(--column-gap);

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__left {
			padding-right: 70px;

			@media (max-width: $tablet) {
				padding-right: 0;
			}
		}

		&__grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px 60px;
			grid-auto-rows: 1fr;

			@media (max-width: $tablet) {
				gap: 20px;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}

		&__item {
			padding: 24px 27px;
			background-color: #e8ebf2;
			border-radius: 20px;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			display: flex;
			align-items: center;

			@media (max-width: $tablet) {
				padding: 24px 20px;
			}
		}
	}

	&__complex {
		&__grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 1fr auto;
			gap: var(--column-gap);

			@media (max-width: $laptop) {
				grid-template-columns: 1fr 1fr;
			}

			@media (max-width: $tablet) {
				grid-template-columns: 1fr;
			}

			@media (max-width: $mobile) {
				display: flex;
				margin-left: calc(var(--container-padding) * -1);
				margin-right: calc(var(--container-padding) * -1);
			}

			.splide {
				width: 100%;

				@media (min-width: #{$mobile + 1px}) {
					display: contents;
				}

				&__arrows {
					display: flex;
					justify-content: flex-end;
					gap: 20px;
					margin-top: 40px;
					padding-right: var(--container-padding);

					@media (min-width: #{$mobile + 1px}) {
						display: none;
					}
				}

				&__arrow {
					width: 64px;
					height: 64px;
					border-radius: 50%;
					border: none;
					background-color: #e8ebf2;
					display: flex;
					align-items: center;
					justify-content: center;
					--color: #{$blue};
					--size: 16px;
					cursor: pointer;

					&::before {
						content: '';
						@extend .icon, .icon--arrow-right;
					}

					&:disabled {
						cursor: default;
						background-color: transparent;
						--color: #000;
					}

					&--prev {
						&::before {
							transform: scaleX(-1);
						}
					}
				}

				&__track {
					@media (min-width: #{$mobile + 1px}) {
						display: contents;
					}
				}

				&__list {
					@media (min-width: #{$mobile + 1px}) {
						display: contents !important;
					}
				}

				&__slide {
					@media (min-width: #{$mobile + 1px}) {
						display: contents;
					}
				}
			}
		}

		&__card {
			transform-style: preserve-3d;
			display: grid;
			grid-template-rows: subgrid;
			grid-template-columns: 1fr auto;
			grid-row: span 2;
			border-radius: 20px;
			transition: transform ease .3s;

			@media (max-width: $mobile) {
				flex-shrink: 0;
				height: 100%;
			}

			&.is-active {
				transform: rotateY(180deg);
			}

			&__title {
				font-size: 36px;
				font-weight: 500;
				line-height: 43px;
				align-self: flex-start;
				grid-row: 2;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 25px;
					align-self: flex-end;
				}
			}

			&__toggler {
				padding: 20px;
				display: flex;
				--size: 24px;
				cursor: pointer;
				border-radius: 50%;
				--color: currentColor;
				transition: background-color ease .3s;
				margin-top: auto;
				margin-left: auto;
				grid-row: 2;

				@media (max-width: $mobile) {
					padding: 12px;
				}

				&:hover {
					background-color: #fff;
					--color: #{$blue};
				}
			}

			&__back &__toggler {
				transform: rotate(45deg);
			}

			&__face,
			&__back {
				grid-column: 1;
				grid-row: 1 / 3;
				backface-visibility: hidden;
				border-radius: inherit;
				padding: 40px;
				color: #fff;

				@media (max-width: $mobile) {
					padding: 20px;
				}
			}

			&__face {
				background-color: $blue;
				display: grid;
				grid-template-rows: subgrid;
			}

			&__back {
				background-color: #0633A7;
				transform: rotateY(180deg);
				display: flex;
				flex-direction: column;
				gap: 55px;

				@media (max-width: $mobile) {
					gap: 20px;
				}
			}
		}
	}

	&__banner {
		gap: 40px;
		padding: 40px;
		border-radius: 40px;
		display: flex;
		align-items: center;
		background-color: #eef0f9;
		position: relative;

		@media (max-width: $mobile) {
			padding: 40px 40px 40px 20px;
			flex-direction: column;
			align-items: flex-start;
		}

		* {
			position: relative;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			border-radius: inherit;
			display: block;
			background-image: url(/src/assets/static/images/support/banner.png);
			background-size: 53% auto;
			background-repeat: no-repeat;
			background-position: top 35% right 0;
			opacity: .1;

			@media (max-width: $mobile) {
				background-size: 145%;
				background-position: top 35% right 60%;
			}
		}

		&__title,
		h2 {
			font-size: 50px;
			line-height: 60px;
			letter-spacing: -5%;
			font-weight: 500;

			@media (max-width: $mobile) {
				font-size: 24px;
				line-height: 27px;
			}
		}
	}

	&__samples {
		display: flex;
		flex-direction: column;
		gap: 60px;

		@media (max-width: $mobile) {
			gap: 24px;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			gap: 60px;
			align-items: flex-start;

			@media (max-width: $mobile) {
				gap: 40px;
				flex-direction: column;
			}
		}

		&__slide {
			aspect-ratio: 390 / 844;
			display: flex;
			align-items: center;
			border-radius: 40px;
			background-color: #e8e8e8;
		}

		.splide {
			&__arrows {
				display: flex;
				gap: 20px;

				@media (max-width: $mobile) {
					margin-left: auto;
				}
			}

			&__arrow {
				width: 64px;
				height: 64px;
				border-radius: 50%;
				border: none;
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				--color: #{$blue};
				--size: 16px;
				cursor: pointer;

				&::before {
					content: '';
					@extend .icon, .icon--arrow-right;
				}

				&:disabled {
					cursor: default;
					background-color: transparent;
					--color: #000;
				}

				&--prev {
					&::before {
						transform: scaleX(-1);
					}
				}
			}
		}
	}

	&__accordion {
		display: flex;
		flex-direction: column;
		gap: 60px;

		&__list {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		&__item {
			padding-bottom: 30px;
			border-bottom: 1px solid #E3E6E6;

			&__header {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				@media (max-width: $mobile) {
					align-items: center;
					gap: 10px;
				}
			}

			&__title {
				font-weight: 500;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: -0.05em;
				padding: 12px 0;

				@media (max-width: $mobile) {
					font-size: 20px;
					line-height: 24px;
				}
			}

			.spoiler__flipper {
				&.is-active {
					.support__accordion__item__flipper {
						.icon {
							@extend %icon--minus;
						}
					}
				}
			}

			&__flipper {
				width: 64px;
				height: 64px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;

				.icon {
					display: flex;
					--color: #{$blue};
					--size: 24px;
					@extend %icon--plus;
				}
			}

			&__content {
				padding-top: 20px;
				padding-right: 80px;

				@media (max-width: $mobile) {
					padding-right: 0;
				}
			}
		}
	}

	&__exactly {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $mobile) {
			gap: 40px;
		}

		&__row {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 40px;

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}

		&__row > &__title {
			grid-column: 1 / -1;
		}

		&__row--disproportional &__item {
			display: grid;
			grid-template-rows: subgrid;
			grid-row: span 2;

			&:last-child {
				grid-column: span 2;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			gap: 40px;

			@media (max-width: $mobile) {
				gap: 20px;
			}
		}

		&__title {
			@extend %h3;

			@media (max-width: $mobile) {
				font-size: 20px;
				line-height: 24px;
			}

			&--small {
				@extend %big-title;
				font-weight: 400;

				@media (max-width: $mobile) {
					font-size: 18px;
					line-height: 21.96px;
				}
			}

			&--accent {
				color: $blue;
			}
		}

		&__list.custom-list {
			font-size: 18px;
			line-height: 24px;
			gap: 12px;
			color: #000;

			li::before {
				margin-top: 7px;
			}
		}
	}

	&__how {
		display: grid;
		grid-template-columns: 20% 5% 5% 21% 10% auto;
		gap: 80px 0;
		@extend %title;
		font-weight: 400;

		@media (max-width: $mobile) {
			overflow-x: auto;
			grid-template-columns: 24px 168px 62px 218px;
			font-size: 14px;
			line-height: 18.2px;
			gap: 30px 0;
			padding-left: 5px;
			margin-bottom: -10px;
			padding-bottom: 10px;
			padding-left: 15px;
			padding-right: 15px;
			margin-left: -15px;
			margin-right: -15px;
		}

		&__source {
			align-self: center;
			grid-row: span 3;
			grid-column: span 2;
			display: grid;
			grid-template-columns: 4fr 1fr;
			gap: 26px 0;
			position: relative;

			@media (max-width: $mobile) {
				grid-column: 1 / -1;
				grid-row: 1;
				gap: 8px;
				display: flex;
			}

			> * {
				grid-column: 1;
			}

			&__img {
				width: 58%;
				background-color: $blue;
				aspect-ratio: 1;
				border-radius: 50%;
				justify-self: center;
				display: flex;
				align-items: center;
				justify-content: center;

				@media (max-width: $mobile) {
					width: 100px;
					flex-shrink: 0;
					margin-left: -5px;
				}

				img {
					width: 50%;
				}
			}

			&::after {
				content: '';
				border-bottom: 1px dashed $blue;
				grid-row: 1;
				grid-column: 2;
				align-self: center;
				width: calc(100% + 5px);
				display: block;
				margin-left: -5px;

				@media (max-width: $mobile) {
					position: absolute;
					left: 0;
					top: 50%;
					border-bottom: none;
					border-left: 1px dashed $blue;
					height: 100px;
					width: auto;
					margin-left: unset;
				}
			}

			p {
				justify-self: center;

				@media (max-width: $mobile) {
					width: 170px;
				}
			}
		}

		&__rows {
			display: grid;
			grid-template-columns: subgrid;
			grid-template-rows: subgrid;
			grid-column: span 4;
			grid-row: span 3;
			position: relative;

			&::before {
				content: '';
				border-right: 1px dashed $blue;
				position: absolute;
				top: 31px;
				bottom: 31px;

				@media (max-width: $mobile) {
					top: 15px;
					bottom: 70px;
				}
			}
		}

		&__row {
			display: grid;
			grid-template-columns: subgrid;
			grid-column: span 4;
			gap: 20px;

			@media (max-width: $mobile) {
				gap: 8px;
			}

			&__divider {
				border-bottom: 1px dashed $blue;
				align-self: center;
			}

			&:first-child &__divider {
				margin-top: 31px;
				align-self: flex-start;

				@media (max-width: $mobile) {
					margin-top: 15px;
				}
			}

			&:last-child &__divider {
				margin-bottom: 31px;
				align-self: flex-end;

				@media (max-width: $mobile) {
					margin-bottom: 70px;
				}
			}
		}

		&__item {
			&:last-child {
				height: 0;

				@media (max-width: $mobile) {
					height: auto;
				}
			}
		}
	}

	&__about {
		padding: var(--row-gap) 0;
		background: #E8EBF2;

		&__content {
			padding: 40px;
			border-radius: 40px;
			background: #fff;
			display: flex;
			flex-direction: column;
			gap: 80px;

			@media (max-width: $mobile) {
				padding: 40px 20px;
				gap: 40px;
			}
		}

		&__title {
			margin-bottom: 40px;
			font-size: 40px;
			font-weight: 500;
			line-height: 52px;
			letter-spacing: -0.05em;
		}

		&__body {
			display: grid;
			grid-template-columns: 1fr 434px;
			gap: 40px var(--column-gap);

			@media (max-width: $small-laptop) {
				align-items: center;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			&__left {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 80px;

				@media (max-width: $laptop) {
					display: contents;
				}
			}

			&__rirght {
				align-self: flex-start;
			}
		}

		&__content &__nums__item {
			> * {
				@media (max-width: $mobile) {
					flex: unset;
				}
			}

			strong {
				@media (max-width: $mobile) {
					font-size: 42px;
					line-height: 55px;
				}
			}
		}

		&__nums {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 16px 24px;

			@media (max-width: $laptop) {
				grid-column: 1 / -1;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			&__item {
				display: flex;
				align-items: center;
				gap: 16px;

				> * {
					@media (max-width: $mobile) {
						flex: 1 1 0;
					}
				}
			}

			strong {
				font-size: 90px;
				font-weight: 500;
				line-height: 1;
				letter-spacing: -0.05em;
				color: $blue;

				@media (max-width: $mobile) {
					font-size: 60px;
				}
			}
		}

		&__links {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: var(--column-gap);

			@media (max-width: $laptop) {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__content &__link {
			@media (max-width: $mobile) {
				font-size: 20px;
				padding: 20px;
				height: auto;
			}
		}

		&__link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-transform: uppercase;
			padding: 42px 20px;
			border-radius: 20px;
			background-color: #e8ebf2;
			letter-spacing: -0.05em;
			font-size: 32px;
			font-weight: 500;
			line-height: 120%;
			transition: all ease .3s;

			@media (max-width: $tablet) {
				font-size: 24px;
				font-weight: 500;
				line-height: 30px;
				padding: 35px 20px;
			}

			@media (max-width: $mobile) {
				height: 140px;
				font-size: 24px;
			}

			&::after {
				content: '';
				@extend .icon, .icon--arrow-up-right;
				--size: 24px;
				--color: #{$blue};
			}

			&:hover {
				background-color: $blue;
				color: #fff;
				align-items: flex-start;

				&::after {
					--color: #fff;
				}
			}
		}

		& &__slider .splide__arrow {
			background-color: #e8ebf2;
		}

		&__content &__slider .splide {
			&__arrows {
				@media (max-width: $mobile) {
					display: flex;
					justify-content: center;
					margin-top: 26px;
				}
			}
		}

		&__slider {
			@media (max-width: $mobile) {
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
			}

			.splide {
				display: grid;
				grid-template-columns: auto 1fr auto;
				gap: 8px;

				@media (max-width: $mobile) {
					grid-template-columns: 1fr;
				}

				&__arrows {
					display: contents;

					@media (max-width: $mobile) {
						display: none;
					}
				}

				&__arrow {
					align-self: center;
					width: 64px;
					height: 64px;
					border-radius: 50%;
					border: none;
					display: flex;
					align-items: center;
					background-color: transparent;
					justify-content: center;
					--color: #{$blue};
					--size: 16px;
					cursor: pointer;
					transition: background-color ease .3s;

					&::before {
						content: '';
						@extend .icon, .icon--arrow-right;
					}

					&:hover {
						background-color: #fff;
					}

					&:disabled {
						cursor: default;
						background-color: transparent;
						--color: #000;
					}

					&--prev {
						&::before {
							transform: scaleX(-1);
						}
					}

					&--prev {
						order: -99;
					}

					&--next {
						order: 99;
					}
				}

				&__pagination {
					gap: 8px;
					margin-top: 40px;

					li {
						display: flex;
					}

					&__page {
						background-color: #fff;
						width: 12px;
						height: 12px;
						appearance: none;
						border: none;
						border-radius: 999px;
						transition: all ease .3s;

						&.is-active {
							background-color: #306aff;
							width: 36px;
						}
					}
				}
			}
		}

		&__content &__slide {
			img {
				@media (max-width: $mobile) {
					margin-left: 0;
					margin-right: 0;
					width: 100%;
				}
			}
		}

		&__slide {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
			text-align: center;

			img {
				width: 100%;
				height: auto;
				border-radius: 20px;
				display: block;

				@media (max-width: $mobile) {
					margin-left: 20px;
					margin-right: 20px;
					width: calc(100% - 40px);
				}
			}
		}
	}

	&__tariff {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 0 var(--column-gap);

		@media (max-width: $tablet) {
			grid-template-columns: 1fr;
			gap: 36px;
		}

		&__headers {
			display: grid;
			grid-template-rows: subgrid;
			grid-row: span 999;
			font-size: 18px;
			line-height: 27px;

			@media (max-width: $tablet) {
				display: none;
			}

			span {
				display: block;
				padding: 14px 20px;
				align-self: center;
			}
		}

		&__card {
			display: grid;
			grid-template-rows: subgrid;
			grid-row: span 999;
			border-radius: 30px;
			text-align: center;
			background-color: #fff;

			@media (max-width: $tablet) {
				grid-row: unset;
				grid-template-rows: unset;
			}

			&--priority {
				border: 1px solid #000;
			}

			&--priority &__title {
				background-color: $blue;
				color: #fff;
			}

			&__title {
				background-color: #e8ebf2;
				padding: 30px;
				border-top-left-radius: inherit;
				border-top-right-radius: inherit;
				@extend %big-title;
				font-weight: 500;
			}

			&__row {
				font-size: 24px;
				line-height: 36px;
				font-weight: 400;
				padding: 16px;
				align-self: center;

				@media (max-width: $mobile) {
					display: flex;
					gap: 10px;
					padding: 20px;
					flex-direction: column;
					align-items: center;
					border-bottom: 1px solid #D3D3D3;
				}

				&__caption {
					font-size: 14px;
					line-height: 19.6px;
					color: #7a7a7a;

					@media (min-width: #{$mobile + 1px}) {
						display: none;
					}
				}
			}

			&__footer {
				padding: 40px;
			}

			.icon--check {
				--color: #00C853;
			}

			.icon--close {
				--color: #ff0000;
			}
		}
	}
}