.landing2-cost {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	.elements-grid {
		display: grid;
		grid-template-columns: 1fr auto auto;
		grid-template-rows: auto 1fr;
		grid-auto-flow: dense;
		gap: 40px 100px;
		padding-right: 196px;

		@media (max-width: $laptop) {
			padding-right: 0;
		}

		@media (max-width: $mobile) {
			max-width: $cols_maxWidth;
			align-self: center;
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 20px;

			ul { margin-top: 20px; }
		}
	}

	.custom-list {
		grid-row: 1 / span 2;

		@media (max-width: $mobile) {
			order: 4;
		}
	}

	.button {
		grid-column: 2 / span 2;
		align-self: start;

		@media (max-width: $mobile) {
			order: 3;
			max-width: 330px;
		}
	}

	.element-block {
		@extend %landing2-element-block;

		@media (max-width: $mobile) {
			&.price { order: 1; }
			&.period {
				order: 2;
				margin-bottom: 20px;
			}

			gap: 10px;
			flex-direction: row;

			.block-name {
				width: 90px;
				flex-shrink: 0;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}