  .price-card {
  	padding: 40px;
  	background: $background_color;
  	border-radius: 40px;
  	display: flex;
  	flex-direction: column;
  	gap: 20px;
  	height: auto;

  	@media (max-width: $mobile) {
  		border-radius: 16px;
  		padding: 24px;
  	}

  	&__caption {
  		color: $text_color;
  	}

  	&__title {
  		@extend %title;
  	}

  	&__header {
  		display: flex;
  		gap: 12px 24px;

  		@media (max-width: $mobile) {
  			flex-wrap: wrap;
  		}

  		&__block {
  			display: flex;
  			flex-direction: column;
  			gap: 5px;
  			white-space: nowrap;

  			@media (min-width: #{$mobile + 1px}) {
  				margin-left: auto;
  				margin-right: auto;

				&:first-child {
					margin-left: 0;
				}
  			}
  		}
  	}

  	&__footer {
  		display: flex;
  		justify-content: space-between;
  		align-items: center;
  		margin-top: auto;
  		gap: 16px;

  		@media (max-width: $mobile) {
  			flex-direction: column;
  		}
  	}

  	&__price {
  		white-space: nowrap;

  		&__big {
  			@extend %h2;
  		}

  		&__small {
  			font-weight: 500;
  			font-size: 30px;
  		}
  	}

  	&-after {
  		padding: 40px;

  		@media (max-width: $mobile) {
  			padding: 24px;
  		}
  	}

  	&-group {
  		display: flex;
  		flex-direction: column;
  		gap: 60px;

  		@media (max-width: $laptop) {
  			gap: 40px;
  		}

  		@media (max-width: $mobile) {
  			gap: 24px;
  		}

  		&__title {
  			@extend %lead;
  		}

  		&__inner {
  			display: flex;
  			gap: var(--column-gap);

  			@media (max-width: $laptop) {
  				flex-wrap: wrap;
  			}

  			@media (max-width: $mobile) {
  				flex-direction: column;
  			}
  		}

  		&__item {
  			flex: 1 1 0;
  		}
  	}
  }