@mixin transition-base($property: all, $duration: .3s) {
	transition: $property var(--transition-duration, #{$duration}) ease;
}

@mixin text-line-clip($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@function proportion($width, $height) {
	@return math.div($height, $width) * 100%;
}

@mixin proportional-block($width, $height) {
	width: 100%;
	display: flex;
	position: relative;
	overflow: hidden;
	mask-image: radial-gradient(#000, #000);

	@supports (aspect-ratio : 1 / 1) {
		aspect-ratio: calc($width / $height);
	}

	@supports not (aspect-ratio : 1 / 1) {
		height: 0;
		padding-bottom: proportion($width : $width, $height : $height);

		> :first-child {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	> :first-child {
		border-radius: inherit;
		mask-image: inherit;
		overflow: hidden;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

@mixin Portfolio {
	display: flex;
	flex-direction: column;
	gap: 80px;

	@media (max-width: $mobile) {
		gap: 60px;
	}

	&__columns {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--column-gap);

		@media (max-width: $laptop) {
			overflow: auto;
			grid-template-columns: repeat(3, minmax(404px, 1fr));
		}

		@media (max-width: $tablet) {
			grid-template-columns: repeat(3, minmax(299px, 1fr));
		}

		@media (max-width: $mobile) {
			grid-template-columns: repeat(3, 90%);
		}
	}

	&__list {
		display: grid;
		grid-template-columns: minmax(auto, 360px) 1fr auto;
		gap: 20px 58px;

		@media (max-width: $laptop) {
			gap: 20px;
		}

		@media (max-width: $tablet) {
			grid-template-columns: minmax(auto, 360px) 1fr;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
	}

	&__item {
		display: grid;
		grid-template-columns: subgrid;
		align-items: center;
		grid-column: 1 / -1;
		background-color: #E8EBF2;
		border-radius: 20px;
		padding: 40px;

		@media (max-width: $tablet) {
			gap: 20px 40px;
		}

		@media (max-width: $mobile) {
			padding: 20px;
		}

		&__number {
			font: 18px/1.2 Inter;
			color: $text_color;
		}

		&__title {
			@extend %big-title;
			font-weight: 500;

			@media (max-width: $mobile) {
				font-size: 20px;
			}
		}

		&__text {
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;

			@media (max-width: $mobile) {
				font-size: 16px;
				line-height: 24px;
			}
		}

		&__buttons {
			display: flex;
			gap: 20px;

			@media (max-width: $laptop) {
				flex-direction: column;
				margin-left: 15px;
			}

			@media (max-width: $tablet) {
				flex-direction: row;
				grid-column: 1 / -1;
				margin-left: 0;
			}

			@media (max-width: $mobile) {
				flex-direction: column;
				margin-top: 20px;
			}

			.button {
				@media (max-width: $tablet) {
					flex: 1 1 0;
				}
			}
		}
	}

	&__card-picture {
		display: flex;
		flex-direction: column;
		gap: 20px;
		border-radius: 20px;
		border: 1px solid $text_color;

		&:hover &__link, &:hover &__title {
			color: $blue;
			--color: #{$blue};
		}

		&:hover &__link {
			background-color: #fff;
		}

		&__img  img {
			border-radius: 20px 20px 0 0;
			display: block;
			height: 313px;
			width: 100%;
			object-fit: cover;

			@media (max-width: $tablet) {
				height: 173px;
			}
		}


		&__body {
			display: grid;
			grid-template-columns: 1fr auto;
			gap: 12px 20px;
			color: #000;
			padding: 20px;
		}

		&:has(&__link:hover) &__title {
			color: $blue;
		}

		&__title {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
			transition: color ease .3s;
		}

		&__text {
			@include text-line-clip(3);
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;

			@media (max-width: $mobile) {
				display: block;
			}
		}

		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			grid-row: 1 / span 2;
			grid-column: 2;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			--size: 24px;
		}
	}

	&__card-text {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 20px;
		background-color: #e8ebf2;
		border-radius: 20px;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;
			margin-bottom: auto;
			transition: color ease .3s;

			@media (max-width: $mobile) {
				margin-bottom: 0;
				flex-grow: 1;
				align-items: flex-start;
			}

			&:hover {
				color: $blue;
				--color: #{$blue};
			}
		}

		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			grid-column: 2;
			width: 64px;
			height: 64px;
			--size: 24px;
		}

		&__title {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;

			@media (max-width: $mobile) {
				font-size: 20px;
				margin-top: auto;
			}
		}

		&__info {
			background-color: #fff;
			padding: 10px;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			gap: 4px;

			small {
				@extend %small-text;
				color: $text_color;
			}

			span {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}
	}
}

%SplideArrow {
	--arrow-size: 64px;
	align-self: center;
	width: var(--arrow-size);
	height: var(--arrow-size);
	border-radius: 50%;
	border: none;
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;
	--color: #{$blue};
	--size: 16px;
	cursor: pointer;
	transition: background-color ease .3s;

	&::before {
		content: '';
		@extend .icon, .icon--arrow-right;
	}

	&:hover {
		background-color: #fff;
	}

	&:disabled {
		cursor: default;
		background-color: transparent;
		--color: #000;
	}

	@media (max-width: $laptop) {
		--arrow-size: 56px;
	}
}