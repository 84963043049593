.hover-gallery {
	height: 100%;
	position: relative;
	--size: 212px;

	@media (max-width: $mobile) {
		height: calc(var(--size) * 1.3);
	}

	&__img {
		width: 400px;
		height: 400px;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		object-fit: cover;
		border-radius: 40px;
		pointer-events: none;

		@media (max-width: $mobile) {
			opacity: 1 !important;
			transform: none !important;
		}

		&:nth-child(n + 4) {
			@media (max-width: $mobile) {
				display: none;
			}
		}

		&:nth-child(3) {
			@media (max-width: $mobile) {
				z-index: 3 !important;
				top: initial;
				left: initial;
				bottom: 0;
				right: 0;
				width: var(--size);
				height: var(--size);
			}
		}

		&:nth-child(2) {
			@media (max-width: $mobile) {
				opacity: 0.5 !important;
				z-index: 2 !important;
				top: 50%;
				width: calc(var(--size) * 0.75);
				height: calc(var(--size) * 0.75);
				left: 50%;
				transform: translate(-70%, -70%) !important;
			}
		}

		&:nth-child(1) {
			@media (max-width: $mobile) {
				z-index: 1 !important;
				position: static;
				width: calc(var(--size) * 0.65);
				height: calc(var(--size) * 0.65);
				opacity: 0.1 !important;
			}
		}
	}
}