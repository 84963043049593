.prices {
	--gap: 40px;

	@media (max-width: $tablet) {
		--gap: 20px;
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 20px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&:not(:last-of-type) {
			padding-bottom: var(--gap);
		}

		&:not(:first-of-type) {
			padding-top: var(--gap);
			border-top: 2px solid #E3E6E6;
		}

		&__title {
			@extend %h2;
			align-self: flex-end;
			display: flex;
			align-items: center;

			&__emoji {
				display: inline-block;
				margin-right: 20px;

				img {
					height: 40px;
					width: auto;
				}
			}
		}

		&__text {
			@extend %body;
			color: $text_color;
		}

		&__time {
			@extend %body;
			color: $text_color;

			@media (max-width: $mobile) {
				order: 4;
			}

			@media (min-width: #{$mobile + 1px}) {
				text-align: right;
			}
		}

		&__price {
			@extend %title;
			align-self: flex-end;

			@media (max-width: $mobile) {
				order: 3;
			}

			@media (min-width: #{$mobile + 1px}) {
				text-align: right;
			}
		}
	}
}