.services {
	--gap: 60px;
	display: flex;
	flex-direction: column;

	@media (max-width: $laptop) {
		--gap: 40px;
	}

	@media (max-width: $tablet) {
		--gap: 20px;
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 20px;

		&:not(:first-of-type) {
			padding-top: var(--gap);
			border-top: 1px solid #E3E6E6;
		}

		&:not(:last-of-type) {
			padding-bottom: var(--gap);
		}

		&__link {
			grid-column: 2;
			grid-row: 1 / span 2;
		}

		&__text {
			color: $text_color;
			@extend %body;
		}

		&__title {
			@extend %title;
		}
	}
}