.landing2-advantages {
	.section__content {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 80px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	h2 {
		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1.2;
		}
	}

	.advantages-grid {
		max-width: 100%;

		@media (min-width: #{$mobile + 1px}) {
			.splide__track .splide__list {
				visibility: visible;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;

				@media (max-width: $tablet) {
					grid-template-columns: 1fr 1fr;
				}
			}
		}
	}

	.advantage {
		--padding: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: var(--padding);
		background-color: $background_color;
		border-radius: var(--padding);
		color: $text_color;

		.title { color: #000; }

		@media (max-width: $mobile) {
			--padding: 20px;

			.title {
				font-size: 26px;
				line-height: 30px;
			}
		}
	}

	.splide__pagination {
		margin-top: 20px;
		gap: 8px;

		li {
			font-size: 0;
		}

		&__page {
			background-color: #d2d6e1;
			width: 8px;
			height: 8px;
			appearance: none;
			border: none;
			border-radius: 50%;

			&.is-active {
				background-color: #306aff;
			}
		}
	}
}