.landing2-expertise {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $mobile) {
			overflow: hidden;
			gap: 40px;
		}
	}

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
	}

	.splide {
		&__arrows {
			display: flex;
			gap: 20px;

			@media (max-width: $mobile) {
				display: none;
			}
		}

		&__arrow {
			background: transparent;
			border: none;
			outline: none;
			padding: 25px;
			border-radius: 50%;
			display: flex;
			@include transition-base;
			--color: #000;

			&:not(:disabled) {
				cursor: pointer;
				background: #{$blue};
				--color: #fff;
			}

			&::before {
				content: "";
				@extend %icon;
				--size: 20px;
			}

			&--prev {
				&::before {
					@extend %icon--arrow-left;
				}
			}

			&--next {
				&::before {
					@extend %icon--arrow-right;
				}
			}
		}
	}

	.splide__slide {
		display: grid;
		grid-template-columns: 1fr 310px;
		column-gap: 111px;

		@media (max-width: $laptop) {
			column-gap: 40px;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 100%;
		}
	}

	blockquote {
		display: flex;
		gap: 51px;
		align-items: flex-start;

		& > img {
			width: auto;
			height: 46px;
		}

		@media (max-width: $mobile) {
			gap: 20px;
			flex-direction: column;

			& > img { height: 28px; }
		}
	}

	.author {
		--imageSize: 100px;
		display: grid;
		grid-template: auto 1fr / var(--imageSize) 1fr;
		gap: 18px;

		img {
			width: var(--imageSize);
			height: var(--imageSize);
			object-fit: cover;
			border-radius: 50%;
			grid-row: 1 / span 2;
		}

		&__position {
			color: $text_color;
		}

		@media (max-width: $mobile) {
			--imageSize: 69px;
			row-gap: 14px;
			margin-top: 40px;

			&__position {
				font-size: 12px;
				line-height: 1.3;
			}
		}
	}
}