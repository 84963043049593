.custom-radio {
	display: flex;
	align-items: center;
	cursor: pointer;
	--size: 20px;
	--border-width: 2px;
	--check-size: 8px;
	user-select: none;

	input[type="radio"] {
		width: var(--size);
		height: var(--size);
		visibility: hidden;
		position: relative;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		appearance: none;

		&::before {
			visibility: visible;
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border-width: var(--border-width);
			border-style: solid;
			border-color: $blue;
			border-radius: 50%;
			@include transition-base;
		}

		&:checked {
			&::before {
				border-width: calc((var(--size) - var(--check-size)) / 2);
			}
		}
	}
}