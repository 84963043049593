header {
	margin-bottom: var(--header-margin);
	z-index: 1;
	position: relative;
}

.header {
	position: sticky;
	top: calc(-1 * var(--header-height, 300px));
	z-index: 98;
	transition: top 0.7s;

	&.sticky {
		top: 0;
		background-color: #fafafa;
	}

	@include transition-base;

	@media (min-width: $laptop) {
		&--inversed {
			background-color: transparent;
			color: #fff;
		}

		&--inversed &__logo {
			img {
				&:nth-child(1) {
					display: none;
				}

				&:nth-child(2) {
					display: block;
				}
			}
		}

		&--inversed &__search-toggler {
			.icon {
				--color: #fff;
			}
		}

		&--inversed .button.button--secondary {
			border-color: #fff;
			color: #fff;

			&:hover {
				background: #fff;
				color: $blue;
			}
		}

		&--inversed .header__link {

			&--is-active,
			&:hover {
				color: #FFF;
			}
		}
	}

	&.search-opened {
		background: $blue;
		color: #fff;

		.header__link {

			&--is-active,
			&:hover {
				color: #FFF;
			}
		}

		.header__search-input {
			opacity: 1;

			@media (max-width: $mobile) {
				position: fixed;
				background: $blue;
				top: var(--header-height);
				left: 0;
				bottom: 0;
				right: 0;
				margin-bottom: 0;
				align-items: flex-start;
				padding: 20px var(--container-padding);
				flex-direction: column;
			}

			&::before {
				@media (max-width: $mobile) {
					content: "";
					width: 100%;
					position: relative;
					top: -20px;
					border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
				}
			}
		}

		.search-opened__hidden {
			display: none;
		}

		.header__search-toggler .icon.icon--close {
			@extend %icon--burger-close;
			--size: 44px;

			@media (max-width: $mobile) {
				--size: 40px;
			}
		}

		.header__search-toggler,
		.header__burger .icon {
			--color: #fff;

			&:hover {
				--color: #{$blue};
				background: #fff;
			}
		}
	}

	&:not(.search-opened) {
		&.header--white {
			background: #fff;
		}

		.search-opened__visible {
			display: none;
		}

		.header__search-input {
			background: transparent;
			opacity: 0;
		}
	}

	&__wrapper {
		padding: 40px 0;
		position: relative;

		@media (max-width: $tablet) {
			padding: 30px 0;
		}

		@media (max-width: $mobile) {
			padding: 10px 0;
		}
	}

	&__logo {
		img {
			width: 89px;
			height: auto;

			@media (max-width: $tablet) {
				width: 60px;
			}
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		gap: 24px;

		@media (max-width: $tablet) {
			gap: 12px;
		}
	}

	&__group {
		--group-gap: 50px;
		display: flex;
		align-items: stretch;
		gap: var(--group-gap);

		&--grow {
			flex: 1 1 0;
		}

		@media (max-width: $laptop) {
			--group-gap: 40px;
		}

		@media (max-width: $tablet) {
			--group-gap: 14px;
		}
	}

	&__item {
		position: relative;
		height: auto;
		display: flex;
		align-items: center;

		&:not(:hover) &__drop {
			opacity: 0;
			pointer-events: none;
		}

		&__drop {
			display: flex;
			flex-direction: column;
			gap: 10px;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			padding: 20px;
			border-radius: 20px;
			background: #fff;
			@include transition-base;
			white-space: nowrap;
			z-index: 10;
			color: #000;
			@extend %default-shadow;

			.header__link {
				&:hover {
					color: $blue;
				}
			}
		}
	}

	&__search {
		&-toggler {
			cursor: pointer;
			margin-left: calc(max(calc(var(--group-gap) - 20px), 0px) * -1);
			border-radius: 50%;
			width: 44px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: $mobile) {
				border: #fff 2px solid;
				width: 40px;
				height: 40px;
			}
		}

		&-input {
			@include transition-base;
			display: flex;
			align-items: center;
			width: 100%;
			padding: 0 15%;

			input {
				width: 100%;
				background: transparent;
				outline: none;
				border: none;
				color: #fff;
				@extend %menu;

				&::placeholder {
					color: #ffffff80;
				}
			}
		}
	}

	&__link {
		@extend %menu;
		white-space: nowrap;

		&:hover,
		&--is-active {
			color: $blue;
		}
	}

	&__burger {
		.icon {
			--color: #{$blue};
			--size: 40px;
			display: block;
			@extend %icon--burger;
		}

		&.popup-controller--active {
			.icon {
				@extend %icon--burger-close;
			}
		}
	}
}