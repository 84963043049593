.why {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 30px 0;

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__card {
		display: grid;
		grid-template-rows: subgrid;
		grid-row: span 2;
		padding-right: 40px;

		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__title {
			@extend %title;
		}

		&__text {
			color: $text_color;
		}
	}
}