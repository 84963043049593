.landing2-choosing {
	.section__content {
		background-color: $blue;
		color: #fff;
		padding: 40px;
		border-radius: 40px;
		display: grid;
		grid-template-columns: 668px 1fr;
		gap: 80px;
		justify-items: start;

		@media (max-width: 1226px) {
			grid-template-columns: 1fr;
			gap: 30px;

			.title {
				grid-row: 2;
			}

			img {
				max-width: 98px;
				justify-self: center;
			}

			.button {
				margin-left: 0;
				justify-self: center;
			}
		}

		@media (max-width: $mobile) {
			border-radius: 20px;
			padding: 30px 15px;
			gap: 24px;
		}
	}

	img {
		max-width: 178px;
		max-height: 212px;
	}

	.button {
		margin-left: 16px;

		@media (max-width: $mobile) {
			max-width: 300px;
			font-size: 16px;
			line-height: 1.2;
		}
	}
}