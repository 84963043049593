.techsupport-client-profit {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $tablet) { gap: 60px; }
		@media (max-width: $mobile) { gap: 40px; }
	}

	.section-caption {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $tablet) {
			line-height: 1;
			align-items: flex-start;
			flex-direction: column;
			gap: 40px;
		}

		.button {
			--px: 40px;
			letter-spacing: normal;

			@media (max-width: $laptop) {
				--py: 20px;
				font-size: 20px;
			}

			@media (max-width: $tablet) {
				--py: 10px;
				--px: 30px;
				font-size: 16px;
				line-height: 30px;
			}

			@media (max-width: $mobile) {
				--px: 10px;
				max-width: 330px;
			}
		}
	}

	.client-profits {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		font: 24px/1.3 Inter;

		@media (max-width: $tablet) {
			font-size: 18px;
			line-height: 1.2;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			font: 500 16px/1.25 Inter;
		}

		&__card {
			display: grid;
			grid-auto-rows: 554px;
			background-color: #E8EBF2;
			border-radius: 20px;
			padding: 40px 0 0 40px;

			@media (max-width: $laptop) { grid-auto-rows: 484px; }

			@media (max-width: $tablet) { grid-auto-rows: 374px; }

			@media (max-width: $mobile) {
				grid-auto-rows: 374px;
				padding: 20px 0 20px 20px;
			}

			span {
				grid-row: 1;
				grid-column: 1;
				display: block;
				max-width: 390px;

				@media (max-width: $laptop) {
					max-width: 280px;
				}

				@media (max-width: $mobile) {
					max-width: 100%;
					padding-right: 20px;
				}
			}

			img {
				grid-row: 1;
				grid-column: 1;
				align-self: end;
				justify-self: end;
				width: 400px;
				opacity: 0.4;

				@media (max-width: $laptop) { width: 290px; }

				@media (max-width: $tablet) { width: 205px; }

				@media (max-width: $mobile) {
					width: 210px;
					margin-right: -20px;
				}
			}
		}
	}
}