.techsupport-caption {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (min-width: #{$mobile + 1px}) and (max-width: $tablet) {
			gap: 50px;
		}
	}

	h1 {
		@media (max-width: $laptop) {
			font-size: 50px;
			line-height: 1;
		}

		@media (max-width: $mobile) { font-size: 36px; }
	}

	.lead {
		@media (max-width: $laptop) { font-size: 24px; }
		@media (max-width: $mobile) { font-size: 18px; }
	}
}