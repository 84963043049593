@import 'tippy.js/dist/tippy';

[data-tippy-content] {
	cursor: help;
}

.tippy {
	&-box {
		background: #fff;
		color: $text_color;
		padding: 20px;
		@extend %small-text;
		@extend %default-shadow;
	}

	&-backdrop {}

	&-arrow {}

	&-content {}
}