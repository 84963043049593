.scroll-preventing-slider {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--column-gap);

	&--type-1 {
		.splide {
			grid-column: 1 / -1;

			@media (min-width: #{$mobile + 1px}) {
				grid-column: span 8;
			}
		}

		&::before,
		&::after {
			@media (min-width: #{$mobile + 1px}) {
				content: "";
				grid-column: span 2;
			}
		}
	}

	&--type-2 {
		.splide {
			grid-column: 1 / -1;
		}
	}

	.splide {
		min-width: 0;

		&__track {
			overflow: visible;
		}
	}

	&__card {
		background: $background_color;
		border-radius: 40px;
		padding: 40px;
		display: flex;
		flex-direction: column;
		$title: & + "__title";
		$price: & + "__price";
		$caption: & + "__caption";
		$gallery: & + "__gallery";

		@media (max-width: $tablet) {
			padding: 20px;
		}

		&--type-1 {
			gap: 40px;

			@media (max-width: $laptop) {
				gap: 20px;
			}

			#{$title} {
				@extend %h1;
			}
		}

		&--type-2 {
			gap: 20px;

			#{$title} {
				@extend %h2;
			}
		}

		#{$gallery} {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			@media (max-width: $mobile) {
				display: grid;
				grid-template-columns: repeat(2, 111px);
				justify-content: center;
			}

			&__item {
				height: 72px;

				@media (max-width: $mobile) {
					height: auto;
					width: 100%;
				}

				img {
					height: 100%;
					width: auto;

					@media (max-width: $mobile) {
						width: 100%;
						height: auto;
					}
				}
			}
		}

		#{$caption} {
			@extend %body;
			color: $text_color;
		}
	}
}