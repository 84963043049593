@import "icons";

.icon,
%icon {
	display: inline-flex;
	border: none;
	user-select: none;
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center;
	flex-shrink: 0;
	@include transition-base;
	width: var(--size, 24px);
	height: var(--size, 24px);
	background: var(--color, #000);

	&--flex {
		width: 100%;
		padding-bottom: 100%;
		height: 0;
	}

	&--color {
		&--blue {
			--color:#{$blue};
		}
	}
}