@use "sass:math";
@import "ux-select"; // Не разорбрался, как из node_modules импортировать, просто скопировал
@import "breakpoint";
@import "variables";
@import "container";
@import "color";
@import "font";
@import "mixins";
@import "header";
@import "breadcrumbs";
@import "button";
@import "custom-checkbox";
@import "footer";
@import "general";
@import "steps";
@import "form";
@import "vacancy-list";
@import "banner";
@import "tariff";
@import "partners";
@import "support";
@import "license";
@import "search-list";
@import "custom-list";
@import "splide";
@import "mobile-menu";
@import "certificates";
@import "clients";
@import "team";
@import "main";
@import "search-banner";
@import "request-themes";
@import "audit-banner";
@import "spoiler";
@import "why";
@import "main-banner";
@import "hover-gallery";
@import "main-quote";
@import "custom-file-input";
@import "icon";
@import "learn";
@import "step-cards";
@import "audit-cards";
@import "expert-banner";
@import "integration-tabs";
@import "cards-request";
@import "popup";
@import "request";
@import "request_new";
@import "price-card";
@import "contacts";
@import "news-detail";
@import "radio-tags";
@import "icons";
@import "idunno";
@import "input";
@import "cases-filter";
@import "blog";
@import "feedback-block";
@import "prices";
@import "services";
@import "tippy";
@import "tabs";
@import "socials";
@import "advantages";
@import "custon-radio";
@import "section";
@import "integration";
@import "case";
@import "scroll-preventing-slider";
@import "tag";
@import "outer-link";
@import "quote";
@import "case-card";
@import "pseudo-scroll";
@import "propirtional-img";
@import "two-columns";
@import "pagination";
@import "text-content";
@import "awards";
@import "custom-select";
@import "text-autoprint";

@import "landing2";
@import "landing2-caption";
@import "landing2-inner-navigation";
@import "landing2-transportation";
@import "landing2-trusters";
@import "landing2-suit-for";
@import "landing2-crm";
@import "landing2-workplan";
@import "landing2-advantages";
@import "landing2-test-runner";
@import "landing2-why-bitrix";
@import "landing2-start-acting";
@import "landing2-cost";
@import "landing2-tariffs";
@import "landing2-choosing";
@import "landing2-banner";
@import "landing2-expertise";
@import "landing2-cases";
@import "landing2-get-offer";
@import "landing2-guaranties";
@import "landing2-faq";
// @import "landing2-choosing-reason";
// @import "landing2-more-services";
@import "landing2-lets-discuss";

@import "techsupport";
@import "techsupport-caption";
@import "techsupport-about";
@import "techsupport-client";
@import "techsupport-workplan";
@import "techsupport-client-profit";
@import "techsupport-what-we-done";
@import "techsupport-response";
@import "techsupport-similar-cases";
@import "techsupport-warranty";
@import "techsupport-form";

@import "universal";

@import "cases";

// @import "indent-generator"; не доделан, надо разобраться с приоритетами правил
.index-links {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px 30px;

	@media (max-width: $tablet) {
		grid-template-columns: 1fr;
	}

	a {
		@extend %h2;

		&::after {
			content: attr(href);
		}
	}
}