section {
	@include transition-base(transform);
	z-index: 0;
	transform: translateY(0);

	&:not(.is-visible, .section--underheader) {
		transform: translateY(100px);
	}
}

.section {
	&__inner {
		display: flex;
		flex-direction: column;
		gap: 80px;

		@media (max-width: $laptop) {
			gap: 60px;
		}

		@media (max-width: $mobile) {
			gap: 50px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		flex-wrap: wrap;

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: flex-start;
		}

		&--align-start {
			align-items: flex-start;
		}

		.button {
			@media (max-width: $mobile) {
				width: auto;
			}
		}
	}

	&__content {}

	&--near {
		margin-top: calc(var(--column-gap) - var(--row-gap));
	}

	&--no-overflow {
		overflow: hidden;
	}

	&--underheader {
		@extend %fullwidth-but-not;

		@media (min-width: #{$laptop + 1px}) {
			--offset: calc((var(--header-margin) + var(--header-height, 124px)));
			margin-top: calc(var(--offset) * -1);
		}
	}

	&--underheader--grey &__inner {
		background: #EEF0F9;
	}

	&--underheader--purple &__inner {
		background: #EEF0F9;
	}

	&--underheader--blue &__inner {
		background: $blue;
	}

	&--underheader &__inner {
		padding-top: var(--offset);
		border-radius: 40px;
		overflow: hidden;
	}

	&__footer {
		display: flex;
		justify-content: center;
	}
}