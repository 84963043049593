.request-themes {
	padding: var(--row-gap) 0;
	background: #fff;
	border-radius: 40px;

	@media (max-width: $laptop) {
		// border-radius: 0;
	}

	&__container {
		@extend %container;
	}

	&__content {
		justify-content: space-between;
		display: flex;
		gap: 24px;

		@media (max-width: $laptop) {
			gap: var(--column-gap);
		}

		@media (max-width: $mobile) {
			flex-direction: column;
			gap: 20px;
		}
	}

	&__item {
		flex: 0 1 calc(1/12 * 3 * 100%);
		display: flex;
		flex-direction: column;
		gap: 30px;

		@media (max-width: $laptop) {
			flex: 1 1 0;
		}

		&:nth-child(1n + 1) {
			grid-column: 1 / 4;
		}

		&:nth-child(2n + 2) {
			grid-column: 5 / 8;
		}

		&:nth-child(3n + 3) {
			grid-column: 9 / 12;
		}

		&__title {
			@extend %big-title;
		}

		&__text {
			display: flex;
			flex-direction: column;
			gap: 20px;

			p {
				color: $text_color;
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
			gap: 40px;
			flex-grow: 1;

			@media (max-width: $laptop) {
				padding: 0 20px;
			}
		}

		&__img {
			@include proportional-block(323, 210);

			img {
				object-fit: contain !important;
			}
		}

		&__footer {
			margin-top: auto;
		}
	}
}