.techsupport-client {
	color: #000;

	.container-unlimited {
		@media (max-width: $laptop) {
			padding-inline: var(--container-padding);
		}

		.section__inner {
			background-color: #e8ebf2;
			padding-block: 140px;
			border-radius: 40px;

			@media (max-width: $laptop) { padding-block: 80px; }
			@media (max-width: $mobile) { padding-block: 40px; }
		}
	}

	.container {
		--client-info-width: 322px;

		display: grid;
		grid-template: auto 1fr / 1fr var(--client-info-width);
		gap: 80px;

		@media (min-width: #{$tablet + 1px}) and (max-width: $laptop) {
			--container-padding: 40px;
		}

		@media (max-width: $laptop) {
			--client-info-width: 281px;
			gap: 60px 40px;
		}

		@media (max-width: $tablet) {
			--client-info-width: 216px;
		}

		@media (max-width: $mobile) {
			--container-padding: 20px;
			gap: 40px;
			grid-template: auto / 1fr;
		}
	}

	.client-quote {
		display: flex;
		gap: 10px;
		margin-top: 52px;

		@media (max-width: $laptop) {
			margin-top: 0;
		}

		&::before {
			content: url(../static/images/techsupport/quote.svg);
			width: 20px;
			flex-shrink: 0;
		}

		.quote-caption {
			font: 500 32px/1 Inter;
			letter-spacing: -0.05em;
			margin-bottom: 40px;

			@media (max-width: $mobile) {
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 20px;
			}
		}

		q { quotes: none; }

		p {
			--line-height: 1.3em;

			font: 24px/var(--line-height) Inter;

			& + p { margin-top: var(--line-height); }

			@media (max-width: $mobile) {
				--line-height: 1.2em;
				font-size: 18px;
			}
		}
	}

	.client-info {
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media (min-width: #{$mobile + 1px}) {
			grid-column: 2;
			grid-row: 1 / span 2;
		}

		@media (max-width: $mobile) {
			max-width: 290px;
		}

		&__picture {
			border-radius: 50%;
			width: 180px;
			aspect-ratio: 1;
			object-fit: cover;
		}

		&__name {
			margin-top: 10px;
			font: 500 24px/1.25 Inter;

			@media (max-width: $mobile) {
				font-size: 20px;
				line-height: 1.4;
			}
		}

		&__position, &__organization {
			font: 18px/24px Inter;
		}
	}

	.button {
		max-width: 427px;
		font: 500 20px/30px Inter;

		@media (min-width: #{$laptop + 1px}) { --py: 21px; }

		@media (max-width: $tablet) {
			font-size: 16px;
			max-width: 257px;
		}

		@media (max-width: $mobile) { max-width: 290px; }
	}
}