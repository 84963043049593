.idunno {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: var(--column-gap);

	&--center {
		align-items: center;

		@media (max-width: $mobile) {
			align-items: flex-start;
		}
	}

	@media (max-width: $mobile) {
		flex-direction: column;
	}
}