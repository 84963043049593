.cases-filter {
	&__big-radio {
		display: flex;
		gap: 10px;

		&__item {
			position: relative;
			cursor: pointer;

			input[type=radio] {
				position: absolute;
				opacity: 0;
				bottom: 0;
				z-index: -999;
			}

			&__visual {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px 16px;
				border-width: 2px;
				border-style: solid;
				border-radius: 666px;
				border-color: #E3E6E6;
				@extend %title;
			}

			input[type=radio]:checked ~ &__visual {
				background: $blue;
				border-color: #{$blue};
				color: #fff;
			}
		}
	}

	&__tags {
		display: flex;
		flex-direction: column;
		gap: 40px;


		&__more {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.spoiler {
			display: flex;
			flex-direction: column;
			gap: 20px;
			--initial-height: 30px;
		}
	}
}