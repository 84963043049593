@font-face {
	font-family: "Inter";
	src: url(/src/assets/static/fonts/Inter-Bold.woff2);
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url(/src/assets/static/fonts/Inter-Medium.woff2);
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url(/src/assets/static/fonts/Inter-Regular.woff2);
	font-weight: 400;
	font-display: swap;
}

h1,
.h1,
%h1 {
	font-weight: 500;
	font-size: 100px;
	line-height: 100px;
	letter-spacing: -0.05em;

	@media (max-width: $laptop) {
		font-size: 80px;
		line-height: 80px;
	}

	@media (max-width: $tablet) {
		font-size: 60px;
		line-height: 60px;
	}

	@media (max-width: $mobile) {
		font-size: 30px;
		line-height: 36px;
	}
}

h2,
.h2,
%h2 {
	font-weight: 500;
	font-size: 60px;
	line-height: 70px;
	letter-spacing: -0.05em;

	@media (max-width: $laptop) {
		font-size: 50px;
		line-height: 40px;
	}

	@media (max-width: $tablet) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $mobile) {
		font-size: 26px;
		line-height: 30px;
	}
}

h3,
.h3,
%h3 {
	font-size: 32px;
	font-weight: 400;
	line-height: 120%;
}

.lead,
%lead {
	font-weight: 400;
	font-size: 32px;
	line-height: 120%;

	@media (max-width: $tablet) {
		font-size: 20px;
		line-height: 28px;
	}
}

.big-title,
%big-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;

	@media (max-width: $tablet) {
		// font-size: 18px;
		// line-height: 24px;
	}

	@media (max-width: $mobile) {
		font-size: 16px;
		line-height: 20px;
	}
}

.title,
%title {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;

	@media (max-width: $tablet) {
		font-size: 16px;
		line-height: 20px;
	}
}

@mixin title_desk {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}

.header-60 {
	font: 500 60px/78px Inter;
	letter-spacing: -0.05em;

	@media (max-width: $laptop) {
		font-size: 40px;
		line-height: 1.3;
	}

	@media (max-width: $mobile) {
		font-size: 30px;
	}
}

.menu,
%menu {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;

	@media (max-width: $tablet) {
		font-size: 12px;
		line-height: 20px;
	}
}

@mixin menu_desk {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
}

.small-text,
%small-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	@media (max-width: $laptop) {
		font-size: 12px;
	}
}

%body {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	@media (max-width: $tablet) {
		font-size: 14px;
		line-height: 20px;
	}
}

body,
html {
	color: #000000;
	font-family: 'Inter';
	font-style: normal;
	@extend %body;
}

input,
textarea,
select,
button {
	font-family: inherit;
}

a {
	text-decoration: none;
	color: inherit;
}

.link,
%link {
	color: $blue;
	border-bottom-color: $blue;
	border-bottom-width: 1px;
	display: inline-flex;

	&--underlined {
		border-bottom-style: solid;
		padding-bottom: 5px;
	}

	&--dashed {
		border-bottom-style: dashed;
		padding-bottom: 5px;
	}

	&--text-underlined {
		text-decoration: underline;
	}
}

.text-colored,
%text-colored {
	&--blue {
		color: $blue;
	}

	&--grey {
		color: $text_color;
	}

	&--grey-dark {
		color: #264FB8;
	}

	&--grey-middle1 {
		color: $text_color;
	}

	&--grey-middle {
		color: #DFE2EC;
	}
}