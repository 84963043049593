.propirtional-img,
%propirtional-img {
	border-radius: 40px;

	&--link {
		position: relative;
		align-items: center;
		justify-content: center;

		&::before {
			content: '';
			position: absolute;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			background-color: $blue;
			z-index: 10;
		}

		&::after {
			content: '';
			position: absolute;
			@extend .icon, .icon--play;
			--size: 24px;
			--color: #fff;
			z-index: 10;
		}
	}

	img {
		border-radius: inherit;
	}

	@media (max-width: $mobile) {
		border-radius: 20px;
	}

	&--131by60 {
		@include proportional-block(131, 60);
	}

	&--131by140 {
		@include proportional-block(131, 140);
	}

	&--656by404 {
		@include proportional-block(656, 404);
	}

	&--11by6 {
		@include proportional-block(33, 18);
	}

	&--1by1 {
		@include proportional-block(1, 1);
	}

	&--23by10 {
		@include proportional-block(23, 10);
	}

	&--after-desktop {
		@media (max-width: $laptop) {
			border-radius: 0;

			@supports (aspect-ratio : 1 / 1) {
				aspect-ratio: initial;
			}

			@supports not (aspect-ratio : 1 / 1) {
				padding-bottom: initial;
				height: initial;

				> :first-child {
					position: initial;
					top: initial;
					left: initial;
				}
			}
		}
	}
}