.search-banner {
	color: #fff;

	&__container {
		@extend %container;
		padding-bottom: 80px;
		position: relative;

		@media (max-width: $laptop) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@media (max-width: $tablet) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&::after {
			position: absolute;
			bottom: 0;
			right: -128px;
			width: 448px;
			height: 448px;
			background-image: url(/src/assets/static/images/search-woman.png);
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: contain;

			@media (min-width: $tablet) {
				content: "";
			}
		}
	}

	&__content {
		@extend %container-small;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
	}

	&__title {}

	&__input {
		margin-top: 80px;
		display: flex;
		align-items: center;

		@media (max-width: $laptop) {
			margin-top: 60px;
		}

		@media (max-width: $tablet) {
			margin-top: 40px;
		}

		&__cleaner {
			width: 44px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			--color: #fff;
			--size: 24px;
			cursor: pointer;
		}

		input {
			width: 100%;
			background: transparent;
			outline: none;
			border: none;
			color: #fff;
			@extend %title;

			&::placeholder {
				color: #ffffff80;
			}
		}
	}
}