.pagination {
	display: flex;
	justify-content: center;
	align-items: center;

	&--left {
		justify-content: flex-start;
	}

	%base {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		--color: #000;

		@media (max-width: $mobile) {
			width: 40px;
			height: 40px;
		}
	}

	&__page {
		position: relative;
		@extend %base, %title;

		&--current {
			background: #E3E6E6;
		}

		&:not(&--current):hover {
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				border: 2px solid $blue;
			}
		}
	}

	&__arrow {
		@extend %base;

		&::before {
			@extend %icon;
			content: "";
			--size: 12px;
		}

		&--prev {
			&::before {
				@extend %icon--arrow-left;
			}
		}

		&--next {
			&::before {
				@extend %icon--arrow-right;
			}
		}
	}
}