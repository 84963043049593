.landing2-tariffs {
	@extend .landing2-start-acting;

	.tariff {
		grid-row: 1 / span 5;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: subgrid;

		@media (max-width: $cols_oneColOnly) {
			grid-row: auto;
			grid-template-rows: auto;
		}

		@media (max-width: 915px) {
			grid-row: auto;
			grid-template-rows: auto;
		}
	}

	.tariff__caption {
		flex-direction: column;
		justify-content: start;
		border-radius: 40px 40px 5px 5px;

		.tariff__name {
			font: bold 24px/1.25 Inter;
			color: #2A2546;
		}

		.tariff__description {
			font: 16px/24px Inter;
			color: #595571;
		}

		@media (max-width: $cols_oneColOnly) {
			min-height: 80px;

			.tariff__name { font-size: 16px; }

			.tariff__description {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.tariff__text {
		color: $text_color;

		.element-block {
			@extend %landing2-element-block;

			@media (max-width: $cols_oneColOnly) {
				.block-name {
					font-size: 14px;
					line-height: 20px;
				}

				.block-value {
					font-size: 24px;
					line-height: 30px;
				}
			}
		}
	}

	.button {
		margin-top: 23px;

		@media (max-width: $cols_oneColOnly) {
			margin-top: 20px;
			max-width: 100%;
			min-height: 39px;

			&.button--primary {
				font-size: 16;
				line-height: 1.2;
			}
		}
	}
}