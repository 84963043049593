
@mixin SliderArrows($width) {
	$gap: 10px;
	display: flex;
	gap: $gap;

	.splide__arrow {
		appearance: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		width: math.div($width - $gap, 2);
		aspect-ratio: 1;
		flex-shrink: 0;
		border-radius: 50%;
		border: 2px solid $text_color;
		cursor: pointer;
		@include transition-base(border);

		svg {
			width: 24px;
			flex-shrink: 0;
			@include transition-base;
		}

		&:hover {
			border-color: $hover_color;

			svg path { stroke: $hover_color; }
		}

		&:disabled {
			pointer-events: none;
		}
	}
}

@mixin LabeledElement {
	border-radius: 10px;
	background-color: #fff;
	padding: 10px;
	font: 18px/1.2 Inter;

	label {
		font: 14px/1.2 Inter;
		margin-bottom: 4px;
		color: $text_color;
	}
}

@mixin FolderTag {
	padding: 26px 30px;
	font: 14px/1.2 Inter;
	background-color: $grey_color;
	border-radius: 35px;
	user-select: none;
	cursor: pointer;

	&:hover, &.tag-selected {
		background-color: $greyHover_color;
		color: $blue;
	}

	@media (max-width: $laptop) {
		font: 12px/1.3 Inter;
		padding: 16px;
	}
}

.cases {
	&__header {
		color: #000;
		$arrowSize: 138px;
		display: grid;
		grid-template-columns: 1fr $arrowSize;
		gap: 40px;
		visibility: visible;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		h1 {
			@media (max-width: $laptop) {
				font-size: 50px;
				line-height: 1;
			}

			@media (max-width: $mobile) {
				font-size: 36px;
			}
		}

		&__subheader {
			font: 24px/1.3 Inter;
			grid-row: 2;
			margin-left: 9px;

			@media (max-width: $laptop) {
				margin-left: 4px;
			}

			@media (max-width: $mobile) {
				margin-left: 2px;
				font-size: 18px;
				line-height: 1.2;
			}
		}

		&__slider-arrows {
			grid-row: 1 / span 2;
			align-self: end;
			@include SliderArrows($arrowSize);

			@media (max-width: $mobile) {
				margin-left: 2px;
				grid-row: auto;
				@include SliderArrows(90px);
			}
		}
	}

	&__cards {
		margin-top: 40px;
		grid-column: 1 / -1;

		// Пока нет слайдера
		.splide__list:not([role]) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
		}

		@media (max-width: $laptop) {
			margin-top: 20px;
		}

		@media (max-width: $mobile) {
			margin-top: 0;
			max-width: calc(100vw - 2 * var(--container-padding));
		}

		&-card {
			--image-height: 313px;
			--card-height: 700px;
			--grid-template: auto auto 1fr;
			background-color: #fff;
			border-radius: 20px;
			padding: 20px;
			display: grid;
			grid-template-rows: var(--grid-template);
			gap: 20px;
			height: var(--card-height);

			@media (max-width: $mobile) {
				--image-height: 225px;
				--card-height: 557px;
				gap: 24px;
			}
		}

		.case__card {
			&-grey {
				--grid-template: auto 1fr;
				background-color: $grey_color;

				&:hover {
					background-color: $greyHover_color;
				}
			}

			&-orange {
				--grid-template: auto 1fr;
				background-color: $orange_color;

				&:hover {
					background-color: $orangeHover_color;
				}
			}

			&-picture {
				height: var(--image-height);
				border-radius: 10px;
				width:  100%;
				object-fit: cover;
			}

			&-picture-replacer {
				height: var(--image-height);
				display: flex;
				flex-direction: column;
				gap: 30px;
			}

			&-link {
				--text-color: #000;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				svg path {
					@include transition-base;
				}

				&:not(:is(.case__card-grey a, .case__card-orange a)):hover {
					--text-color: #{$blue};

					svg path {
						stroke: $blue;
					}
				}
			}

			&-name {
				font: 500 24px/1.25 Inter;
				color: var(--text-color);
				@include transition-base(color);
			}

			&-description {
				font: 18px/1.2 Inter;
				margin-top: auto;
				min-height: 3.6em;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				line-clamp: 3;
				overflow-y: hidden;
			}

			&-tags {
				margin-top: 10px;
				display: flex;
				align-items: center;
				gap: 12px;

				@media (max-width: $mobile) {
					margin-top: 2px;
				}

				&-tag {
					font: 14px/1.2 Inter;
					padding: 8px 16px;
					border-radius: 17px;
					background-color: $grey_color;
					border: 1px solid $grey_color;

					&:hover {
						color: $hover_color;
						border-color: currentColor;
						background-color: #bbdefb;
					}

					@media (max-width: $mobile) {
						font-size: 12px;
						line-height: 1.3;
					}
				}
			}

			&-result {
				flex-grow: 1;
				@include LabeledElement;
			}

			&-metainfo {
				margin-top: auto;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;

				&-info {
					@include LabeledElement;
				}
			}
		}
	}


	&__tags {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 80px;

		@media (max-width: $laptop) {
			margin-bottom: 60px;
		}

		@media (max-width: 640px) {
			display: grid;
			grid-template-columns: 115px auto;
			gap: 20px;
		}

		&__tag-wrapper {
			padding: 10px 10px 21px;
			border: 2px solid transparent;
			border-bottom: none;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			z-index: 2;
			@include transition-base(border-color);

			&:first-child {
				padding-left: 0;
			}

			@media (min-width: 641px) {
				&.tag-open {
					border-color: $grey_color;
					background-color: #fafafa;
				}
			}

			@media (max-width: 640px) {
				padding: 0;
			}
		}

		&__tag {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 157px;
			height: 69px;
			border-radius: 35px;
			background-color: $orange_color;
			font: 14px/1.2 Inter;

			@media (max-width: $mobile) {
				width: 115px;
				height: 48px;
				font: 12px/1.3 Inter;
			}

			&:hover {
				background-color: $orangeHover_color;
			}

			&.tag-blue {
				background-color: $blue;
				color: #fff;

				&:hover, &:is(.tag-open .tag-blue) {
					background-color: $hover_color;
				}
			}

			i {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 29px;
				aspect-ratio: 1;
				margin-left: 10px;
				border-radius: 50%;
				background-color: #fff;
				font: 14px/1.2 Inter;
				color: #000;

				&:empty {
					display: none;
				}

				@media (max-width: $mobile) {
					width: 24px;
					font: 12px/1.3 Inter;
				}
			}
		}

		&__folder {
			display: none;
			margin-top: -2px;
			width: 100%;
			flex-shrink: 0;
			border-radius: 20px;
			border: 2px solid $grey_color;

			@media (max-width: 640px) {
				display: none !important;
			}

			&-tag {
				@include FolderTag;
			}

			&-content {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				padding: 40px;
			}

			&--mobile {
				position: fixed;
				z-index: 200;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100dvh;
				background-color: #fafafa;
				padding: 40px 15px;
				display: none;
				flex-direction: column;
				gap: 60px;

				&.open { display: flex; }

				@media (min-width: 641px) {
					display: none !important;
				}

				.tags-dialog__header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 10px;
					margin: 0;
				}

				.tags-dialog__title {
					font: 500 30px/1.2 Inter;
					letter-spacing: -0.05em;
				}

				.tags-dialog__close {
					font-size: 0;
					cursor: pointer;
				}

				.tags-dialog__tags-list {
					display: flex;
					flex-wrap: wrap;
					gap: 20px;
					overflow-y: auto;

					&-tag {
						@include FolderTag;
					}
				}
			}
		}
	}


	&__portfolio {
		@include Portfolio;

		&__list {
			column-gap: 10px;
			// Тут учитывается левый padding в 40px, поэтому 72
			grid-template-columns: 72px 363px 1fr auto;
			align-items: start;

			@media (max-width: $laptop) {
				grid-template-columns: 60px 180px 1fr auto;
				column-gap: 20px;
			}

			@media (max-width: $tablet) {
				grid-template-columns: 40px 180px 1fr;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}

		&__item {
			align-items: start;

			@media (max-width: $tablet) {
				padding: 20px;
			}

			&__title {
				font: bold 18px/1.2 Inter;

				@media (max-width: $laptop) {
					font: 500 24px/30px Inter;
				}

				@media (max-width: $mobile) {
					font-size: 20px;
				}
			}

			&__text {
				line-height: 1.2;
				margin-right: 65px;

				@media (max-width: $laptop) {
					margin-right: 20px;
				}

				@media (max-width: $tablet) {
					margin-right: 0;
				}

				@media (max-width: $mobile) {
					font-size: 16px;
					line-height: 1.5;
				}
			}

			&__buttons {
				justify-content: flex-end;

				@media (min-width: #{$laptop + 1px}) and (max-width: $laptop) {
					flex-direction: column;
					justify-content: center;
				}

				@media (max-width: $tablet) {
					grid-column: 3;
					grid-row: 2;
				}

				@media (max-width: $mobile) {
					grid-column: 1;
					grid-row: auto;
					margin-top: 20px;
					flex-direction: row;
					justify-content: flex-start;
				}

				.button {
					width: 148px;
					height: 42px;

					@media (max-width: $laptop) {
						flex-basis: 42px;
					}

					@media (max-width: $tablet) {
						flex-basis: 135px;
						width: 135px;
						flex-grow: 0;
					}

					@media (max-width: $mobile) {
						height: 50px;
						width: 126px;
						flex-basis: 126px;
					}

					span {
						font: 18px/1.2 Inter;

						@media (max-width: $mobile) {
							font-size: 16px;
							line-height: 30px;
						}
					}
				}
			}
		}

		&__pager {
			grid-column: 1 / -1;
			margin-top: 60px;
			display: flex;

			@media (max-width: $laptop) {
				margin-top: 40px;
			}

			@media (max-width: $mobile) {
				gap: 18px;
			}

			&-btn {
				flex-shrink: 0;
				width: 64px;
				aspect-ratio: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				font: 500 20px/1.5 Inter;
				@include transition-base;

				@media (max-width: $mobile) {
					width: 40px;

					&.btn--invisible-mobile {
						display: none;
					}
				}

				svg path {
					@include transition-base;
				}

				&:hover {
					background-color: #fff;
					color: $blue;

					svg path {
						stroke: $blue;
					}
				}

			}

			.btn--inactive, .btn--current {
				pointer-events: none;
			}

			.btn--current {
				color: $blue;
			}
		}
	}
}