.case-card {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (max-width: $mobile) {
		gap: 0;
	}

	&__header {
		border-radius: 40px;
		@include proportional-block(658, 520);

		@media (max-width: $mobile) {
			@include proportional-block(1, 1);
		}

		&--wide {
			@media (min-width: #{$mobile + 1px}) {
				@include proportional-block(1320, 520);
			}

			@media (max-width: $mobile) {
				@include proportional-block(1, 1);
			}
		}
	}

	&__body {
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: $laptop) {
			gap: 20px;
		}

		@media (max-width: $mobile) {
			padding: 20px 10px;
		}

		&__top {
			display: grid;
			grid-template-columns: 1fr auto;
			gap: 10px 40px;

			&__name {
				grid-column: 1;
				grid-row: 1;
				@extend %title;
			}

			&__tags {
				grid-column: 1;
				grid-row: 2;

				@media (max-width: $laptop) {
					grid-column: 1 / -1;
				}
			}

			&__link {
				grid-column: 2;
				grid-row: 1 / span 2;

				@media (max-width: $laptop) {
					grid-row: 1;
				}
			}
		}

		&__bot {
			&__text {
				color: $text_color;
				@extend %body;
			}
		}
	}
}