.landing2-why-bitrix {
	.section__content {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 80px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	.features-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 60px 40px;

		@media (max-width: $tablet) {
			grid-template-columns: 1fr 1fr;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			gap: 40px;
		}
	}

	.feature {
		display: flex;
		flex-direction: column;
		gap: 30px;
		color: $text_color;

		.title { color: #000; }

		@media (max-width: $mobile) {
			gap: 20px;
		}
	}

	h2 {
		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1.2;
		}
	}

	.button {
		align-self: center;
		text-align: center;

		@media (max-width: $mobile) {
			--px: 30px;
			align-self: start;
			max-width: 330px;
			font-size: 16px;
			line-height: 1.2;
			text-wrap: unset;
			text-wrap: balance;
		}
	}
}