.main-quote {
	background: $blue;
	color: #fff;
	padding: 140px 0;
	border-radius: 40px;
	@extend %fullwidth-but-not;
	overflow-wrap: break-word;

	@media (max-width: $laptop) {
		padding: 100px 0;
		border-radius: 40px;
	}

	@media (max-width: $mobile) {
		padding: 40px 0;
		border-radius: 20px;
	}

	&__container {
		@extend %container;

		@media (max-width: $mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__content {
		flex-direction: column;
		gap: 30px;
		display: grid;
		grid-template-columns: max-content auto;

		@media (max-width: $laptop) {
			grid-template-columns: 1fr;
			gap: 60px;
		}

		@media (max-width: $mobile) {
			gap: 40px;
			grid-template-columns: 1fr;
		}
	}

	&__person {
		display: flex;
		flex-direction: column;
		grid-column: 1;

		@media (max-width: $laptop) {
			order: 999;
			display: grid;
			grid-template-columns: max-content auto max-content;
			gap: 24px 40px;
			align-items: center;
		}

		@media (max-width: $tablet) {
			grid-template-columns: max-content minmax(auto, max-content);
		}

		@media (max-width: $mobile) {
			grid-template-columns: max-content 1fr;
			gap: 20px;
		}

		&__photo {
			width: 211px;
			height: 211px;
			margin-bottom: 40px;

			@media (max-width: $laptop) {
				grid-row: span 2;
				margin-bottom: 0;
			}

			@media (max-width: $tablet) {
				grid-row: span 3;
			}

			@media (max-width: $mobile) {
				width: 60px;
				height: 60px;
				grid-row: unset;
			}
		}

		img {
			border-radius: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&__name {
			font-size: 32px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: -0.05em;
			margin-bottom: 24px;

			@media (max-width: $laptop) {
				margin-bottom: 0;
				grid-column: 2;
				grid-row: 1;
				align-self: flex-end;
			}

			@media (max-width: $tablet) {
				grid-column: unset;
				grid-row: unset;
				font-size: 18px;
				line-height: 1;
				align-self: center;
			}
		}

		&__caption {
			margin-bottom: 40px;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			display: flex;
			flex-direction: column;
			gap: 8px;

			@media (max-width: $laptop) {
				margin-bottom: 0;
				grid-column: 2;
				grid-row: 2;
				align-self: flex-start;
			}

			@media (max-width: $tablet) {
				grid-column: unset;
				grid-row: unset;
			}

			@media (max-width: $mobile) {
				grid-column: 1 / -1;
				gap: 4px;
				font-size: 14px;
			}
		}

		.button {
			@media (max-width: $laptop) {
				grid-row: span 2;
			}

			@media (max-width: $tablet) {
				grid-row: unset;
				justify-self: flex-start;
				margin-top: 16px;
				justify-self: stretch;
			}

			@media (max-width: $mobile) {
				grid-column: 1 / -1;
				margin-top: 0;
			}
		}
	}

	&__text {
		@extend %h1;
		display: flex;
		gap: 20px;
		align-items: flex-start;
		min-width: 0;
		width: auto;

		@media (max-width: $mobile) {
			font-size: 36px;
			gap: 4px;
		}

		&::before {
			content: '“';
			@extend %text-colored--grey-dark;
		}

		@media (max-width: $mobile) {
			grid-column: 1;
		}
	}
}