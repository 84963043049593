.landing2-suit-for {
	.section__content {
		display: flex;
		flex-direction: column;
		gap: 70px;

		@media (max-width: $mobile) {
			gap: 40px;
		}
	}

	.suit-elements {
		max-width: 976px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 95px;

		@media (max-width: $mobile) {
			column-gap: 15px;
		}
	}

	h2 {
		@media (max-width: $mobile) {
			font-size: 30px;
			line-height: 1.2;
		}
	}

	.suit-element {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 44px;

		img { height: 96px; }

		@media (max-width: $mobile) {
			gap: 20px;

			img { height: 60px; }
		}
	}
}