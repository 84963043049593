.certificates {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: var(--column-gap);

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		gap: 24px;
	}

	&__item {
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media (max-width: $mobile) {
			gap: 16px;
		}

		&__img {
			@media (min-width: #{$mobile + 1px}) {
				@include proportional-block(1, 1);
			}

			&__inner {
				display: flex;
				align-items: center;

				img {
					display: block;
					width: 100%;
					height: auto;
					object-fit: contain;
					object-position: center left;
					border-radius: 20px;

					@media (max-width: $mobile) {
						object-position: center;
					}
				}
			}
		}

		&__caption {
			@extend %small-text;
			color: $text_color;
		}

		&__title {
			@extend %title;
		}
	}
}