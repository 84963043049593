.vacancy-list {
	--gap: 40px;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@media (max-width: $tablet) {
		--gap: 20px;
	}

	&__item {
		&:not(:last-child) {
			padding-bottom: var(--gap);
			border-bottom: 2px solid #E3E6E6;
		}

		&__header {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 30px var(--column-gap);
			align-items: center;

			@media (max-width: $laptop) {
				gap: 20px var(--column-gap);
			}

			&__title {
				grid-row: 1;
				grid-column: 1 / 11;

				@media (max-width: $mobile) {
					grid-column: 1 / -1;
				}
			}

			&__flipper {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;

				&-wrapper {
					grid-column: 12 / 13;

					@media (max-width: $mobile) {
						grid-column: 1 / 2;
						grid-row: 3;
					}

					.spoiler__flipper {
						&.is-active {
							.icon {
								@extend %icon--minus;
							}
						}
					}
				}

				@media (max-width: $tablet) {
					width: 50px;
					height: 50px;
				}

				.icon {
					--color: #{$blue};
					--size: 30px;
					@extend %icon--plus;
				}
			}

			&__text {
				grid-row: 2;
				grid-column: 1 / 11;
				@extend %text-colored, %text-colored--grey;

				@media (max-width: $mobile) {
					grid-column: 1 / -1;
				}
			}
		}

		&__content {
			gap: 60px var(--column-gap);
			padding-top: 80px;
			display: grid;
			grid-template-columns: repeat(12, 1fr);

			@media (max-width: $laptop) {
				padding-top: 40px;
				gap: 40px var(--column-gap);
			}

			&__block {
				display: flex;
				flex-direction: column;
				gap: 10px;
				grid-column: 1 / 8;

				@media (max-width: $laptop) {
					grid-column: 1 / 9;
				}

				@media (max-width: $tablet) {
					grid-column: 1 / -1;
				}

				&__title {}

				&__text {
					@extend %text-colored, %text-colored--grey;

					ul {
						@extend %custom-list;
					}
				}
			}
		}
	}
}