.contacts {
	&__info {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		gap: var(--column-gap);

		&__left {
			grid-column: 1 / 6;
			display: flex;
			flex-direction: column;
			gap: 16px;

			@media (max-width: $mobile) {
				grid-column: 1/-1;
			}
		}

		&__right {
			grid-column: 8 / 13;

			@media (max-width: $mobile) {
				grid-column: 1/-1;
			}
		}

		&__links {
			display: flex;
			gap: 30px;
		}

		&__request {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
			gap: 16px;
		}
	}

	&__map {
		height: 600px;
		background: #D9D9D9;
		border-radius: 40px;
		max-height: 70vh;
		overflow: hidden;

		iframe,
		> div {
			border-radius: inherit;
			height: 100% !important;
			width: 100% !important;
		}
	}
}